import { useEffect, useState } from "react";
import styles from "../MyNetwork.module.css";
import { Col, Row, Dropdown } from "react-bootstrap";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { Toast } from "../../../utils/Toster";
import { userLogoutClear } from "../../../store/Slices/UserSlice";
import {
  fetchCountData,
  connectionList,
} from "../../../store/Slices/NetworkSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FaUserMinus } from "react-icons/fa";
import SublyApi from "../../../helpers/Api";

function MyConnections({ count }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.user);
  const connections = useSelector((state) => state.network.connections);
  const connectionData = useSelector((state) => state.network.connectionData);
  const [pageValue, setPageValue] = useState(1);
  const [isFriend, setIsFriend] = useState(false);

  // ====function for calling connectionList api===
  async function fetchConnectionList(page, loadValue) {
    const requestData = new FormData();
    requestData.append("page_no", page ? page : pageValue);
    dispatch(
      connectionList({
        userToken: userToken,
        requestData: requestData,
        loadValue: loadValue,
      })
    ).then((responsejson) => {
      const response = responsejson.payload.response;
      if (response.status_code === STATUS_CODES.SUCCESS) {
      } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: response.message,
        });
      } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        dispatch(userLogoutClear());
      } else if (response.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
      } else {
        Toast.fire({
          icon: "error",
          title: response.message,
        });
      }
    });
  }

  useEffect(() => {
    fetchConnectionList(1, false);
    setPageValue(1);
  }, [isFriend]);

  // ====function for page increament====
  function pageHandle() {
    fetchConnectionList(pageValue + 1, true);
    setPageValue(pageValue + 1);
  }

  //====== function to disconnect user======
  async function disconnectHandler(userId) {
    const requestData = new FormData();
    requestData.append("otherUserId", userId);
    await SublyApi.disconnectUser(userToken, requestData).then((response) => {
      const responsejson = response.data;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        dispatch(fetchCountData(userToken));
        setIsFriend(!isFriend);
      } else if (responsejson.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      } else if (responsejson.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
        dispatch(userLogoutClear());
      } else if (responsejson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        Toast.fire({
          icon: "error",
          title: `${t("SERVER_ERR")}`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      }
    });
  }

  return (
    <>
      <div
        className={`${styles.myRequestData} bg-bg_colour mb-6 myConnectionData`}
      >
        <h3 className="text-left font-semibold tracking-norma">
          {t("Connections")} ({count})
        </h3>
        <Row>
          {connections.length > 0 ? (
            connections.map((data, index) => (
              <Col xl={4} lg={6} md={4} sm={6} xs={6} key={index}>
                <div className={`${styles.connectionsList}`}>
                  <div className="userActionBtn connectionDropDown">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <BiDotsVerticalRounded
                          className={`${styles.svgEllipses}`}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => disconnectHandler(data.user_id)}
                          as="button"
                        >
                          <FaUserMinus />
                          {t("DISCONNECT")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      navigate(`/my-network/profile-detail/${data.user_id}`)
                    }
                  >
                    <span className={styles.requestImg}>
                      <img src={data.profile_img} alt={data.fullname} />
                    </span>
                    <div className={styles.requestDetail}>
                      <p>{data.fullname}</p>
                      <span>{data.current_designation}</span>
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <span className={`${styles.noData} text-center mt-2`}>
              {t("No_Connection")}
            </span>
          )}
        </Row>
        {connections.length > 0 && connectionData.loadMore && (
          <div className={`${styles.seeMoreBtn} text-center mb-1`}>
            <span onClick={pageHandle} className="cursor-pointer">
              {t("SEE_MORE")}
            </span>
          </div>
        )}
      </div>
    </>
  );
}

export default MyConnections;
