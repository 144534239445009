import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./PostDetail.module.css";
import { Container, Col, Row } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import TopHeader from "../UserHeaders/TopHeader";
import { useDispatch, useSelector } from "react-redux";
import threedot from "../../Assests/Images/overflow.svg";
import OptionsList from "../HomeModule/OptionsList";
import heart from "../../Assests/Images/icon-like.svg";
import thumb from "../../Assests/Images/like-outline.svg";
import send from "../../Assests/Images/send-fill.svg";
import repost from "../../Assests/Images/repost.svg";
import $ from "jquery";
import { Icon } from "@iconify/react";
import comment from "../../Assests/Images/comment-dots.svg";
import CommentBox from "../HomeModule/CommentBox";
import ImageGallery from "react-image-gallery";
import { useNavigate, useParams } from "react-router-dom";
import SublyApi from "../../helpers/Api";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { fetchPostMedia, userMediaClear } from "../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";
import { STATUS_CODES } from "../../utils/StatusCode";
import { Toast } from "../../utils/Toster";
import Loader from "../../utils/Loader/Loader";
import ReportComment from "../HomeModule/ReportComment";
import SocialShare from "../HomeModule/SocialShare";
import VideoPlayer from "../HomeModule/VideoPlayer";
import Footer from "../Footer/Footer";
import { POST_MEDIA_TYPE } from "../../utils/Constants";


function PostDetail() {
  const { t } = useTranslation();
  const { userToken } = useSelector((state) => state.user);
  const [openBox, setOpenBox] = useState(false);
  const [addClass, setAddClass] = useState(false);
  const [postDetail, setPostDetail] = useState("");
  const [loader, setLoader] = useState(false);
  const [images, setImages] = useState([]);
  const [openReport, setOpenReport] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [postIdValue, setPostIdValue] = useState(null);
  const [isComment, setIsComment] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [manageScreen, setManageScreen] = useState(false);
  const [readValue, setReadValue] = useState(false);
  const [commentPage, setCommentPage] = useState(1);
  const [isLoad, setIsLoad] = useState();
  const [commentCount, setCommentCount] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();


  const fullScreenHandle = useCallback((e) => {
    setManageScreen(e);
  }, []);


  // =======clear redux========
  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  // ====function to hide box on click outside====
  $(document).mouseup(function (e) {
    if ($(e.target).closest(".openBoxClass").length === 0) {
      setOpenBox(false);
    }
  });

  // -----------Calling APi for post detail--------------
  useEffect(() => {
    getPostDetail(true);
  }, []);

  async function getPostDetail(isLike) {
    setLoader(true);
    const requestData = new FormData();
    requestData.append("post_id", id);
    await SublyApi.FetchPostDetail(userToken, requestData).then((response) => {
      if (response.status_code === STATUS_CODES.SUCCESS) {
        setPostDetail(response.data);
        if (isLike) {
          const appendImages = [...images];
          response.data.postMedia.map((img) => {
            appendImages.push({ original: img.post_url });
          });
          setImages(appendImages);
        }
        setLoader(false);
        setManageScreen(false);
      } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: response.message,
        });
        setLoader(false);
      } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        setLoader(false);
        logOutDataClear();
      } else if (response.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        setLoader(false);
      } else {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        setLoader(false);
      }
    });
  }

  // -------------Calling comment list API------------
  useEffect(() => {
    commentList(1);
    setCommentPage(1);
  }, [isComment]);

  async function commentList(props, loadValue) {
    const requestData = new FormData();
    requestData.append("page_no", props);
    requestData.append("post_id", id);
    await SublyApi.fetchCommentList(userToken, requestData).then(
      async (responsejson) => {
        if (responsejson.status_code === STATUS_CODES.SUCCESS) {
          if (loadValue) {
            setCommentValue(
              commentValue.concat(responsejson.data.fetchComments)
            );
          } else {
            setCommentValue(responsejson.data.fetchComments);
          }
          setCommentCount(responsejson.data.total_comments);
          setIsLoad(responsejson.data.loadMore);
        } else if (responsejson.data.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
        } else if (
          responsejson.data.status_code === STATUS_CODES.INVALID_TOKEN
        ) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          logOutDataClear();
        } else {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
        }
      }
    );
  }

  // =====Function for handling loadmore comments=====
  function loadmoreHandle() {
    commentList(commentPage + 1, true);
    setCommentPage(commentPage + 1);
  }

  // ======API for repost post======
  async function repostHandle(postId) {
    setLoader(true);
    const requestData = new FormData();
    requestData.append("post_id", postId);
    await SublyApi.repostPost(userToken, requestData).then(
      async (responsejson) => {
        if (responsejson.status_code === STATUS_CODES.SUCCESS) {
          Toast.fire({
            icon: "success",
            title: responsejson.message,
          });
          const requestData = new FormData();
          requestData.append("page_no", 1);
          await dispatch(
            fetchPostMedia({ userToken: userToken, requestData: requestData })
          );
          navigate("/");
          setLoader(false);
        } else if (responsejson.data.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          setLoader(false);
        } else if (
          responsejson.data.status_code === STATUS_CODES.INVALID_TOKEN
        ) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          setLoader(false);
          logOutDataClear();
        } else {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          setLoader(false);
        }
      }
    );
  }

  // ------------API for like and heart------------
  async function likePostHandle(postId, action, value) {
    const requestData = new FormData();
    requestData.append("like_type", action);
    requestData.append("post_id", postId);
    await SublyApi.likePost(userToken, requestData).then(
      async (responsejson) => {
        if (responsejson.status_code === STATUS_CODES.SUCCESS) {
          getPostDetail(false);
          setLoader(false);
        } else if (responsejson.data.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          setLoader(false);
        } else if (
          responsejson.data.status_code === STATUS_CODES.INVALID_TOKEN
        ) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          logOutDataClear();
          setLoader(false);
        } else {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
        }
      }
    );
  }

  // ------------API for dislike------------
  async function dislikePostHandle(postId) {
    const requestData = new FormData();
    requestData.append("post_id", postId);
    await SublyApi.dislikePost(userToken, requestData).then(
      async (responsejson) => {
        if (responsejson.status_code === STATUS_CODES.SUCCESS) {
          getPostDetail(false);
          setLoader(false);
        } else if (responsejson.data.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          setLoader(false);
        } else if (
          responsejson.data.status_code === STATUS_CODES.INVALID_TOKEN
        ) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          setLoader(false);
          logOutDataClear();
        } else {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          setLoader(false);
        }
      }
    );
  }

  return (
    <section
      className={`${images &&
        images.length > 0 &&
        postDetail &&
        postDetail.postMedia.length > 0 &&
        postDetail.postMedia[0].media_type !== POST_MEDIA_TYPE.VEDIO_MEDIA
        ? "profilDetailWrapper pb-6"
        : "profilDetailWrapper gaveHeight pb-6"
        }`}
    >
      {loader ? <Loader /> : ""}
      <TopHeader />
      <div className="mt-10 mb-6 mx-0">
        <Container>
          <Row>
            <Col lg={3} md={12} sm={12}>
              <div
                className={`${styles.followBackBtn} flex items-center cursor-pointer`}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <BiArrowBack />
                <span className="text-lg text-center text-black font-medium">
                  {t("BACK")}
                </span>
              </div>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <h3 className={styles.detailHeadText}>{t("DETAILS")}</h3>
            </Col>
            <Col lg={3} md={12} sm={12}></Col>
          </Row>
        </Container>
      </div>
      <Container>
        <div
          className={
            images && images.length > 0 ? styles.detailBox : styles.noImageClass
          }
        >
          <Row className={images && images.length == 0 && "customRowClass"}>
            {postDetail &&
              postDetail.postMedia.length > 0 &&
              postDetail.postMedia[0].media_type ==
              POST_MEDIA_TYPE.VEDIO_MEDIA ? (
              <>
                <Col lg={8} className="pr-0 slideRespo">
                  <VideoPlayer
                    videoUrl={
                      postDetail &&
                      postDetail.postMedia.length > 0 &&
                      postDetail.postMedia[0].post_url
                    }
                  />
                </Col>
              </>
            ) : (
              <>
                {images && images.length > 0 ? (
                  <Col lg={8} className="pr-0 slideRespo">
                    {images && (
                      <ImageGallery
                        items={images}
                        showThumbnails={false}
                        showPlayButton={false}
                        additionalClass={`${manageScreen
                          ? "imageRadius"
                          : "fullScreen imageRadius"
                          }`}
                        useBrowserFullscreen={false}
                        onScreenChange={fullScreenHandle}
                      />
                    )}
                  </Col>
                ) : (
                  ""
                )}
              </>
            )}
            <Col
              lg={images && images.length == 0 ? 6 : 4}
              className={`pl-0 ${styles.detailRespo}`}
            >
              <section className={styles.detailSection}>
                <div className={`${styles.detailCaption} px-2.5`}>
                  <div className="flex items-center justify-between pt-3 pb-3">
                    <div className="flex items-center">
                      <div className={styles.userIcon}>
                        <img
                          src={postDetail && postDetail.profile_img}
                          alt="profile"
                          className="mr-4"
                        />
                      </div>
                      <div className={styles.profileDetail}>
                        <h2>{postDetail && postDetail.fullname}</h2>
                        <p>{postDetail && postDetail.current_designation}</p>
                      </div>
                    </div>
                    <div
                      className={`${openBox
                        ? "relative openBoxClass"
                        : "relative closeBoxClass"
                        }`}
                    >
                      <img
                        src={threedot}
                        alt="threeDot"
                        className="cursor-pointer"
                        onClick={() => {
                          openBox ? setOpenBox(false) : setOpenBox(true);
                          setPostIdValue(postDetail.id);
                        }}
                      />
                      {openBox ? (
                        <OptionsList
                          setOpenReport={setOpenReport}
                          setOpenShare={setOpenShare}
                          myPost={postDetail.my_post}
                          itemId={postDetail.id}
                          setOpenBox={setOpenBox}
                          setIsLoader={setLoader}
                          isDetail={true}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      postDetail &&
                        postDetail.post_caption &&
                        postDetail.post_caption.length > 250
                        ? styles.PadingHandle
                        : styles.detailText
                    }
                  >
                    <p>
                      {readValue && postDetail && postDetail.post_caption
                        ? postDetail &&
                        postDetail.post_caption &&
                        postDetail.post_caption
                        : postDetail &&
                          postDetail.post_caption &&
                          postDetail.post_caption.length > 250
                          ? postDetail &&
                          postDetail.post_caption &&
                          postDetail.post_caption.substring(0, 250) + "...."
                          : postDetail &&
                          postDetail.post_caption &&
                          postDetail.post_caption.substring(0, 250)}
                    </p>
                  </div>
                  <div className="text-end">
                    {postDetail &&
                      postDetail.post_caption &&
                      postDetail.post_caption.length > 250 ? (
                      <span
                        className={`mb-3 ${styles.seeMoreClass}`}
                        onClick={() => {
                          setReadValue(!readValue);
                        }}
                      >
                        {readValue ? t("SEE_LESS") : t("SEE_MORE")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className={`flex relative items-center gap-2 ${styles.responsiveReact}`}
                  >
                    <div className={styles.thumbIcon}>
                      <img src={thumb} />
                    </div>
                    <div className={styles.heartIcon}>
                      <img src={heart} />
                    </div>
                    <p>{postDetail && postDetail.total_likes}</p>
                    <p className="pl-1.5">
                      {commentCount && commentCount}{" "}
                      {commentCount && commentCount > 1
                        ? t("COMMENTS")
                        : t("COMMENT")}
                    </p>
                    <p className="pl-1.5">
                      {postDetail && postDetail.repost_count} {t("REPOST")}
                    </p>
                  </div>
                </div>
                <div>
                  <div
                    className={`flex item-center gap-4 justify-between ${styles.reactIcons}`}
                  >
                    <span
                      className="relative hoverClass"
                      onMouseOver={() => {
                        setAddClass(true);
                      }}
                      onMouseLeave={() => {
                        setTimeout(() => {
                          setAddClass(false);
                        }, 800);
                      }}
                    >
                      {postDetail && postDetail.is_like == 1 ? (
                        postDetail && postDetail.like_type == 1 ? (
                          <Icon
                            icon="mdi:heart"
                            color="red"
                            width="20"
                            height="20"
                            className="cursor-pointer"
                            onClick={() => {
                              dislikePostHandle(postDetail && postDetail.id);
                            }}
                          />
                        ) : (
                          <Icon
                            icon="mdi:like"
                            color="#101331"
                            width="20"
                            height="20"
                            className="cursor-pointer"
                            onClick={() => {
                              dislikePostHandle(postDetail && postDetail.id);
                            }}
                          />
                        )
                      ) : (
                        <Icon
                          icon="mdi:like-outline"
                          color="#797979"
                          width="20"
                          height="20"
                          className="cursor-pointer"
                          onClick={() => {
                            likePostHandle(postDetail && postDetail.id, 0);
                          }}
                        />
                      )}
                      <p
                        className={`cursor-pointer ${styles.likeText}`}
                        onClick={() => {
                          if (postDetail && postDetail.is_like == 1) {
                            dislikePostHandle(postDetail && postDetail.id);
                          } else {
                            likePostHandle(postDetail && postDetail.id, 0);
                          }
                        }}
                      >
                        {t("LIKE")}
                      </p>
                      <span
                        className={
                          addClass ? styles.reactPopup : styles.reactPopupShow
                        }
                      >
                        <Icon
                          icon="mdi:like"
                          color="#101331"
                          width="20"
                          height="20"
                          className="cursor-pointer"
                          onClick={() => {
                            likePostHandle(postDetail && postDetail.id, 0);
                          }}
                        />
                        <Icon
                          icon="mdi:heart"
                          color="red"
                          width="20"
                          height="20"
                          className="cursor-pointer"
                          onClick={() => {
                            likePostHandle(postDetail && postDetail.id, 1);
                          }}
                        />
                      </span>
                    </span>
                    <span className="cursor-pointer">
                      <img
                        src={comment}
                        alt="comment"
                        className="cursor-pointer classForTop m-auto"
                      />
                      <p className={styles.likeText}>{t("COMMENT")}</p>
                    </span>
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        repostHandle(postDetail && postDetail.id);
                      }}
                    >
                      <img
                        src={repost}
                        alt="repost"
                        className="cursor-pointer classForTop m-auto"
                      />
                      <p className={styles.likeText}>{t("REPOST")}</p>
                    </span>
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href);
                        Toast.fire({
                          icon: "success",
                          title: t("CPOIED"),
                        });
                      }}
                    >
                      <img src={send} alt="send" className="m-auto" />
                      <p className={styles.likeText}>{t("SEND")}</p>
                    </span>
                  </div>
                </div>
                <div>
                  <CommentBox
                    items={postDetail && postDetail}
                    setIsLoader={setLoader}
                    commentDetailBox="commentDetailBox"
                    setIsComment={setIsComment}
                    isComment={isComment}
                  />
                </div>
                <div>
                  <div
                    className={`mt-3 ${isLoad && isLoad == true ? "mb-1" : "mb-3"
                      } ${styles.commentBoxClass}`}
                  >
                    {commentValue &&
                      commentValue.map((comments, index) => (
                        <div
                          className="flex justify-between pt-2.5 pb-2.5 items-start"
                          key={index}
                        >
                          <div className="flex gap-2 w-full items-start">
                            <div className={styles.commentUserIcons}>
                              <img
                                src={comments.profile_img}
                                alt="profile"
                                className="w-8 h-8"
                              />
                            </div>
                            <div className={`${styles.commentDetail} w-11/12`}>
                              <h2>{comments.fullname}</h2>
                              <p>{comments.comment}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  {isLoad && isLoad == true ? (
                    <span
                      className={styles.loadComments}
                      onClick={() => loadmoreHandle()}
                    >
                      {t("LOADMORE_COMMENTS")}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </section>
            </Col>
          </Row>
        </div>
      </Container>
      <ReportComment
        postIdValue={postIdValue}
        setPostIdValue={setPostIdValue}
        setOpenBox={setOpenBox}
        openReport={openReport}
        setOpenReport={setOpenReport}
        isDetail={true}
      />
      <SocialShare
        openShare={openShare}
        setOpenShare={setOpenShare}
        postIdOption={postDetail && postDetail.id}
      />
      <div className="pt-5">
        <Footer />
      </div>
    </section>
  );
}

export default PostDetail;
