import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const getTokenArr = (setTokenFound) => {
  if ("serviceWorker" in navigator) {
    const firebase_sw = "./firebase-messaging-sw.js";
    navigator.serviceWorker
      .register(firebase_sw)
      .then(function (registration) {
        return getToken(messaging, {
          vapidKey: process.env.REACT_APP_API_KEY_PAIR_VAPID,
        })
          .then((currentToken) => {
            if (currentToken) {
              setTokenFound(currentToken);
              // Track the token -> client mapping, by sending to backend server
              // show on the UI that permission is secured
            } else {
              setTokenFound(false);
              // shows on the UI that permission is required
            }
          })
          .catch((err) => {
            // catch error while creating client token
            console.log("one", err);
          });
      })
      .catch(function (err) {
        console.log(err);
      });
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
