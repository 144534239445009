import React from "react";
import styles from "./Setting.module.css";
import ProfileSection from "../HomeModule/ProfileSection";
import TrendingSection from "../HomeModule/TrendingSection";
import SettingModule from "./SettingModule";
import { Col, Container, Row } from "react-bootstrap";
import TopHeader from "../UserHeaders/TopHeader";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BiArrowBack } from "react-icons/bi";
import Footer from "../Footer/Footer";

function Setting() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <section className="profilDetailWrapper h-auto">
      <TopHeader />
      <div className="mt-10 mb-6 mx-0">
        <Container>
          <Row>
            <Col lg={3} md={12} sm={12}>
              <div
                className={`${styles.followBackBtn} flex items-center cursor-pointer`}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <BiArrowBack />
                <span className="text-lg text-center text-black font-medium">
                  {t("BACK")}
                </span>
              </div>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <h3 className={styles.detailHeadText}>{t("SETTING")}</h3>
            </Col>
            <Col lg={3} md={12} sm={12}></Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col lg={3} className="mb-4">
            <ProfileSection isSetting={true} />
          </Col>
          <Col lg={6} className="mb-4">
            <SettingModule />
          </Col>
          <Col lg={3} className="mb-4">
            <TrendingSection isSetting={true} />
          </Col>
        </Row>
      </Container>
      <div className="pt-4">
        <Footer />
      </div>
    </section>
  );
}

export default Setting;
