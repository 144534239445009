import React, { useState, memo, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import TopHeader from "../UserHeaders/TopHeader";
import BottomHeader from "../UserHeaders/BottomHeader";
import styles from "./ChatModule.module.css";
import { useTranslation } from "react-i18next";
import Footer from "../Footer/Footer";
import chatBackground from "../../Assests/Images/Chat_bg.png";
import CreateGroup from "./CreateGroup";
import ChatList from "./ChatList";

function ChatDetailResponsive() {
  const { t } = useTranslation();
  const [openGroup, setOpenGroup] = useState(false);

  return (
    <section>
      <div className={`${styles.myNetworkWrapper} background_color`}>
        <TopHeader />
        <div
          className={`${styles.backgImg} bg-cover w-full bg-center relative`}
          style={{
            backgroundImage: `url(${chatBackground})`,
          }}
        >
          <div className={styles.btmHeader}>
            <BottomHeader />
          </div>
          <p className={styles.myNetworkHeading}>{t("CHAT")}</p>
        </div>
        <div className="my-8 mx-0">
          <Container>
            <Row>
              <Col lg={6} md={12} sm={12} className="mb-4">
                <>
                  <div className={styles.responsiveChatList}>
                    <CreateGroup
                      openGroup={openGroup}
                      setOpenGroup={setOpenGroup}
                      isFromChatList={1}
                    />
                  </div>
                </>

                <ChatList openGroup={openGroup} />
              </Col>
              <Col lg={3} md={12} sm={12} className="mb-4"></Col>
            </Row>
          </Container>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </section>
  );
}

export default memo(ChatDetailResponsive);
