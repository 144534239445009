export const REQUEST_ACTION_TYPE = {
  REQUEST_PENDING: 0,
  REQUEST_ACCEPT: 1,
  REQUEST_DECLINE: 2,
};
export const REQUEST_SENT_STATUS = {
  REQUEST_SENT: 1,
  REQUEST_NOT_SENT: 0,
};
export const REQUEST_RECEIVE_STATUS = {
  RECEIVED: 1,
  NOT_RECEIVED: 0,
};
export const FRIEND_STATUS = {
  FRIEND: 1,
  NOT_FRIEND: 0,
};

export const ROOM_TYPE = {
  PRIVATE: 1,
  GROUP: 2,
};

export const IS_PRIVATE_CHAT = {
  PRIVATE_CONNECTION: 1,
  CHAT_LIST_PRIVATE_CONNECTION: 2,
};

export const IS_BLOCKED = {
  BLOCKED_USER: 1,
  UNBLOCKED_USER: 0,
};

export const MESSAGE_TYPE = {
  NORMAL_MESSAGE: 1,
  QUOTE_MESSAGE: 2,
  FORWARD_MESSAGE: 3,
};
export const MESSAGE_IS_FILE = {
  IS_FILE_TEXT: 0,
  IS_FILE_MEDIA: 1,
};

export const MESSAGE_FILE_TYPE = {
  TEXT: 0,
  IMAGE: 1,
  AUDIO: 2,
  VIDEO: 3,
};

export const LOAD_MORE = {
  NORMAL: 0,
  TOP_SCROLL: 1,
};

export const POST_MEDIA_TYPE = {
  IMAGE_MEDIA: 0,
  VEDIO_MEDIA: 1,
};
