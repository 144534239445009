import React, { useEffect } from "react";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../Assests/Styles/Common.css";
import crossImg from "../../Assests/Images/popupCrossImg.webp";
import { RiDeleteBin6Line } from "react-icons/ri";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { blue } from "@mui/material/colors";
import { useSelector } from "react-redux";
import SublyApi from "../../helpers/Api";
import { Toast } from "../../utils/Toster";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { fetchEducation } from "../../store/Slices/ProfileSlice";
import Loader from "../../utils/Loader/Loader";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";
import { STATUS_CODES } from "../../utils/StatusCode";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";

const EducationModal = ({ userEducation, setUserEducation }) => {
  const { t } = useTranslation();
  const { profile } = useSelector((state) => state.profile);
  const userEducationData = profile.education ? profile.education : [];
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  // const [selectedDate, setSelectedDate] = useState(null);
  const [errors, setErrors] = useState(false);
  const [count, setCount] = useState(
    userEducationData.length > 0 ? userEducationData.length : 1
  );
  const moment = require("moment");
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    setCount(profile?.education?.length || 0);
  }, [profile]);

  let reqErrors = "Please enter this field.";

  let resetErrors = {
    academy: "",
    speciality: "",
    start_date: "",
    end_date: "",
  };

  const [errorsMessage, setErrorsMessage] = useState([
    {
      academy: "",
      speciality: "",
      start_date: "",
      end_date: "",
    },
  ]);

  const [educationInputList, setEducationInputList] = useState([
    {
      id: "",
      academy: "",
      speciality: "",
      currently_pursuing: 0,
      start_date: null,
      end_date: null,
      dataFullfill: false,
    },
  ]);

  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  //----------function for ckeck uncheck currently pursuing checkbox------------
  const handleCheckboxChange2 = (e, index) => {
    const itemList2 = [...educationInputList];
    itemList2[index].currently_pursuing =
      itemList2[index].currently_pursuing === 0 ? 1 : 0;

    educationInputList.map((obj, itemIndex2) => {
      if (itemIndex2 != index) {
        itemList2[itemIndex2].currently_pursuing = 0;
      }
    });
    setEducationInputList(itemList2);

    let errorArray = [...errorsMessage];
    errorArray[index] = { ...errorArray[index], end_date: "" };
    setErrorsMessage(errorArray);
    itemList2[index] = { ...itemList2[index], end_date: null };
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: blue[500],
      },
    },
  });

  //----------function for show education data------------
  function showEducationData() {
    if (userEducationData.length > 0) {
      const educationListDisplay = [];
      userEducationData.length > 0 &&
        userEducationData.map((items, index) => {
          educationListDisplay.push({
            id: items.id,
            academy: items.academy,
            speciality: items.speciality,
            start_date: items.start_date,
            end_date: items.end_date,
            currently_pursuing: items.currently_pursuing,
            dataFullfill: true,
          });
        });
      setEducationInputList(educationListDisplay);
    } else {
      setEducationInputList([
        {
          id: "",
          academy: "",
          speciality: "",
          currently_pursuing: 0,
          start_date: null,
          end_date: null,
          dataFullfill: false,
        },
      ]);
    }
  }

  useEffect(() => {
    showEducationData();
  }, [userEducation]);

  //------------function for find error-----------------------------
  function findError(errorsMessage) {
    for (let i = 0; i < errorsMessage.length; i++) {
      const obj = errorsMessage[i];
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] !== "") {
          return false;
        }
      }
    }
    return true;
  }

  //----------function for add more fields to education modal------------
  const handleAddMore = () => {
    if (
      (findError(errorsMessage) &&
        educationInputList[0].dataFullfill == true) ||
      (educationInputList[0].currently_pursuing != 1 &&
        educationInputList[0].academy &&
        educationInputList[0].speciality &&
        educationInputList[0].start_date &&
        educationInputList[0].end_date)
    ) {
      setEducationInputList([
        {
          academy: "",
          speciality: "",
          start_date: null,
          end_date: null,
          currently_pursuing: 0,
          dataFullfill: false,
        },
        ...educationInputList,
      ]);
      setCount(count + 1);
    } else {
      onSaveEducation();
    }
  };

  //----------function for call api for remove education data------------
  const handleRemoveClick = async (index, items) => {
    if (items.id) {
      setIsLoader(true);
      let requestData = new FormData();
      requestData.append("education_id", items.id);
      await SublyApi.removeEducation(userToken, requestData).then(
        (responsejson) => {
          if (responsejson.status_code === 200) {
            Toast.fire({
              icon: "success",
              title: responsejson.message,
            });
            setCount(count - 1);
            dispatch(fetchEducation(userToken));
            setUserEducation(false);
            showEducationData();
            setIsLoader(false);
            let arr = [...errorsMessage];
            arr.splice(index, 1, resetErrors);
            setErrorsMessage(arr);
            setErrors(false);
          } else if (
            responsejson.data.status_code === STATUS_CODES.INVALID_TOKEN
          ) {
            Toast.fire({
              icon: "error",
              title: responsejson.data.message,
            });
            logOutDataClear();
            setIsLoader(false);
          } else {
            Toast.fire({
              icon: "error",
              title: responsejson.data.message,
            });
            setIsLoader(false);
          }
        }
      );
    } else {
      const list = [...educationInputList];
      list.splice(index, 1);
      setEducationInputList(list);
      setCount(count - 1);

      let arr = [...errorsMessage];
      arr.splice(index, 1, resetErrors);
      setErrorsMessage(arr);
      setErrors(false);
    }
  };

  //----------function for acadmy input field------------
  const handleAcademy = (e, index) => {
    // if (e.nativeEvent.data === " ") {
    //   e.preventDefault(); // Prevent the default behavior (input of space)
    //   return;
    // }
    const itemList2 = [...educationInputList];
    itemList2[index].academy = e.target.value;
    setEducationInputList(itemList2);

    let arr = [...errorsMessage];
    if (e.target.value) {
      arr.splice(index, 1, {
        ...arr[index],
        academy: "",
      });
      setErrorsMessage(arr);
      setErrors(false);
    } else {
      arr.splice(index, 1, {
        ...arr[index],
        academy: reqErrors,
      });
      setErrorsMessage(arr);
      setErrors(true);
    }
  };

  //----------function for speciality input field------------
  const handleSpeciality = (e, index) => {
    // if (e.nativeEvent.data === " ") {
    //   e.preventDefault(); // Prevent the default behavior (input of space)
    //   return;
    // }
    const itemList2 = [...educationInputList];
    itemList2[index].speciality = e.target.value;
    setEducationInputList(itemList2);

    let arr = [...errorsMessage];
    if (e.target.value) {
      arr.splice(index, 1, {
        ...arr[index],
        speciality: "",
      });
      setErrorsMessage(arr);
      setErrors(false);
    } else {
      arr.splice(index, 1, {
        ...arr[index],
        speciality: reqErrors,
      });
      setErrorsMessage(arr);
      setErrors(true);
    }
  };

  //----------function for start_date input field------------
  const handleStartDate2 = (e, index) => {
    const itemList2 = [...educationInputList];
    itemList2[index].start_date = moment(e.$d).format("YYYY-MM-DD");
    setEducationInputList(itemList2);
    // setSelectedDate();
    let d1 =
      itemList2[index].start_date &&
      new Date(itemList2[index].start_date).getTime();
    let d2 =
      itemList2[index].end_date &&
      new Date(itemList2[index].end_date).getTime();

    let arr = [...errorsMessage];
    if (e) {
      if (d2 == d1) {
        arr.splice(index, 1, {
          ...arr[index],
          start_date: "Start date must be less then end date",
        });
        setErrorsMessage(arr);
        setErrors(true);
      } else {
        arr.splice(index, 1, {
          ...arr[index],
          start_date: "",
          end_date: "",
        });
        setErrorsMessage(arr);
        setErrors(false);
      }
    } else {
      arr.splice(index, 1, {
        ...arr[index],
        start_date: reqErrors,
      });
      setErrorsMessage(arr);
      setErrors(true);
    }
  };

  //----------function for end input field------------
  const handleEndDate2 = (e, index) => {
    const itemList2 = [...educationInputList];
    itemList2[index].end_date = moment(e.$d).format("YYYY-MM-DD");
    setEducationInputList(itemList2);
    // setSelectedDate();
    let d1 =
      itemList2[index].start_date &&
      new Date(itemList2[index].start_date).getTime();
    let d2 =
      itemList2[index].end_date &&
      new Date(itemList2[index].end_date).getTime();

    let arr = [...errorsMessage];
    if (e) {
      if (d1 == d2) {
        arr.splice(index, 1, {
          ...arr[index],
          end_date: "End date must be greater then start date",
        });
        setErrorsMessage(arr);
        setErrors(true);
      } else {
        arr.splice(index, 1, {
          ...arr[index],
          end_date: "",
          start_date: "",
        });
        setErrorsMessage(arr);
        setErrors(false);
      }
    } else {
      arr.splice(index, 1, {
        ...arr[index],
        end_date: reqErrors,
      });
      setErrorsMessage(arr);
      setErrors(true);
    }
  };

  //----------function for check validations------------
  const finalInputs = async () => {
    const educationList = [];
    let requiredChecks = 0;
    let arr = [];
    await educationInputList.map((item, index) => {
      if (
        item.currently_pursuing == 1 &&
        item.academy &&
        item.speciality &&
        item.start_date
      ) {
        arr.splice(index, 1, resetErrors);
        setErrorsMessage(arr);
        setErrors(false);
      } else if (
        item.currently_pursuing != 1 &&
        item.academy &&
        item.speciality &&
        item.start_date &&
        item.end_date
      ) {
        arr.splice(index, 1, resetErrors);
        setErrorsMessage(arr);
        setErrors(false);
      } else {
        requiredChecks = 1;

        if (!item.academy) {
          arr.splice(index, 1, {
            ...arr[index],
            academy: reqErrors,
          });
          setErrorsMessage(arr);
        }

        if (!item.speciality) {
          arr.splice(index, 1, {
            ...arr[index],
            speciality: reqErrors,
          });
          setErrorsMessage(arr);
        }

        if (!item.start_date) {
          arr.splice(index, 1, {
            ...arr[index],
            start_date: reqErrors,
          });
          setErrorsMessage(arr);
        }

        if (!item.end_date) {
          arr.splice(index, 1, {
            ...arr[index],
            end_date: reqErrors,
          });
          setErrorsMessage(arr);
        }
        setErrors(true);
        return false;
      }

      educationList.push({
        id: item.id,
        academy: item.academy,
        speciality: item.speciality,
        currently_pursuing: item.currently_pursuing,
        start_date: item.start_date,
        end_date: item.end_date,
      });
    });

    if (requiredChecks == 0) {
      return educationList;
    } else {
      return [];
    }
  };

  //----------function for call api for sev experience------------
  const onSaveEducation = async (items, index) => {
    const educationList = await finalInputs();

    if (educationList.length > 0 && !errors) {
      setIsLoader(true);
      let requestData = new FormData();
      requestData.append("education_data", JSON.stringify(educationList));
      await SublyApi.sevEducation(userToken, requestData).then(
        (responsejson) => {
          if (responsejson.status_code === 200) {
            Toast.fire({
              icon: "success",
              title: responsejson.message,
            });
            dispatch(fetchEducation(userToken));
            setUserEducation(false);
            showEducationData();
            setIsLoader(false);
            let arr = [...errorsMessage];
            arr.splice(index, 1, resetErrors);
            setErrorsMessage(arr);
            setErrors(false);
          } else if (
            responsejson.data.status_code === STATUS_CODES.INVALID_TOKEN
          ) {
            Toast.fire({
              icon: "error",
              title: responsejson.data.message,
            });
            logOutDataClear();
            setIsLoader(false);
          } else {
            Toast.fire({
              icon: "error",
              title: responsejson.data.message,
            });
            setIsLoader(false);
          }
        }
      );
    }
  };

  return (
    <>
      {isLoader ? <Loader /> : ""}
      <div>
        <Modal
          show={userEducation}
          onHide={() => setUserEducation(false)}
          backdrop="static"
        >
          <Modal.Header className="crossImg">
            <Modal.Title className="modalTitle">{t("EDUCATION")}</Modal.Title>
            <img
              className="cursor-pointer"
              src={crossImg}
              alt="cross-Image"
              onClick={(index) => {
                setUserEducation(false);
                let arr = [...errorsMessage];
                arr.splice(index, 1, resetErrors);
                setErrorsMessage(arr);
                setErrors(false);
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <div className="mt-2">
              <Form>
                <div className="btn">
                  <button
                    className="addMoreBtn"
                    type="button"
                    onClick={() => handleAddMore()}
                  >
                    {" "}
                    {t("Add More")}
                  </button>
                </div>
                {educationInputList.map((items, index) => {
                  return (
                    <>
                      <Form.Group className="mb-3" key={index}>
                        <Form.Label className="labelName">
                          {t("ACADEMY")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="h-12"
                          name={`academy${index}`}
                          value={items.academy}
                          onChange={(e) => handleAcademy(e, index)}
                        />
                        {errorsMessage[index] &&
                          errorsMessage[index].academy &&
                          !items.academy && (
                            <p className="text-red-600">
                              {errorsMessage[index].academy}
                            </p>
                          )}
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label className="labelName">
                          {t("SPECIALITY")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={`h-12`}
                          name={`speciality${index}`}
                          value={items.speciality}
                          onChange={(e) => handleSpeciality(e, index)}
                        />
                        {errorsMessage[index] &&
                          errorsMessage[index].speciality &&
                          !items.speciality && (
                            <p className="text-red-600">
                              {errorsMessage[index].speciality}
                            </p>
                          )}
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <div className="startEndDate">
                          <div>
                            <Form.Label className="labelName">
                              {t("START_DATE")}
                            </Form.Label>
                            <div className="startDate">
                              <ThemeProvider theme={theme}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                      disableFuture
                                      className="custom-datepicker"
                                      format={t("DATE_FORMAT")}
                                      maxDate={dayjs(
                                        moment(items.end_date).format(
                                          "YYYY/MM/DD"
                                        )
                                      )}
                                      value={dayjs(
                                        moment(items.start_date).format(
                                          "YYYY/MM/DD"
                                        )
                                      )}
                                      onChange={(e) => {
                                        handleStartDate2(e, index);
                                      }}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </ThemeProvider>
                            </div>
                            <div>
                              {errorsMessage[index] &&
                                errorsMessage[index].start_date && (
                                  <p className="text-red-600">
                                    {errorsMessage[index].start_date}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="p-2.5"></div>

                          {items.currently_pursuing == 0 ? (
                            <div>
                              <Form.Label className="labelName">
                                {t("END_DATE")}
                              </Form.Label>
                              <div className="endDate">
                                <ThemeProvider theme={theme}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer components={["DatePicker"]}>
                                      <DatePicker
                                        disableFuture
                                        disabled={!items.start_date}
                                        className="custom-datepicker"
                                        format={t("DATE_FORMAT")}
                                        minDate={dayjs(
                                          moment(items.start_date).format(
                                            "YYYY/MM/DD"
                                          )
                                        )}
                                        onChange={(e) => {
                                          handleEndDate2(e, index);
                                        }}
                                        value={dayjs(
                                          moment(items.end_date).format(
                                            "YYYY/MM/DD"
                                          )
                                        )}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </ThemeProvider>
                              </div>
                              {errorsMessage[index] &&
                                errorsMessage[index].end_date &&
                                !items.end_date && (
                                  <p className="text-red-600">
                                    {errorsMessage[index].end_date}
                                  </p>
                                )}
                              {errorsMessage[index] &&
                                errorsMessage[index].end_date &&
                                items.end_date && (
                                  <p className="text-red-600">
                                    {errorsMessage[index].end_date}
                                  </p>
                                )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </Form.Group>

                      <Form.Check
                        name={`currently_pursuing${index}`}
                        checked={items.currently_pursuing === 1}
                        onChange={(e) => handleCheckboxChange2(e, index)}
                        label="currently pursuing"
                        className="text-no_color"
                      />

                      {count > 1 && (
                        <div className="btn">
                          <RiDeleteBin6Line className="deleteicon" />
                          <button
                            className="removeBtn"
                            type="button"
                            onClick={() => handleRemoveClick(index, items)}
                          >
                            {t("Remove")}
                          </button>
                        </div>
                      )}
                      <hr className="separationsLine" />
                    </>
                  );
                })}

                <button
                  className="saveBtn"
                  type="button"
                  onClick={onSaveEducation}
                >
                  {t("SAVE")}
                </button>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default EducationModal;
