import React from "react";
import { Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./ProfileEducation.module.css";
import moment from "moment";
import schoolImg from "../../../../Assests/Images/school-img.webp";

const ProfileEducation = ({ educationData }) => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <div className={`bg-bg_colour ${styles.educationMain}`}>
          <Row>
            <div className={`flex ${styles.education}`}>
              <p
                className={`text-solid_black font-semibold ${styles.educationHeading}`}
              >
                {t("EDUCATION")}
              </p>
            </div>

            <div className="mt-3">
              {educationData && educationData.length > 0 ? (
                educationData.map((item, index) => (
                  <>
                    <div className="flex mt-6" key={index}>
                      <div className={styles.schoolBG}>
                        <img src={schoolImg} className={styles.schoolImage} />
                      </div>
                      <div className="ml-6">
                        <p
                          className={`font-semibold text-solid_black md:text-sm sm:text-sm ${styles.universityName}`}
                        >
                          {item.academy}
                        </p>
                        <p className="text-base	font-normal	text-my_color2 md:text-sm sm:text-sm">
                          {item.speciality}
                        </p>
                        <p className="text-sm	font-normal	text-my_color2">
                          {item.start_date && item.end_date ? (
                            <span>
                              {moment(item.start_date).format("YYYY")}
                              {" - "}
                              {moment(item.end_date).format("YYYY")}
                            </span>
                          ) : (
                            <span>
                              {moment(item.start_date).format("YYYY")}
                              {" - Current"}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <hr className={styles.separationLine} />
                  </>
                ))
              ) : (
                <p>{t("NOT_AVAILABLE")}</p>
              )}
            </div>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default ProfileEducation;
