import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import editImg2 from "../../../Assests/Images/editGrayImg.webp";
import styles from "./ExpeSkills.module.css";
import ExperienceModal from "../ExperienceModal";
import { useSelector } from "react-redux";
import moment from "moment";

const ExpeSkills = () => {
  const [userExperience, setUserExperience] = useState(false);
  const { profile } = useSelector((state) => state.profile);
  const userExperienceData = profile.experience ? profile.experience : [];
  const { t } = useTranslation();

  const diff = (item) => {
    var d1 = new Date(item.start_date);
    var d2 = item?.end_date ? new Date(item?.end_date) : new Date();
    const diffYear = moment(d2).diff(d1, "years");
    const diffMonth = moment(d2).diff(d1, "months");
    const diffMonthReminder = diffMonth % 12;
    const diffDays = moment(d2).diff(d1, "days");
    if (!diffMonthReminder && !diffYear) {
      return <>{`${diffDays ? diffDays + " days" : ""}`}</>;
    }
    return (
      <>{`${diffYear ? diffYear + " yr" : ""} ${
        diffMonthReminder ? diffMonthReminder + " m'th" : ""
      }`}</>
    );
  };

  return (
    <>
      <Container>
        <div className={`bg-bg_colour ${styles.expSkill}`}>
          <div className={styles.ExperienceMain}>
            <div className={`flex ${styles.experience}`}>
              <div>
                <p
                  className={`text-solid_black font-semibold ${styles.experienceHeading}`}
                >
                  {t("EXPERIENCE")}
                </p>
              </div>
              <div>
                <img
                  src={editImg2}
                  alt="edit-image"
                  className="w-5	h-5	cursor-pointer"
                  onClick={() => setUserExperience(true)}
                />
              </div>
            </div>

            {userExperienceData && userExperienceData.length > 0 ? (
              userExperienceData.map((item, index) => (
                <>
                  <div key={index}>
                    <div className="font-medium	text-lg	text-my_color1 mt-3">
                      <p className="text-solid_black font-semibold">
                        {item.designation}
                      </p>{" "}
                      <p className="text-sm"> {item.company} </p>
                      {item.start_date && item.end_date ? (
                        <span className="text-sm">
                          {moment(item.start_date).format("MMM YYYY")}
                          {" - "}
                          {moment(item.end_date).format("MMM YYYY")}
                          {" . "}
                          {diff(item)}
                        </span>
                      ) : (
                        <span className="text-sm">
                          {moment(item.start_date).format("MMM YYYY")}
                          {" - Current . "}
                          {diff(item)}
                        </span>
                      )}
                    </div>
                    <p className="font-normal	text-sm	text-my_color2">
                      {item.company_address}
                    </p>
                  </div>
                  <div className="mt-3">
                    <span className="text-base font-medium	text-no_color mt-3">
                      {t("SKILLS")} :{" "}
                    </span>
                    <span className="text-base	font-normal	text-skills_color">
                      {item.skills}
                    </span>
                  </div>
                  <hr className={styles.separationLine} />
                </>
              ))
            ) : (
              <p>{t("NOT_AVAILABLE")}</p>
            )}
          </div>
        </div>
      </Container>
      <ExperienceModal
        userExperience={userExperience}
        setUserExperience={setUserExperience}
      />
    </>
  );
};

export default ExpeSkills;
