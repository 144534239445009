export const TRANSLATIONS_ES = {
  HEADING_TEXT: "¡TUS SOLUCIONES PARA LA COMUNIDAD!",
  PARAGRAPH_TEXT: "Un socio de conexión de confianza",
  NAVBAR_HOME: "Hogar",
  NAVBAR_SERVICES: "Servicios",
  NAVBAR_ABOUT_US: "Sobre nosotras",
  NAVBAR_CONTACT_US: "Contacta con nosotras",
  FOOTER_LEFT_TEXT:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure, desierto.",
  FOOTER_NUMBER: "+91-1234567890",
};
