import { t } from "i18next";
import React from "react";
import styles from "../src/appComponent/Home/Home.module.css";
import { Link } from "react-router-dom";

const SignButton = () => {

  return (
    <div className="pb-3 pt-3 justify-center">
      <Link to="/login">
      <button className="w-36 h-10 text-sm bg-sign_button text-white rounded">
          <p
            className={`text-white ${styles.signIn}`}
          >
            {t("SIGN_IN_SIGN_UP")}
          </p>
      </button>
      </Link>
    </div>
  );
};

export default SignButton;
