import { useEffect, useState } from "react";
import styles from "../../MyNetwork.module.css";
import { Dropdown, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../../../utils/StatusCode";
import { Toast } from "../../../../utils/Toster";
import { userLogoutClear } from "../../../../store/Slices/UserSlice";
import SublyApi from "../../../../helpers/Api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { userProfileClear } from "../../../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../../../store/Slices/NetworkSlice";
import { useParams } from "react-router-dom";
import Loader from "../../../../utils/Loader/Loader";
import { FaCircleMinus, FaEllipsis, FaCirclePlus } from "react-icons/fa6";
import { Modal } from "react-bootstrap";
import crossImg from "../../../../Assests/Images/popupCrossImg.webp";

function FollowersModal({ userFollowers, setUserFollowers }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetail = useParams();
  const { userToken, currentUser } = useSelector((state) => state.user);
  const [followerList, setFollwerList] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isFriend, setIsFriend] = useState(false);



  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  // ===function for fetch other user followers list===
  async function fetchFollowerList(isSearch, event) {
    var search = isSearch ? event.target.value : "";
    const requestData = new FormData();
    requestData.append("user_id", userDetail.id);
    requestData.append("search", search ? search : "");
    // setIsLoader(true);
    await SublyApi.followerList(userToken, requestData).then((responsejson) => {
      if (responsejson.status_code === 200) {
        setFollwerList(responsejson.data);
        // setIsLoader(false);
      } else if (responsejson.data.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: responsejson.data.message,
        });
        logOutDataClear();
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.data.message,
        });
        // setIsLoader(false);
      }
    });
  }

  // Function to open user profile
  function openUserProfile(userId) {
    // Check if the clicked user ID is the same as the current user's ID
    if (userId === currentUser.id) {
      // Redirect to the current user's profile page
      navigate("/user-profile");
    } else {
      // Redirect to the profile page of the clicked user
      navigate(`/my-network/profile-detail/${userId}`);
    }
    setUserFollowers(false);
  }

  useEffect(() => {
    fetchFollowerList(false);
  }, [userDetail.id, isFriend]);

  //====== function to send follow request ======
  async function followHandler(id) {
    const requestData = new FormData();
    requestData.append("follow_to", id);
    await SublyApi.followUser(userToken, requestData).then((response) => {
      const responsejson = response.data;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        setIsFriend(!isFriend);
      } else if (responsejson.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      } else if (responsejson.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
        dispatch(userLogoutClear());
      } else if (responsejson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        Toast.fire({
          icon: "error",
          title: `${t("SERVER_ERR")}`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      }
    });
  }

  //====== function to unfollow user======
  async function unfollowHandler(id) {
    const requestData = new FormData();
    requestData.append("follow_to", id);
    await SublyApi.unfollowUser(userToken, requestData).then((response) => {
      const responsejson = response.data;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        setIsFriend(!isFriend);
      } else if (responsejson.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      } else if (responsejson.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
        dispatch(userLogoutClear());
      } else if (responsejson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        Toast.fire({
          icon: "error",
          title: `${t("SERVER_ERR")}`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      }
    });
  }

  return (
    <>
      {isLoader ? <Loader /> : ""}
      <Modal
        show={userFollowers}
        onHide={() => setUserFollowers(false)}
        // backdrop="static"
        className="myModal"
      >
        <div className="profilDetailWrapper">
          <Modal.Header className="crossImg">
            <Modal.Title className="modalTitle">
              {t("MY_FOLLOWERS")}
            </Modal.Title>
            <img
              className="cursor-pointer"
              src={crossImg}
              alt="cross-Image"
              onClick={() => setUserFollowers(false)}
            />
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                className="h-12"
                placeholder="Search"
                onKeyUp={(e) => fetchFollowerList(true, e)}
                id="search"
              />
            </Form.Group>
            <div className={`${styles.mySuggestionsData} mb-6  bg-white`}>
              {followerList && followerList.length ? (
                followerList.map((follow, index) => (
                  <div className={`${styles.suggestionsList}`} key={index}>
                    <div
                      onClick={() => openUserProfile(follow.follow_by)}
                      className={`${styles.userInfo} cursor-pointer`}
                    >
                      <span className={styles.requestImg}>
                        <img src={follow.profile_img} alt="img" />
                      </span>
                      <div className={styles.requestDetail}>
                        <p>{follow.fullname}</p>
                        <span> {follow.current_designation}</span>
                      </div>
                    </div>

                    <div className={`userActionBtn connectionsList`}>
                      {currentUser.id !== follow.follow_by && (
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            <FaEllipsis className={`text-xl text-slate-700`} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {follow.is_following == 0 ? (
                              <Dropdown.Item
                                as="button"
                                onClick={() => followHandler(follow.follow_by)}
                              >
                                <FaCirclePlus />
                                {t("FOLLOW")}
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                as="button"
                                onClick={() =>
                                  unfollowHandler(follow.follow_by)
                                }
                              >
                                <FaCircleMinus />
                                {t("UNFOLLOW")}
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <span className="text-center">{t("NO_FOLLOWER")}</span>
              )}

              {followerList && followerList.length > 10 ? (
                <div className={`${styles.seeMoreBtn} text-center mb-1`}>
                  <span className="cursor-pointer">{t("SEE_MORE")}</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default FollowersModal;
