import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";

const initialState = {
    count: {},
    requests: [],
    connections: [],
    connectionData: {},
    suggestions: [],
    suggestionData: {},
    isLoading: false,
    success: false,
    error: null,
};

// Thunk for count data
export const fetchCountData = createAsyncThunk(
    "count",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SublyApi.fetchCount(data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

// Thunk for requests list
export const requestList = createAsyncThunk(
    "requests",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SublyApi.requestList(data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

// Thunk for connections list
export const connectionList = createAsyncThunk(
    "connections",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SublyApi.connectionList(data.userToken, data.requestData);
            return { response: response, loadValue: data.loadValue };
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

// Thunk for suggestions list
export const suggestionList = createAsyncThunk(
    "suggestions",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SublyApi.suggestionList(data.userToken, data.requestData);
            return { response: response, loadValue: data.loadValue };
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const NetworkSlice = createSlice({
    name: "network",
    initialState: initialState,
    reducers: {
        userNetworkClear: (state, action) => {
            state.requests = [];
            state.connections = [];
            state.suggestions = [];
            state.isLoading = false;
            state.userToken = null;
        },
        handleSuggestions :(state,action) =>{
            state.suggestions =action.payload.suggestions
        },
        handleConnections :(state,action) =>{
            state.connections =action.payload.connections
        }
    },
    extraReducers: (builder) => {
        // ------Builder for request list-------

        builder.addCase(fetchCountData.pending, (state) => {
            state.isLoading = true;
        })

        builder.addCase(fetchCountData.fulfilled, (state, action) => {
            const response = action.payload;
            state.isLoading = false;
            if (response.status_code === STATUS_CODES.SUCCESS) {
                state.count = response.data;
                state.success = true;
            } else {
                state.success = false;
            }
        })

        builder.addCase(fetchCountData.rejected, (state, action) => {
            state.isLoading = false
        })

        builder.addCase(requestList.pending, (state) => {
            state.isLoading = true;
        })

        builder.addCase(requestList.fulfilled, (state, action) => {
            const response = action.payload;
            state.isLoading = false;
            if (response.status_code === STATUS_CODES.SUCCESS) {
                state.requests = response.data;
                state.success = true;
            } else {
                state.success = false;
            }
        })

        builder.addCase(requestList.rejected, (state, action) => {
            state.isLoading = false
        })

        builder.addCase(connectionList.pending, (state) => {
            state.isLoading = true;
        })

        builder.addCase(connectionList.fulfilled, (state, action) => {
            const response = action.payload.response;
            state.isLoading = false;
            if (response.status_code === STATUS_CODES.SUCCESS) {
                if (action.payload.loadValue == true) {
                    state.connections = state.connections.concat(
                        response.data.connectionList
                    );
                } else {
                    state.connections = response.data.connectionList;
                }
                state.connectionData = response.data;
                state.success = true;
            } else {
                state.success = false;
            }
        })

        builder.addCase(connectionList.rejected, (state, action) => {
            state.isLoading = false;
        })

        builder.addCase(suggestionList.pending, (state) => {
            state.isLoading = true;
        })

        builder.addCase(suggestionList.fulfilled, (state, action) => {
            const response = action.payload.response;
            state.isLoading = false;
            if (response.status_code === STATUS_CODES.SUCCESS) {
                if (action.payload.loadValue == true) {
                    state.suggestions = state.suggestions.concat(
                        response.data.getSuggestionUsers
                    );
                } else {
                    state.suggestions = response.data.getSuggestionUsers;
                }
                state.suggestionData = response.data;
                state.success = true;
            } else {
                state.success = false;
            }
        })

        builder.addCase(suggestionList.rejected, (state, action) => {
            state.isLoading = false;
        })
    }
})

export const { userNetworkClear } = NetworkSlice.actions;

export const { handleSuggestions } = NetworkSlice.actions;

export default NetworkSlice.reducer;