import React from "react";
import styles from "./HomeModule.module.css";
import share from "../../Assests/Images/share.svg";
import report from "../../Assests/Images/report.svg";
import copy from "../../Assests/Images/copy.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";
import { Toast } from "../../utils/Toster";
import { handleKeyValues } from "../../store/Slices/MediaSlice";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import { Icon } from "@iconify/react";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";
import { useNavigate } from "react-router-dom";

function OptionsList({
  setOpenReport,
  setOpenShare,
  myPost,
  itemId,
  setOpenBox,
  setIsLoader,
  isDetail,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  const { postMediaList } = useSelector((state) => state.media);
  const navigate = useNavigate();

  // =======clear redux========
  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  // function for handle delete post
  async function handleDeletePost() {
    setIsLoader(true);
    const requestData = new FormData();
    requestData.append("post_id", itemId);
    await SublyApi.deletePost(userToken, requestData).then(async (response) => {
      if (response.status_code === STATUS_CODES.SUCCESS) {
        setOpenBox(false);
        Toast.fire({
          icon: "success",
          title: response.message,
        });
        setIsLoader(false);
        if (isDetail) {
          navigate("/");
        }
        const MediaObject = postMediaList.filter(
          (element) => element.id !== itemId
        );
        dispatch(handleKeyValues({ postMediaList: MediaObject }));
      } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: response.message,
        });
        setIsLoader(false);
      } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        logOutDataClear();
        setIsLoader(false);
      } else {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        setIsLoader(false);
      }
    });
  }

  return (
    <section className={styles.optionsBody}>
      <div
        onClick={() => {
          setOpenShare(true);
        }}
      >
        <img src={share} alt="share" />
        <p>{t("SHARE")}</p>
      </div>
      {myPost == 1 ? (
        ""
      ) : (
        <div
          onClick={() => {
            setOpenReport(true);
          }}
        >
          <img src={report} alt="report" />
          <p>{t("REPORT_POST")}</p>
        </div>
      )}
      <div
        onClick={() => {
          if (isDetail) {
            navigator.clipboard.writeText(window.location.href);
          } else {
            navigator.clipboard.writeText(
              `${window.location.protocol}//${window.location.host}/post-detail/${itemId}`
            );
          }
          setOpenBox(false);
          Toast.fire({
            icon: "success",
            title: t("CPOIED"),
          });
        }}
      >
        <img src={copy} alt="copy" />
        <p>{t("COPY_LINK")}</p>
      </div>
      {myPost == 1 ? (
        <div
          onClick={() => {
            handleDeletePost();
          }}
        >
          <Icon
            icon="fluent:delete-48-filled"
            color="#4c4e66"
            width="20"
            height="20"
          />
          <p>{t("DELETE_POST")}</p>
        </div>
      ) : (
        ""
      )}
    </section>
  );
}

export default OptionsList;
