import React from "react";
import { Modal } from "react-bootstrap";
import Loader from "../../utils/Loader/Loader";
import styles from "./Setting.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";
import { Toast } from "../../utils/Toster";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";
import { useNavigate } from "react-router-dom";
import { srachListClear } from "../../store/Slices/SearchSlice";
import { Icon } from "@iconify/react";

function DeleteAccount({ isLoader, openDelete, setOpenDelete, setIsLoader }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.user);

  // =======clear redux========
  function deleteUserDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
    dispatch(srachListClear());
  }

  // === function for calling user delete api ===
  async function userDeleteHandle() {
    await SublyApi.deleteUser(userToken).then((response) => {
      if (response.status_code === STATUS_CODES.SUCCESS) {
        Toast.fire({
          icon: "success",
          title: response.message,
        });
        deleteUserDataClear();
        navigate("/login");
        setOpenDelete(false);
      } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: response.message,
        });
        setIsLoader(false);
      } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        setIsLoader(false);
      } else if (response.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        setIsLoader(false);
      } else {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        setIsLoader(false);
      }
    });
  }

  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <Modal
        show={openDelete}
        onHide={() => {
          setOpenDelete(false);
        }}
        className="modalCloseBtn"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles.modalTitle}>
            {t("DELETE_ACCOUNT")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.textAreaClass}>
          <div className={styles.iconText}>
            <Icon
              icon="solar:danger-triangle-broken"
              color="red"
              width="100"
              height="100"
            />
          </div>
          <div className={styles.iconText}>{t("WANT_TO_DELETE")}</div>
          <div className={styles.Btns}>
            <button
              className={styles.cancelBtn}
              onClick={() => setOpenDelete(false)}
            >
              {t("CANCEL")}
            </button>
            <button
              className={styles.deleteBtn}
              onClick={() => {
                userDeleteHandle();
              }}
            >
              {t("DELETE")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default DeleteAccount;
