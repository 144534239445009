import React from "react";
import VideoPlayer from "./VideoPlayer";
import { Col, Row } from "react-bootstrap";
import "./ImageCollage.css";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { POST_MEDIA_TYPE } from "../../utils/Constants";

function ImageCollage({ img, postData }) {
  const navigate = useNavigate();

  return (
    <div
      className={`${
        img[0].media_type == POST_MEDIA_TYPE.VEDIO_MEDIA
          ? "ImageCover"
          : "px-2.5 ImageCover"
      }`}
    >
      {img[0].media_type == POST_MEDIA_TYPE.VEDIO_MEDIA ? (
        <VideoPlayer videoUrl={img[0].post_url} />
      ) : img.length < 3 ? (
        <Row
          onClick={() => {
            navigate(`/post-detail/${postData.id}`);
          }}
        >
          {img.map((imgs, index) => (
            <Col
              key={index}
              className={`${
                img.length == 2
                  ? "h-96 manageImage heightManageImg"
                  : "manageImage"
              }`}
            >
              <img src={imgs.post_url} />
            </Col>
          ))}
        </Row>
      ) : img.length == 3 ? (
        <Row
          onClick={() => {
            navigate(`/post-detail/${postData.id}`);
          }}
        >
          <Col className="manageImage">
            <img src={img[0].post_url} />
          </Col>
          <Col className="manageImage">
            <Row>
              <Col lg={12} sm={12} className="pb-1.5">
                <img src={img[1].post_url} />
              </Col>
              <Col lg={12} sm={12}>
                <img src={img[2].post_url} />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : img.length >= 4 ? (
        <Row
          onClick={() => {
            navigate(`/post-detail/${postData.id}`);
          }}
        >
          <Col className="manageImage">
            <img src={img[0].post_url} />
          </Col>
          <Col className="manageImage">
            <Row>
              <Col lg={12} sm={12} className="pb-1.5">
                <img src={img[1].post_url} />
              </Col>
              <Col lg={12} sm={12}>
                <Row className="ImageHeight">
                  <Col lg={6} sm={6} xs={6} className="pr-1">
                    <img src={img[2].post_url} />
                  </Col>
                  <Col
                    lg={6}
                    sm={6}
                    xs={6}
                    className={`${img.length > 4 ? "pl-1 lastImage" : "pl-1"}`}
                  >
                    <img src={img[3].post_url} />
                    {img.length > 4 && <p>{t("SEE_MORE")}</p>}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </div>
  );
}

export default ImageCollage;
