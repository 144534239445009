import React, { useState } from "react";
import { Container, Toast } from "react-bootstrap";
import Logo from "../../Assests/Images/linkeii_logo 2.webp";
import NotificationImg from "../../Assests/Images/Group 182.webp";
import styles from "./TopHeader.module.css";
import { useSelector } from "react-redux";
import ProfileModal from "./ProfileModalBox";
import $ from "jquery";
import { NavLink, useNavigate } from "react-router-dom";
import NotificationModal from "../SettingModule/NotificationModal";
import { onMessageListener } from "../../firebase";
import { useTranslation } from "react-i18next";
import Notify from "../../Assests/Images/favicon.ico";
import { RxCross2 } from "react-icons/rx";


const TopHeader = (props) => {
  const { t } = useTranslation();
  const { profileDetail } = useSelector((state) => state.profile);
  const [openModal, setOpenModal] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "", type: "", reffrenceId: "" });
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  // ====function to hide box on click outside====
  $(document).mouseup(function (e) {
    if ($(e.target).closest(".outSideClick").length === 0) {
      setOpenModal(false);
    }
  });

  //====here i am getting the notification value====
  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
        type: payload.data.notification_type,
        reffrenceId: payload.data.reference_id,
      });
    })
    .catch((err) => console.log("failed: ", err));
  return (
    <div
      className={`flex bg-bg_colour h-24 mt-3.5 commonLinkClass ${props.topHeaderClass}`}
    >
      <Container>
        <div className={`flex ${styles.topHeaderImg}`}>
          <NavLink to="/user-home">
            <div>
              <img className="h-14 w-36 object-cover" src={Logo} />
            </div>
          </NavLink>
          <div className={`flex ${styles.twoImg}`}>
            <div
              onClick={() => {
                setOpenNotification(true);
              }}
            >
              <img
                className="h-11 w-11 mr-5 cursor-pointer object-cover"
                src={NotificationImg}
              />
            </div>
            <div
              className={`${openModal ? styles.profileImgBox : styles.imgRadius
                } outSideClick`}
            >
              <img
                className="h-11 w-11 cursor-pointer"
                src={profileDetail.profile_img}
                onClick={() => {
                  openModal ? setOpenModal(false) : setOpenModal(true);
                }}
              />
              {openModal && <ProfileModal />}
            </div>
          </div>
        </div>
      </Container>
      <NotificationModal
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
        setIsLoader={setIsLoader}
        isLoader={isLoader}
      />
      <div className="App deleteIcon firebaseNotification" >
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={5000}
          autohide
          animation
          style={{
            position: "fixed",
            top: 50,
            right: 10,
            zIndex: 99999,
            margin: 0,
          }}

        >
          <RxCross2 className="crossIcon"
            onClick={() => {
              setShow(false);
            }}
          />
          <Toast.Header onClick={() => {
            if (notification.type == "post_liked") {
              navigate(`/post-detail/${notification.reffrenceId}`);
            } else {
              if (notification.type == "send_request") {
                navigate(
                  `/my-network/profile-detail/${notification.reffrenceId}`
                );
              }
              else if (notification.type == "accept_connection_request" ||
                notification.type == "decline_connection_request") {
                navigate(
                  `/my-network/profile-detail/${notification.reffrenceId}`
                );
              }
              else if (notification.type == "start_follow") {
                navigate(
                  `/my-network/profile-detail/${notification.reffrenceId}`
                );
              }
            }
          }}>

            <div className="Notifydiv">
              <img
                src={Notify}
                className="rounded mr-2"
                alt="notification=img"
              />
            </div>
            <div className="NotifyPara">
              <strong className="mr-auto">{notification.title}</strong>{" "}
              <p>{notification.body}</p>
            </div>
            {/* <RxCross2
              onClick={() => {
                setShow(false);
              }}
            /> */}
          </Toast.Header>

        </Toast>
      </div>
    </div>
  );
};

export default TopHeader;
