import React, { useEffect } from "react";
import styles from "./HomeModule.module.css";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { STATUS_CODES } from "../../utils/StatusCode";
import { profileDataDetail } from "../../store/Slices/ProfileSlice";
import { Toast } from "../../utils/Toster";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";

function ProfileSection({ isSetting }) {
  const { t } = useTranslation();
  const { userToken, currentUser } = useSelector((state) => state.user);
  const { profileDetail } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // =======clear redux========
  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  // function for fetch profileDataDetail
  useEffect(() => {
    async function fetchProfileData() {
      dispatch(profileDataDetail(userToken)).then((responsejson) => {
        const response = responsejson.payload;
        if (response.status_code === STATUS_CODES.SUCCESS) {
          // console.log("profileDataDetail response",response)
        } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: response.message,
          });
        } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
          Toast.fire({
            icon: "error",
            title: response.data.message,
          });
          logOutDataClear();
        } else if (response.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        } else {
          Toast.fire({
            icon: "error",
            title: response.message,
          });
        }
      });
    }
    fetchProfileData();
  }, []);

  return (
    <section
      className={
        isSetting == "chatDetail"
          ? ""
          : isSetting && isSetting
          ? styles.forSettingModule
          : styles.profileSection
      }
    >
      <div className={styles.profileBody}>
        <div className={styles.profileImg}>
          <img src={profileDetail.profile_img} />
        </div>{" "}
        <h3 className="text-xl font-semibold">{profileDetail.fullname}</h3>
        <p className="text-lg font-medium">
          {profileDetail.current_designation}
        </p>
        <p className="my-1.5">
          <span
            onClick={() => {
              navigate(`/followers/${currentUser.id}`);
            }}
            className="cursor-pointer"
          >
            {profileDetail.follower_count} {t("FOLLOWERS")}
          </span>{" "}
          |{" "}
          <span
            onClick={() => {
              navigate(`/my-network/${1}`);
            }}
            className="cursor-pointer"
          >
            {profileDetail.connection_count} {t("CONNECTION")}
          </span>
        </p>
        <button>
          <NavLink to="/user-profile">{t("VIEW_PROFILE")}</NavLink>
        </button>
      </div>
    </section>
  );
}

export default ProfileSection;
