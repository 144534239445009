import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import editImg2 from "../../../Assests/Images/editGrayImg.webp";
import styles from "./MyTags.module.css";
import TagModal from "../TagModal";
import { useSelector } from "react-redux";

const MyTag = () => {
  const { t } = useTranslation();
  const [userTag, setUserTag] = useState(false);
  const { profile } = useSelector((state) => state.profile);
  const userMyTagData = profile.tags ? profile.tags : [];
  return (
    <>
      <Container>
        <div className={`bg-bg_colour ${styles.myTagMain}`}>
          <div className={`flex ${styles.myTag}`}>
            <div>
              <p className={`text-xl	font-semibold	text-solid_black`}>
                {t("MY_TAG")}
              </p>
            </div>
            <div className="flex">
              <div>
                <p className="text-nav_hover text-lg mr-4">{t("MANAGE_TAG")}</p>
              </div>
              <img
                src={editImg2}
                alt="edit-image"
                className="w-5	h-5	cursor-pointer"
                onClick={() => setUserTag(true)}
              />
            </div>
          </div>
          <div className="mt-3">
            {userMyTagData && userMyTagData.length > 0 ? (
              userMyTagData.map((item, index) => (
                <span
                  key={index}
                  className={`text-my_color2 text-lg font-medium mt-3 ml-1 ${styles.tagText}`}
                >
                  {"#"}
                  {item.tag_name}{" "}
                </span>
              ))
            ) : (
              <p
                className={`text-my_color2 text-lg font-medium mt-3 ${styles.tagText}`}
              >
                {t("NOT_AVAILABLE")}
              </p>
            )}
          </div>
        </div>
        <TagModal userTag={userTag} setUserTag={setUserTag} />
      </Container>
    </>
  );
};

export default MyTag;
