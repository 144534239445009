import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import { Col, Container, Row } from "react-bootstrap";
import ProfileSection from "./ProfileSection";
import styles from "./HomeModule.module.css";
import TopHeader from "../UserHeaders/TopHeader";
import BottomHeader from "../UserHeaders/BottomHeader";
import TrendingSection from "./TrendingSection";
import HomeHeader from "./HomeHeader";
import PostField from "./PostField";
import Loader from "../../utils/Loader/Loader";
import { useSelector } from "react-redux";

function HomeModule() {
  const { isLoading } = useSelector((state) => state.media);

  return (
    <>
      {/* {isLoading ? <Loader /> : ""} */}
      <div className="relative h-36">
        <TopHeader topHeaderClass={"topHeaderClass"} />
        <BottomHeader headerClass={"headerClass"} />
      </div>
      <HomeHeader />
      <section className={styles.homeModuleBody}>
        <Container>
          <Row>
            <Col lg={3} className="mb-4">
              <ProfileSection />
            </Col>
            <Col lg={6}>
              <PostField />
            </Col>
            <Col lg={3} className="mb-4">
              <TrendingSection />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default HomeModule;
