import React from "react";
import { Container } from "react-bootstrap";
import styles from "./AboutProfile.module.css";
import { useTranslation } from "react-i18next";

const AboutProfile = ({ aboutData }) => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <div className={`bg-bg_colour ${styles.aboutMain}`}>
          <div className={`flex ${styles.aboutMe}`}>
            <p className={`text-no_color font-semibold ${styles.aboutText}`}>
              {t("ABOUT")}
            </p>
          </div>
          <p className="mt-3 text-sm font-normal whitespace-pre-wrap">
            {aboutData ? aboutData : "NA"}
          </p>
        </div>
      </Container>
    </>
  );
};

export default AboutProfile;
