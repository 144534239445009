import React from "react";
import Logo from "../../Assests/Images/linkeii_logo 2.webp";
import Facebook from "../../Assests/Images/facebook.webp";
import Instagram from "../../Assests/Images/instagram.webp";
import Linkedin from "../../Assests/Images/linkedin.webp";
import Youtube from "../../Assests/Images/youtube.webp";
import styles from "./Footer.module.css";
import { services } from "./Services";
import { navigate } from "./Navigation";
import SignButton from "../../SignButton";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Container, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";

const Footer = () => {
  const { t } = useTranslation();
  const { userToken } = useSelector((state) => state.user);
  return (
    <div className={`bg-bg_colour ${styles.footerMain}`}>
      <Container>
        <section className="pt-3">
          <div className="flex flex-col md:flex-row lg:flex-row 2xl:flex-row lgg:flex-row xl:flex-row lg:flex-wrap md:flex-wrap sm:flex-wrap ">
            <div className="2xl:w-1/2 xl:w-1/2 lgg:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2 justify-center sm:text-center">
              <div>
                <Link to="/">
                  <img src={Logo} alt="Logo"></img>
                </Link>
              </div>
              <div className="pt-1 text-my_color1 mr-11 sm:mr-0 sm:text-left">
                {t("FOOTER_TEXT")}
              </div>

              <a href={`tel:++91-1234567890`} target="blank">
                <div className="pt-1.5 text-lg text-no_color sm:text-left">
                  {t("NUMBER")}
                </div>
              </a>

              <a href={`https://mail.google.com/mail/?view=cm&to=${process.env.REACT_APP_LINKIII_MAIL_ID}&su=${"Subject"}`} target="blank">
                <div className="pt-1.5 text-my_color1 sm:text-left">
                  {process.env.REACT_APP_LINKIII_MAIL_ID}
                </div>
              </a>
            </div>
            <div
              className={`pt-2 2xl:w-1/4 xl:w-1/4 lgg:w-1/4 lg:w-1/2 md:w-1/2 sm:w-1/2 ${styles.navigation}`}
            >
              <div className="pt-1 font-bold">{t("NAVIGATION")}</div>
              <div className="pt-2">
                <div className="pb-2.5 text-my_color2 cursor-pointer">
                  {navigate.map((item) => (
                    <div key={item.id}>
                      <Nav.Link href="javascript:;">
                        <NavLink to={item.link}>{item.name}</NavLink>
                      </Nav.Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className={`pt-2 2xl:w-1/4 xl:w-1/4 lgg:w-1/4 lg:w-1/2 md:w-1/2 sm:w-1/2 ${styles.services}`}
            >
              <div className="pt-1 font-bold">{t("SERVICES")}</div>
              <div className="pt-2">
                <div className="pb-2.5 text-my_color2 cursor-pointer">
                  {services.map((item) => (
                    <div key={item.id}>
                      <Nav.Link href="javascript:;">
                        <NavLink to={item.link}>{item.name}</NavLink>
                      </Nav.Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="pt-2 2xl:w-1/2 xl:w-1/2 lgg:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2">
              <div className="pt-1 font-bold">{t("JOIN_US")}</div>
              <div className="pt-2 sm:justify-center sm:items-center">
                <div className="pb-2 text-my_color2 mr-11 sm:mr-0 sm:text-left">
                  {t("FOOTER_TEXT")}
                </div>
                <div>{userToken ? "" : <SignButton />}</div>
                <div className="flex pt-2 sm:items-center">
                  <Link to={process.env.REACT_APP_FACEBOOK_LINK}>
                    <img className="pr-3" src={Facebook} alt="Facebook"></img>
                  </Link>
                  <Link to={process.env.REACT_APP_INSTAGRAM_LINK}>
                    <img className="pr-3" src={Instagram} alt="Instagram"></img>
                  </Link>
                  <Link to={process.env.REACT_APP_LINKEDIN_LINK}>
                    <img className="pr-3" src={Linkedin} alt="LinkedIn"></img>
                  </Link>
                  <Link to={process.env.REACT_APP_YOUTUBE_LINK}>
                    <img className="pr-3" src={Youtube} alt="Youtube"></img>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
};
export default Footer;
