import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import bakeImg from "../../Assests/Images/back.webp";
import sideImg from "../../Assests/Images/signup.webp";
import googleImg from "../../Assests/Images/search1.webp";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../appComponent/Header/Header";
import Footer from "../../appComponent/Footer/Footer";
import styles from "./SignUp.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { blue } from "@mui/material/colors";
import "../../Assests/Styles/Common.css";
import SublyApi from "../../helpers/Api";
import { Toast } from "../../utils/Toster";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { socialSignUp, userSignUp } from "../../store/Slices/UserSlice";
import Loader from "../../utils/Loader/Loader";
import { STATUS_CODES } from "../../utils/StatusCode";
import { useGoogleLogin } from "@react-oauth/google";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import $ from "jquery";

const SignUp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.user);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const [selectedDate, setSelectedDate] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  // === function for handle checkbox ===
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: blue[500],
        light: blue[500],
      },
    },
  });

  // ===  function for calling api userSignup ===
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append("fullname", formdata.name.trim());
    requestData.append("email", formdata.email.trim());
    requestData.append(
      "dob",
      moment(formdata.dateOfBirth.$d).format("YYYY-MM-DD")
    );
    requestData.append("password", formdata.password.trim());
    requestData.append("cnf_password", formdata.confirmPassword.trim());
    dispatch(userSignUp(requestData)).then((response) => {
      const responsejson = response.payload;
      if (responsejson.status_code === 200) {
        Toast.fire({
          icon: "success",
          title: responsejson.message,
        });
        navigate("/login");
      } else if (responsejson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        Toast.fire({
          icon: "error",
          title: `${t("SERVER_ERR")}`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.data.message,
        });
      }
    });
  };

  //  === function for manage social signup ===
  const socialSignUpToken = useGoogleLogin({
    onSuccess: (tokenResponse) => verifyGoogleToken(tokenResponse),
  });
  const verifyGoogleToken = async (data) => {
    let requestData = new FormData();
    requestData.append("access_token", data.access_token);
    await SublyApi.verifyGoogleLogin(requestData).then((response) => {
      const responsejson = response;
      if (responsejson.status_code === 200) {
        socialSignUps(responsejson.data);
      } else if (responsejson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        Toast.fire({
          icon: "error",
          title: `${t("SERVER_ERR")}`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.data.message,
        });
      }
    });
  };

  // === Calling api for social signup ===
  const socialSignUps = async (formdata) => {
    let requestData = new FormData();
    // console.log("formdata", formdata);
    requestData.append("social_email", formdata.email);
    requestData.append("social_key", formdata.sub);
    requestData.append("fullname", formdata.name);
    requestData.append("social_type", 1);
    dispatch(socialSignUp(requestData)).then((response) => {
      // console.log("response", response);
      const responsejson = response.payload;
      if (responsejson.status_code === 200) {
        Toast.fire({
          icon: "success",
          title: responsejson.message,
        });
      } else if (responsejson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        Toast.fire({
          icon: "error",
          title: `${t("SERVER_ERR")}`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.data.message,
        });
      }
    });
  };

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  const onInputChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  const passwordKeyPress = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };
  return (
    <>
      {isLoading ? <Loader /> : ""}
      <Header />
      <div className={styles.signUpMain}>
        <Container>
          <div className={styles.signContainer}>
            <Row>
              <Col lg={6}>
                <div className={styles.signUpSide}>
                  <div className="flex mb-3">
                    <img
                      onClick={() => navigate(-1)}
                      className="pt-4 w-5 h-11 cursor-pointer"
                      src={bakeImg}
                      alt="back"
                    />
                    <p className="font-medium mt-6 ml-2.5">{t("BACK")}</p>
                  </div>
                  <p
                    className={`text-4xl text-sign_button ${styles.signHeading}`}
                  >
                    {t("CREAT_NEW_ACCOUNT")}
                  </p>
                  <p
                    className={`text-my_color1 leading-5 ${styles.signSubHeading}`}
                  >
                    {t("ENTER_INFORMATION")}
                  </p>
                  <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        className={`text-sign_placeholder h-12 ${styles.fullName}`}
                        type="text"
                        name="name"
                        placeholder={t("FULL_NAME")}
                        {...register("name", {
                          onChange: (e) => {
                            handleKeyPress(e);
                          },
                          required: {
                            value: true,
                            message: `${t("NAME_REQUIRED")}`,
                          },
                          maxLength: {
                            value: 50,
                            message: `${t("NAME_TO_LONG")}`,
                          },
                          minLength: {
                            value: 2,
                            message: `${t("NAME_TO_SHORT")}`,
                          },
                          ...sanitizeHtmlTags(),
                        })}
                      />
                      <div className="text-error_message">
                        {errors.name ? <span>{errors.name.message}</span> : ""}
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        className={`h-12 ${styles.email}`}
                        placeholder={t("EMAIL")}
                        {...register("email", {
                          onChange: (e) => onInputChange(e),
                          required: {
                            value: true,
                            message: `${t("EMAIL_REQUIRED")}`,
                          },
                          pattern: {
                            value:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: `${t("EMAIL_INVALID")}`,
                          },
                          ...sanitizeHtmlTags(),
                        })}
                      />
                      <div className="text-error_message">
                        {errors.email ? (
                          <span>{errors.email.message}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <div className="dateOfBirth">
                        <Controller
                          control={control}
                          name="dateOfBirth"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <ThemeProvider theme={theme}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                  <DatePicker
                                    disableFuture
                                    className="custom-datepicker"
                                    label={t("DATE_OF_BIRTH_LABEL")}
                                    format={t("DATE_FORMAT")}
                                    onChange={onChange}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </ThemeProvider>
                          )}
                        />
                        <div className="text-error_message">
                          {errors.dateOfBirth && (
                            <p>{t("DATE_OF_BIRTH_REQUIRED")}</p>
                          )}
                        </div>
                      </div>
                    </Form.Group>

                    <Form.Group
                      className="mb-3 setIconPassword"
                      controlId="formBasicPassword"
                    >
                      <Form.Control
                        className={`h-12 ${styles.password}`}
                        name="password"
                        type={passwordShow ? "text" : "password"}
                        placeholder={t("PASSWORD")}
                        {...register("password", {
                          onChange: (e) => {
                            passwordKeyPress(e);
                          },
                          required: {
                            value: true,
                            message: `${t("PASSWORD_REQUIRED")}`,
                          },
                          minLength: {
                            value: 8,
                            message: `${t("PASSWORD_MIN_CHARACTER")}`,
                          },
                          maxLength: {
                            value: 15,
                            message: `${t("PASS_MAXLENGTH")}`,
                          },
                          pattern: {
                            value:
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                            message: `${t("PASSWORD_MUST_CONTAIN")}`,
                          },
                          ...sanitizeHtmlTags(),
                        })}
                      />
                      <div className="text-error_message">
                        {errors.password ? (
                          <span>{errors.password.message}</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="passwordicon">
                        {passwordShow ? (
                          <FaEye
                            className="icon"
                            onClick={() => setPasswordShow(!passwordShow)}
                          />
                        ) : (
                          <FaEyeSlash
                            className="icon"
                            onClick={() => setPasswordShow(!passwordShow)}
                          />
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group
                      className="mb-3 setIconPassword"
                      controlId="formBasicPassword"
                    >
                      <Form.Control
                        className={`h-12 ${styles.confirmPassword}`}
                        type={confirmPasswordShow ? "text" : "password"}
                        name="confirmPassword"
                        placeholder={t("CONFIRM_PASSWORD")}
                        {...register("confirmPassword", {
                          onChange: (e) => {
                            passwordKeyPress(e);
                          },
                          ...sanitizeHtmlTags(),
                          required: {
                            value: true,
                            message: `${t("CONFIRM_PASSWORD_REQUIRED")}`,
                          },
                          validate: (value) =>
                            value === password.current ||
                            `${t("CONFIRM_PASSWORD_NOT_MATCH")}`,
                        })}
                      />
                      <div className="text-error_message">
                        {errors.confirmPassword ? (
                          <span>{errors.confirmPassword.message}</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="passwordicon">
                        {confirmPasswordShow ? (
                          <FaEye
                            className="icon"
                            onClick={() =>
                              setConfirmPasswordShow(!confirmPasswordShow)
                            }
                          />
                        ) : (
                          <FaEyeSlash
                            className="icon"
                            onClick={() =>
                              setConfirmPasswordShow(!confirmPasswordShow)
                            }
                          />
                        )}
                      </div>
                    </Form.Group>

                    <div className="flex">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        className={styles.checkBox}
                      />
                      <p className={styles.checkText}>
                        {t("AGREE_WITH")}{" "}
                        <Link to="javascript:;" className="underline">
                          {t("TERMS_CONDITIONS")}
                        </Link>
                      </p>
                    </div>
                    <div>
                      <button
                        type="submit"
                        disabled={!isChecked}
                        className={
                          isChecked ? styles.signUpBtn : styles.signUpBtnDisable
                        }
                      >
                        {t("SIGN_UP")}
                      </button>
                    </div>
                  </Form>

                  <div className={`my-8 ${styles.orTextBlock} `}>
                    <span className={styles.orText}>{t("OR")}</span>
                  </div>

                  <div className={styles.googleLogin}>
                    <div className="flex">
                      <img
                        className={`mr-2.5 ${styles.googleImg}`}
                        src={googleImg}
                        alt="googleSearch"
                      />
                      <span
                        className={`text-no_color ${styles.googleText}`}
                        onClick={() => socialSignUpToken()}
                      >
                        {t("GOOGLE")}
                      </span>
                    </div>
                  </div>

                  <p
                    className={`text-center mb-2 text-my_color1 ${styles.signInUrl}`}
                  >
                    {t("ALREADY_LINKEIII_MEMBER")}
                    <Link
                      to="/login"
                      className={`text-no_color ${styles.signInText}`}
                    >
                      {" "}
                      {t("SIGN_IN_TEXT")}
                    </Link>
                  </p>
                </div>
              </Col>
              <Col lg={6}>
                <img
                  className={`${styles.sideImg}`}
                  src={sideImg}
                  alt="sign-up"
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};
export default SignUp;
