import React, { useState } from "react";
import { Container } from "react-bootstrap";
import editImg2 from "../../../Assests/Images/editGrayImg.webp";
import styles from "./AboutMe.module.css";
import { useTranslation } from "react-i18next";
import AboutModal from "../AboutModal";
import { useSelector } from "react-redux";

const AboutMe = () => {
  const { t } = useTranslation();
  const [userAbout, setUserAbout] = useState(false);

  const { profile } = useSelector((state) => state.profile);
  return (
    <>
      <Container>
        <div className={`bg-bg_colour ${styles.aboutMain}`}>
          <div className={`flex ${styles.aboutMe}`}>
            <p className={`text-solid_black font-semibold ${styles.aboutText}`}>
              {t("ABOUT_ME2")}
            </p>
            <img
              src={editImg2}
              alt="edit-image"
              className="w-5	h-5	cursor-pointer"
              onClick={() => setUserAbout(true)}
            />
          </div>
          <p className="mt-3 text-sm font-normal whitespace-pre-wrap text-my_color2">
            {profile.about_me && profile.about_me ? profile.about_me : "NA"}
          </p>
        </div>
      </Container>
      <AboutModal userAbout={userAbout} setUserAbout={setUserAbout} />
    </>
  );
};

export default AboutMe;
