import React from "react";
import placeholder from "../../Assests/Images/placeholder.png";
import styles from "./HomeModule.module.css";
import { useNavigate } from "react-router-dom";

function TrendingOption({ item }) {
  const navigate = useNavigate();

  return (
    <>
      <section className={styles.trendingList} onClick={() => {
        navigate(`/post-detail/${item.id}`);
      }}>
        <div className="flex item-center">
          <div>
            <img
              src={
                item.thumbnail
                  ? item.thumbnail
                  : item.post_url
                    ? item.post_url
                    : placeholder
              }
              className="mr-4"
            />
          </div>

          <div
            className="w-4/5"
            // onClick={() => {
            //   navigate("/user-profile");
            // }}
          >
            <h3>{item.fullname}</h3>
            <p>{item.post_caption}</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default TrendingOption;
