import React, { useEffect } from "react";
import { useState, useRef } from "react";
import forgotPasswordImg from "../../Assests/Images/forgotPassword.webp";
import bakeImg from "../../Assests/Images/back.webp";
import Header from "../../appComponent/Header/Header";
import Footer from "../../appComponent/Footer/Footer";
import styles from "./VerifyForgotPassword.module.css";
import { Col, Form, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import SublyApi from "../../helpers/Api";
import { Toast } from "../../utils/Toster";
import { STATUS_CODES } from "../../utils/StatusCode";
import Loader from "../../utils/Loader/Loader";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import $ from "jquery";

const VerifyForgotPassword = () => {
  const token = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  const newPassword = useRef({});
  newPassword.current = watch("newPassword", "");

  // ===  function for calling api verifyForgot password ===
  const onSubmit = async (formdata) => {
    setIsLoader(true);
    let requestData = new FormData();
    requestData.append("forgot_pass_code", formdata.verificationCode);
    requestData.append("new_password", formdata.newPassword);
    requestData.append("confirm_password", formdata.confirmPassword);
    requestData.append("token_data", token.tokenData);
    await SublyApi.verifyForgotPassword(requestData).then((responsejson) => {
      if (responsejson.status_code === 200) {
        Toast.fire({
          icon: "success",
          title: responsejson.message,
        });
        navigate("/login");
        setIsLoader(false);
      } else if (responsejson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        Toast.fire({
          icon: "error",
          title: `${t("SERVER_ERR")}`,
        });
        setIsLoader(false);
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.data.message,
        });
        setIsLoader(false);
      }
    });
  };

  const passwordKeyPress = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };
  return (
    <>
      {isLoader ? <Loader /> : ""}
      <Header />
      <div className={styles.forgotPasswordMain}>
        <Container>
          <div className={styles.forgotContainer}>
            <Row>
              <Col lg={6}>
                <div className={styles.forgotSide}>
                  <div className="flex mb-3 pb-4">
                    <img
                      onClick={() => navigate(-1)}
                      className="pt-4 w-5 h-11 cursor-pointer"
                      src={bakeImg}
                      alt="back"
                    />
                    <p className="font-medium mt-6 ml-2.5">{t("BACK")}</p>
                  </div>

                  <p
                    className={`text-4xl text-sign_button ${styles.forgotHeading}`}
                  >
                    {t("VERIFY_FORGOT_PASSWORD")}
                  </p>

                  <Form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        className="h-12"
                        type="text"
                        placeholder={t("ENTER_VERIFICATION_CODE")}
                        {...register("verificationCode", {
                          onChange: (e) => {
                            passwordKeyPress(e);
                          },
                          required: {
                            value: true,
                            message: `${t("VERIFICATION_CODE_REQUIRED")}`,
                          },
                          ...sanitizeHtmlTags(),
                        })}
                      />
                      <div className="text-error_message">
                        {errors.verificationCode ? (
                          <span>{errors.verificationCode.message}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3 setIconPassword" controlId="formBasicEmail">
                      <Form.Control
                        className="h-12"
                        type={passwordShow ? "text" : "password"}
                        placeholder={t("ENTER_NEW_PASSWORD")}
                        {...register("newPassword", {
                          onChange: (e) => {
                            passwordKeyPress(e);
                          },
                          required: {
                            value: true,
                            message: `${t("PASSWORD_REQUIRED")}`,
                          },
                          minLength: {
                            value: 8,
                            message: `${t("PASSWORD_MIN_CHARACTER")}`,
                          },
                          maxLength: {
                            value: 15,
                            message: `${t("PASS_MAXLENGTH")}`,
                          },
                          pattern: {
                            value:
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                            message: `${t("PASSWORD_MUST_CONTAIN")}`,
                          },
                          ...sanitizeHtmlTags(),
                        })}
                      />
                      <div className="text-error_message">
                        {errors.newPassword ? (
                          <span>{errors.newPassword.message}</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="passwordicon">
                        {passwordShow ? (
                          <FaEye
                            className="icon"
                            onClick={() => setPasswordShow(!passwordShow)}
                          />
                        ) : (
                          <FaEyeSlash
                            className="icon"
                            onClick={() => setPasswordShow(!passwordShow)}
                          />
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3 setIconPassword" controlId="formBasicEmail">
                      <Form.Control
                        className="h-12"
                        type={confirmPasswordShow ? "text" : "password"}
                        placeholder={t("ENTER_CONFIRM_PASSWORD")}
                        {...register("confirmPassword", {
                          onChange: (e) => {
                            passwordKeyPress(e);
                          },
                          ...sanitizeHtmlTags(),
                          required: {
                            value: true,
                            message: `${t("CONFIRM_PASSWORD_REQUIRED")}`,
                          },
                          validate: (value) =>
                            value === newPassword.current ||
                            `${t("CONFIRM_PASSWORD_NOT_MATCH")}`,
                        })}
                      />
                      <div className="text-error_message">
                        {errors.confirmPassword ? (
                          <span>{errors.confirmPassword.message}</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="passwordicon">
                        {confirmPasswordShow ? (
                          <FaEye className="icon" onClick={() => setConfirmPasswordShow(!confirmPasswordShow)} />
                        ) : (
                          <FaEyeSlash
                            className="icon"
                            onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                          />
                        )}
                      </div>
                    </Form.Group>

                    <div>
                      <button
                        type="submit"
                        className={`h-12 text-2xl bg-sign_button text-white rounded ${styles.submitBtn} `}
                      >
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </Form>
                </div>
              </Col>
              <Col lg={6}>
                <img
                  className={`${styles.forgotPassword}`}
                  src={forgotPasswordImg}
                  alt="forgot-password"
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default VerifyForgotPassword;
