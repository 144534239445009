import React, { useEffect, useState } from "react";
import styles from "./Login.module.css";
import { Container, Form, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import sideLoginImg from "../../Assests/Images/login-side-img.webp";
import googleImg from "../../Assests/Images/search1.webp";
import Header from "../../appComponent/Header/Header";
import Footer from "../../appComponent/Footer/Footer";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Toast } from "../../utils/Toster";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import {
  socialLogin,
  socialSignUp,
  userLogin,
} from "../../store/Slices/UserSlice";
import Loader from "../../utils/Loader/Loader";
import { STATUS_CODES } from "../../utils/StatusCode";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import SublyApi from "../../helpers/Api";
import $ from "jquery";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.user);
  const [passwordShow, setPasswordShow] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  // === function for manage googleLogin process ===
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => verifyGoogleToken(tokenResponse),
    // flow: "auth-code",
  });

  const verifyGoogleToken = async (data) => {
    let requestData = new FormData();
    requestData.append("access_token", data.access_token);
    await SublyApi.verifyGoogleLogin(requestData).then((response) => {
      // console.log("verifyGoogleToken response ", response);
      const responsejson = response;
      if (responsejson.status_code === 200) {
        responseGoogle(responsejson.data);
      } else if (responsejson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        Toast.fire({
          icon: "error",
          title: `${t("SERVER_ERR")}`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.data.message,
        });
      }
    });
  };

  // Social Login with Google
  const responseGoogle = async (response) => {
    let userData = response;
    // console.log("userData", userData);
    if (userData) {
      let requestData = new FormData();
      requestData.append("social_email", userData.email);
      requestData.append("social_key", userData.sub);
      dispatch(socialLogin(requestData)).then((response) => {
        // console.log("response", response);
        const responsejson = response.payload;
        if (responsejson.status_code === STATUS_CODES.SUCCESS) {
          Toast.fire({
            icon: "success",
            title: responsejson.message,
          });
        } else if (
          responsejson &&
          responsejson.data.status == STATUS_CODES.SOCIAL_USER_NOT_FOUND
        ) {
          dispatch(socialSignUp(requestData)).then((signresponsejson) => {
            // console.log("signresponsejson", signresponsejson);
            const response = signresponsejson.payload;
            if (response.status_code === STATUS_CODES.SUCCESS) {
              Toast.fire({
                icon: "success",
                title: response.message,
              });
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.message,
              });
            }
          });
        } else {
          Toast.fire({
            icon: "Error",
            title: response.message,
          });
        }
      });
    }
  };

  // == function for userLogin api calling ===
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append("email", formdata.email.trim());
    requestData.append("password", formdata.password.trim());
    dispatch(userLogin(requestData)).then((response) => {
      const responsejson = response.payload;
      if (responsejson.status_code === 200) {
        Toast.fire({
          icon: "success",
          title: responsejson.message,
        });
        navigate("/user-home");
      } else if (responsejson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        Toast.fire({
          icon: "error",
          title: `${t("SERVER_ERR")}`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.data.message,
        });
      }
    });
  };

  // disable space on key press input email field
  const onInputChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  // disable space on key press input password field
  const passwordKeyPress = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <Header />
      <div className={styles.loginMain}>
        <Container>
          <div className={styles.loginContainer}>
            <Row>
              <Col lg={6}>
                <div className={styles.loginSide}>
                  <div>
                    <p
                      className={`text-4xl text-sign_button ${styles.loginHeading}`}
                    >
                      {t("LOGIN")}
                    </p>
                    <p
                      className={`text-my_color1 leading-5 ${styles.loginSubHeading}`}
                    >
                      {t("ALREADY_A_MEMBER")}
                    </p>
                  </div>
                  <Form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3">
                      <Form.Control
                        className={` h-12 ${styles.email}`}
                        placeholder={t("EMAIL")}
                        {...register("email", {
                          onChange: (e) => onInputChange(e),
                          required: {
                            value: true,
                            message: `${t("EMAIL_REQUIRED")}`,
                          },
                          pattern: {
                            value:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: `${t("EMAIL_INVALID")}`,
                          },
                          ...sanitizeHtmlTags(),
                        })}
                      />
                      <div className="text-error_message">
                        {errors.email ? (
                          <span>{errors.email.message}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group
                      className="mb-3 setIconPassword"
                      controlId="formBasicPassword"
                    >
                      <Form.Control
                        className={`h-12 ${styles.password}`}
                        type={passwordShow ? "text" : "password"}
                        placeholder={t("PASSWORD")}
                        {...register("password", {
                          onChange: (e) => {
                            passwordKeyPress(e);
                          },
                          required: {
                            value: true,
                            message: `${t("PASSWORD_REQUIRED")}`,
                          },
                          minLength: {
                            value: 8,
                            message: `${t("PASSWORD_MIN_CHARACTER")}`,
                          },
                          maxLength: {
                            value: 15,
                            message: `${t("PASS_MAXLENGTH")}`,
                          },
                          pattern: {
                            value:
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                            message: `${t("PASSWORD_MUST_CONTAIN")}`,
                          },
                          ...sanitizeHtmlTags(),
                        })}
                      />
                      <div className="text-error_message">
                        {errors.password ? (
                          <span>{errors.password.message}</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="passwordicon">
                        {passwordShow ? (
                          <FaEye
                            className="icon"
                            onClick={() => setPasswordShow(!passwordShow)}
                          />
                        ) : (
                          <FaEyeSlash
                            className="icon"
                            onClick={() => setPasswordShow(!passwordShow)}
                          />
                        )}
                      </div>
                    </Form.Group>
                    <div>
                      <p className={styles.forgotPassword}>
                        <Link to="/forgot-password">
                          {t("FORGOT_PASSWORD")}
                        </Link>
                      </p>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className={`h-12 text-2xl bg-sign_button text-white rounded ${styles.signInBtn} `}
                      >
                        {t("SIGN_IN")}
                      </button>
                    </div>
                  </Form>

                  <div className={`my-8 ${styles.orTextBlock} `}>
                    <span className={styles.orText}>{t("OR")}</span>
                  </div>

                  <div className={styles.googleLogin} onClick={() => login()}>
                    <div className="flex">
                      <img
                        className={`mr-2.5 ${styles.googleImg}`}
                        src={googleImg}
                        alt="googleSearch"
                      />
                      <span className={`text-no_color ${styles.googleText}`}>
                        {t("GOOGLE")}
                      </span>
                    </div>
                  </div>

                  <div>
                    <p className={`text-center ${styles.newLinkiii}`}>
                      {t("NEW_TO_LINKIII")}{" "}
                      <Link to="/sign-up" className="font-semibold">
                        {t("JOIN_NOW")}
                      </Link>
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="mt-16">
                  <img
                    className={`${styles.sideLoginImg}`}
                    src={sideLoginImg}
                    alt="login"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Login;
