import React, { useEffect, useState } from "react";
import styles from "./HomeModule.module.css";
import threedot from "../../Assests/Images/overflow.svg";
import heart from "../../Assests/Images/icon-like.svg";
import thumb from "../../Assests/Images/like-outline.svg";
import { useTranslation } from "react-i18next";
import send from "../../Assests/Images/send-fill.svg";
import repost from "../../Assests/Images/repost.svg";
import CommentBox from "./CommentBox";
import OptionsList from "./OptionsList";
import { useDispatch, useSelector } from "react-redux";
import { fetchPostMedia, handleKeyValues } from "../../store/Slices/MediaSlice";
import { STATUS_CODES } from "../../utils/StatusCode";
import { Toast } from "../../utils/Toster";
import moment from "moment";
import ImageCollage from "./ImageCollage";
import $ from "jquery";
import { Icon } from "@iconify/react";
import SublyApi from "../../helpers/Api";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../utils/Loader/Loader";
import ReportComment from "./ReportComment";
import SocialShare from "./SocialShare";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";
import { useNavigate } from "react-router-dom";



function PostField() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.user);
  const { postMedia, postMediaList } = useSelector((state) => state.media);
  const [openBox, setOpenBox] = useState(false);
  const [postIdValue, setPostIdValue] = useState(null);
  const [postIdOption, setPostIdOption] = useState("");
  const [addClass, setAddClass] = useState(false);
  const [pageValue, setPageValue] = useState(1);
  const [isLoader, setIsLoader] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [showMore, setShowMore] = useState(false);

  // =======clear redux========
  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  // ------------API for create a post----------------
  async function getMediaPost(page, loadValue) {
    const requestData = new FormData();
    requestData.append("page_no", page ? page : pageValue);
    await dispatch(
      fetchPostMedia({
        userToken: userToken,
        requestData: requestData,
        loadValue: loadValue,
      })
    ).then((responsejson) => {
      const response = responsejson.payload.response;
      if (response.status_code === STATUS_CODES.SUCCESS) {
      } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: response.message,
        });
      } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        logOutDataClear();
      } else if (response.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
      } else {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
      }
    });
  }

  useEffect(() => {
    getMediaPost(1, false);
    setPageValue(1);
    // =====here when come to screen or refresh back to top====
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  // ====function for page increament====
  function pageHandle() {
    getMediaPost(pageValue + 1, true);
    setPageValue(pageValue + 1);
  }

  // =====Hadnling for see more functionality======
  function seemoreHandle(value, actionValue) {
    const MediaObject = [...postMediaList];
    MediaObject[value] = { ...MediaObject[value], seeMore: actionValue };
    dispatch(handleKeyValues({ postMediaList: MediaObject }));
  }

  // ====function to hide box on click outside====
  $(document).mouseup(function (e) {
    if ($(e.target).closest(".openBoxClass").length === 0) {
      setOpenBox(false);
    }
  });

  // ------------API for like and heart------------
  async function likePostHandle(postId, action, value) {
    const requestData = new FormData();
    requestData.append("like_type", action);
    requestData.append("post_id", postId);
    await SublyApi.likePost(userToken, requestData).then(
      async (responsejson) => {
        if (responsejson.status_code === STATUS_CODES.SUCCESS) {
          const MediaObject = [...postMediaList];
          MediaObject[value] = { ...MediaObject[value], like_type: action };
          MediaObject[value] = { ...MediaObject[value], is_like: 1 };
          if (postMediaList[value].is_like !== 1) {
            MediaObject[value] = {
              ...MediaObject[value],
              total_likes: postMediaList[value].total_likes + 1,
            };
          }
          dispatch(handleKeyValues({ postMediaList: MediaObject }));
        } else if (responsejson.data.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
        } else if (
          responsejson.data.status_code === STATUS_CODES.INVALID_TOKEN
        ) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          logOutDataClear();
        } else {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
        }
      }
    );
  }

  // ------------API for dislike------------
  async function dislikePostHandle(postId, value) {
    const requestData = new FormData();
    requestData.append("post_id", postId);
    await SublyApi.dislikePost(userToken, requestData).then(
      async (responsejson) => {
        if (responsejson.status_code === STATUS_CODES.SUCCESS) {
          const MediaObject = [...postMediaList];
          MediaObject[value] = { ...MediaObject[value], like_type: null };
          MediaObject[value] = { ...MediaObject[value], is_like: 0 };
          if (postMediaList[value].is_like == 1) {
            MediaObject[value] = {
              ...MediaObject[value],
              total_likes: postMediaList[value].total_likes - 1,
            };
          }
          dispatch(handleKeyValues({ postMediaList: MediaObject }));
          setIsLoader(false);
        } else if (responsejson.data.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          setIsLoader(false);
        } else if (
          responsejson.data.status_code === STATUS_CODES.INVALID_TOKEN
        ) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          setIsLoader(false);
          logOutDataClear();
        } else {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          setIsLoader(false);
        }
      }
    );
  }

  // ======API for repost post======
  async function repostHandle(postId) {
    setIsLoader(true);
    const requestData = new FormData();
    requestData.append("post_id", postId);
    await SublyApi.repostPost(userToken, requestData).then(
      async (responsejson) => {
        if (responsejson.status_code === STATUS_CODES.SUCCESS) {
          Toast.fire({
            icon: "success",
            title: responsejson.message,
          });
          const requestData = new FormData();
          requestData.append("page_no", 1);
          await dispatch(
            fetchPostMedia({ userToken: userToken, requestData: requestData })
          );
          setPageValue(1);
          setIsLoader(false);
          // =====here after response back to top====
          $("document").ready(function () {
            $(window).scrollTop(0);
          });
        } else if (responsejson.data.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          setIsLoader(false);
        } else if (
          responsejson.data.status_code === STATUS_CODES.INVALID_TOKEN
        ) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          setIsLoader(false);
          logOutDataClear();
        } else {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          setIsLoader(false);
        }
      }
    );
  }

  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <InfiniteScroll
        dataLength={
          postMediaList && postMediaList.length
            ? postMediaList && postMediaList.length
            : 0
        }
        next={pageHandle}
        hasMore={postMedia.loadMore}
      >
        {postMediaList && postMediaList.length > 0
          ? postMediaList &&
          postMediaList.map((items, index) => (

            <div className={`mb-4 ${styles.postFieldBody}`} key={index}>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className={styles.userIcon}>
                    <img
                      src={items.profile_img}
                      alt="profile"
                      className="mr-4"
                    />
                  </div>
                  <div className={styles.profileDetail}>
                    <h2>{items.fullname}</h2>
                    <p>{items.current_designation}</p>
                  </div>
                </div>
                <div
                  className={`${openBox
                    ? "relative openBoxClass"
                    : "relative closeBoxClass"
                    }`}
                >
                  <img
                    src={threedot}
                    alt="threeDot"
                    className="cursor-pointer"
                    onClick={() => {
                      openBox ? setOpenBox(false) : setOpenBox(true);
                      setPostIdOption(items.id);
                    }}
                  />
                  {openBox && items.id === postIdOption ? (
                    <OptionsList
                      setOpenReport={setOpenReport}
                      setOpenShare={setOpenShare}
                      myPost={items.my_post}
                      itemId={items.id}
                      setOpenBox={setOpenBox}
                      pageValue={pageValue}
                      setIsLoader={setIsLoader}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className={styles.postDetail}>
                <p
                  className={`${items.post_caption ? "pt-3 mb-2.5" : "mb-3"}`}
                >
                  {items.seeMore === 1
                    ? items.post_caption
                    : items.post_caption.length > 250
                      ? items.post_caption.substring(0, 250) + "...."
                      : items.post_caption.substring(0, 250)}
                </p>
              </div>
              <div className="text-end">
                {items.post_caption.length > 250 ? (
                  <span
                    className={`mb-3 ${styles.seeMoreClass}`}
                    onClick={() => {
                      seemoreHandle(index, items.seeMore == 1 ? 0 : 1);
                      setShowMore(!showMore);
                    }}
                  >
                    {items.seeMore === 1 ? t("SEE_LESS") : t("SEE_MORE")}
                  </span>
                ) : (
                  ""
                )}
              </div>
              {items.postMedia.length > 0 ? (
                <div className={styles.postMedia}>
                  <ImageCollage img={items.postMedia} postData={items} />
                </div>
              ) : (
                ""
              )}
              <div className={styles.reactSection}>
                <div
                  className={`flex relative items-center gap-2.5 ${styles.responsiveReact}`}
                >
                  <div className={styles.thumbIcon}>
                    <img src={thumb} />
                  </div>
                  <div className={styles.heartIcon}>
                    <img src={heart} />
                  </div>
                  <p>{items.total_likes}</p>

                  {<p className="pl-1.5 cursorComment" onClick={() => {
                    navigate(`/post-detail/${items.id}`);
                  }}>
                    {items.total_comments}{" "}
                    {items.total_comments > 1 ? t("COMMENTS") : t("COMMENT")}
                  </p>
                  }

                  <p className="pl-1.5">
                    {items.repost_count} {t("REPOST")}
                  </p>
                </div>
                <div
                  className={`flex item-center gap-4 ${styles.reactIcons}`}
                >
                  <span
                    className="relative hoverClass"
                    id={items.id}
                    onMouseOver={() => {
                      setPostIdValue(items.id);
                      setAddClass(true);
                    }}
                    onMouseLeave={() => {
                      setTimeout(() => {
                        setAddClass(false);
                      }, 800);
                    }}
                  >
                    {items.is_like == 1 ? (
                      items.like_type == 1 ? (
                        <Icon
                          icon="mdi:heart"
                          color="red"
                          width="25"
                          height="25"
                          className="cursor-pointer"
                          onClick={() => {
                            dislikePostHandle(items.id, index);
                          }}
                        />
                      ) : (
                        <Icon
                          icon="mdi:like"
                          color="#101331"
                          width="25"
                          height="25"
                          className="cursor-pointer"
                          onClick={() => {
                            dislikePostHandle(items.id, index);
                          }}
                        />
                      )
                    ) : (
                      <Icon
                        icon="mdi:like-outline"
                        color="#797979"
                        width="25"
                        height="25"
                        className="cursor-pointer"
                        onClick={() => {
                          likePostHandle(items.id, 0, index);
                        }}
                      />
                    )}
                    {items.id === postIdValue && (
                      <span
                        className={
                          addClass ? styles.reactPopup : styles.reactPopupShow
                        }
                      >
                        <Icon
                          icon="mdi:like"
                          color="#101331"
                          width="25"
                          height="25"
                          className="cursor-pointer"
                          onClick={() => {
                            likePostHandle(items.id, 0, index);
                            setPostIdValue(items.id);
                          }}
                        />
                        <Icon
                          icon="mdi:heart"
                          color="red"
                          width="25"
                          height="25"
                          className="cursor-pointer"
                          onClick={() => {
                            likePostHandle(items.id, 1, index);
                            setPostIdValue(items.id);
                          }}
                        />
                      </span>
                    )}
                  </span>
                  <img
                    src={repost}
                    alt="repost"
                    className="cursor-pointer classForTop"
                    onClick={() => {
                      repostHandle(items.id);
                    }}
                  />
                  <img
                    src={send}
                    alt="send"
                    className="cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${window.location.protocol}//${window.location.host}/post-detail/${items.id}`
                      );
                      Toast.fire({
                        icon: "success",
                        title: t("CPOIED"),
                      });
                    }}
                  />
                </div>
              </div>
              <div>
                <CommentBox
                  items={items}
                  setIsLoader={setIsLoader}
                  pageValue={pageValue}
                  index={index}
                />
              </div>
              <div className={`mt-3 mb-3 ${styles.commentBoxClass}`}>
                {items.recent_comments.map((comments, index) => (
                  <div
                    className="flex justify-between pt-3 pb-3 items-start"
                    key={index}
                  >
                    <div className="flex gap-2 w-full items-start">
                      <div className={styles.commentUserIcons}>
                        <img
                          src={comments.profile_img}
                          alt="profile"
                          className="w-10 h-10"
                        />
                      </div>
                      <div className={`${styles.commentDetail} w-11/12`}>
                        <h2>{comments.fullname}</h2>
                        <p>{comments.comment}</p>
                      </div>
                    </div>
                    <div className={styles.commentTime}>
                      <p>{moment(comments.commented_at).fromNow(true)}</p>
                    </div>
                  </div>
                ))}
              </div>
              <span
                className={styles.loadComments}
                onClick={() => {
                  navigate(`/post-detail/${items.id}`);
                }}
              >
                {t("MORE_INFO")}
              </span>
            </div>
          ))
          : ""}
      </InfiniteScroll>
      <ReportComment
        postIdValue={postIdOption}
        setPostIdValue={setPostIdOption}
        setOpenBox={setOpenBox}
        openReport={openReport}
        setOpenReport={setOpenReport}
        pageValue={pageValue}
      />
      <SocialShare
        openShare={openShare}
        setOpenShare={setOpenShare}
        postIdOption={postIdOption}
      />
    </section>
  );
}

export default PostField;
