import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Navbar, Nav } from "react-bootstrap";
import { BottomHeaderData } from "./BottomHeaderData";
import styles from "./BottomHeader.module.css";
import { FiSearch } from "react-icons/fi";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { fetchSearchList } from "../../store/Slices/SearchSlice";
import { STATUS_CODES } from "../../utils/StatusCode";

const BottomHeader = (props) => {
  const { userToken } = useSelector((state) => state.user);
  const { searchList } = useSelector((state) => state.search);
  const dispatch = useDispatch();
  const { register, setValue } = useForm();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(props.search && props.search);
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    if (props.search) {
      setValue("Search", props.search);
      if (isSearch) {
        document.body.classList.add("fixedBody");
      }
    } else {
      document.body.classList.remove("fixedBody");
    }
  }, [props.search]);

  // === function for handle global search ===
  const Globalsearchhandler = async (search) => {
    if (search) {
      setIsSearch(true);
      document.body.classList.add("fixedBody");
      dispatch(fetchSearchList({ userToken: userToken, search: search })).then(
        (responsejson) => {
          if (responsejson.status_code === STATUS_CODES.SUCCESS) {
          }
        }
      );
    } else {
      setIsSearch(false);
    }
  };

  const searchHandler = async (event) => {
    setSearchValue(event.target.value);
    if (event.target.value === "") {
      // setSearchList([]);
      document.body.classList.remove("fixedBody");
    } else {
      document.body.classList.add("fixedBody");
    }
    Globalsearchhandler(event.target.value);
    if (event.target.value && searchList.length > 0 && event.keyCode == 13) {
      document.body.classList.remove("fixedBody");
      setIsSearch(false);
      navigate(`/search-for/${event.target.value}`);
    }
  };

  return (
    <>
      <div>
        <Container
          className={`${
            props.headerClass ? props.headerClass : styles.mainContainer
          }`}
        >
          <div
            className={`bg-mid_background pl-8 pr-4 commonLinkClass ${styles.bottomNav}`}
          >
            <Navbar expand="lg" className={styles.test}>
              <div className={styles.searchBarMain}>
                {isSearch && searchList ? (
                  <div
                    className={styles.searchModal}
                    onClick={() => {
                      setIsSearch(false);
                      // setValue("Search", "");
                      document.body.classList.remove("fixedBody");
                    }}
                  >
                    <div className={`${styles.searchListWrapper}`}>
                      {searchList.length > 0 ? (
                        searchList.map((item, index) => (
                          <div
                            key={index}
                            className={`${styles.searchListItem} cursor-pointer`}
                            onClick={() =>
                              navigate(`/my-network/profile-detail/${item.id}`)
                            }
                          >
                            <p>
                              <span
                                className={`${styles.fullName} font-semibold text-black`}
                              >
                                {item.fullname}
                              </span>
                              {item.current_designation && <i></i>}
                              <span>{item.current_designation}</span>
                            </p>
                            <img src={item.profile_img} />
                          </div>
                        ))
                      ) : (
                        <div className={`${styles.searchListItem}`}>
                          <p>No Data Found</p>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <input
                  className={`text-mid_text2 ${styles.searchBar}`}
                  type="text"
                  autoComplete="off"
                  {...register("Search")}
                  placeholder="Search"
                  onKeyUp={searchHandler}
                  onFocus={() => {
                    Globalsearchhandler(searchValue);
                  }}
                />
                <FiSearch />
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  className={styles.toggleBtn}
                />
              </div>
              <Navbar.Collapse id="basic-navbar-nav" className="grow-0">
                <Nav className="me-auto">
                  {BottomHeaderData.map((item) => (
                    <div key={item.id} className="relative forNavResponse">
                      <NavLink className={`classForNav`} to={item.link}>
                        {item.name}
                      </NavLink>
                    </div>
                  ))}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </Container>
      </div>
    </>
  );
};

export default BottomHeader;
