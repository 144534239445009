import React, { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import "../../Assests/Styles/Common.css";
import crossImg from "../../Assests/Images/popupCrossImg.webp";
import SublyApi from "../../helpers/Api";
import { Toast } from "../../utils/Toster";
import { useDispatch, useSelector } from "react-redux";
import { profileData } from "../../store/Slices/ProfileSlice";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";
import { STATUS_CODES } from "../../utils/StatusCode";
import Loader from "../../utils/Loader/Loader";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";

const AboutModal = ({ userAbout, setUserAbout }) => {
  const { t } = useTranslation();
  const { profile } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const { userToken } = useSelector((state) => state.user);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  // === function for submit user about data ====
  const onSubmit = async (formdata) => {
    setIsLoader(true);
    let requestData = new FormData();
    requestData.append("about_me", formdata.about);

    await SublyApi.userAboutMe(userToken, requestData).then((responsejson) => {
      if (responsejson.status_code === 200) {
        Toast.fire({
          icon: "success",
          title: responsejson.message,
        });
        dispatch(profileData(userToken));
        setUserAbout("");
        setIsLoader(false);
      } else if (responsejson.data.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: responsejson.data.message,
        });
        logOutDataClear();
        setIsLoader(false);
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.data.message,
        });
        setIsLoader(false);
      }
    });
  };

  useEffect(() => {
    setValue("about", profile.about_me);
  }, [userAbout]);

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  return (
    <>
      {isLoader ? <Loader /> : ""}
      <div>
        <Modal
          show={userAbout}
          onHide={() => setUserAbout(false)}
          backdrop="static"
        >
          <Modal.Header className="crossImg">
            <Modal.Title className="modalTitle">{t("ABOUT_ME")}</Modal.Title>
            <img
              className="cursor-pointer"
              src={crossImg}
              alt="cross-Image"
              onClick={() => setUserAbout(false)}
            />
          </Modal.Header>
          <Modal.Body>
            <div className="mt-8">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                  <Form.Label className="text-base font-semibold">
                    {t("ABOUT")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows={6}
                    className=""
                    {...register("about", {
                      onChange: (e) => {
                        handleKeyPress(e);
                      },
                      required: {
                        value: true,
                        message: `${t("ABOUT_REQUIRED")}`,
                      },
                      maxLength: {
                        value: 1000,
                        message: `${t("ABOUT_TO_LONG")}`,
                      },
                      ...sanitizeHtmlTags(),
                    })}
                  />
                  <div className="text-error_message">
                    {errors.about ? <span>{errors.about.message}</span> : ""}
                  </div>
                </Form.Group>

                <button className="saveBtn">{t("SAVE")}</button>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default AboutModal;
