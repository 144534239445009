import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "react-bootstrap";
import styles from "./ProfileSkills.module.css";
import { useTranslation } from "react-i18next";
import "react-circular-progressbar/dist/styles.css";

const ProfileSkills = ({ skillData }) => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <div className={`bg-bg_colour ${styles.skillMain}`}>
          <div>
            <p className={`text-no_color font-semibold ${styles.skillHeading}`}>
              {t("SKILLS")}
            </p>
          </div>
          <div className="mt-4 flex flex-wrap">
            {skillData && skillData.length > 0 ? (
              skillData.map((skill, index) => (
                <span
                  key={index}
                  className={`text-my_color2 text-base mt-3 ml-1 ${styles.skillProgress}`}
                >
                  {skill.skill_name}{" "}
                </span>
              ))
            ) : (
              <p>{t("NOT_AVAILABLE")}</p>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default ProfileSkills;
