import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";

const initialState = {
  currentUser: {},
  isLoading: false,
  userToken: null,
  success: false,
  error: null,
};

// Thunk for user sign-up
export const userSignUp = createAsyncThunk(
  "user/userSign-up",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.signUp(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user login
export const userLogin = createAsyncThunk(
  "user/userLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.login(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user social sign-up
export const socialSignUp = createAsyncThunk(
  "user/socialSignUp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.socialSignUpHandle(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user social Login
export const socialLogin = createAsyncThunk(
  "user/socialLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.socialLoginHandle(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogoutClear: (state, action) => {
      state.currentUser = {};
      state.isLoading = false;
      state.userToken = null;
    },
    handleNotificationKey: (state, action) => {
      state.currentUser = action.payload.currentUser;
    },
  },
  extraReducers: (builder) => {
    // ------Builder for signup-------
    builder.addCase(userSignUp.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userSignUp.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        state.userToken = response.data.token;
        state.success = true;
      } else {
        state.userToken = null;
        state.success = false;
      }
    });

    builder.addCase(userSignUp.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for login-------
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userLogin.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        state.userToken = response.data.token;
        state.currentUser = response.data;
        state.success = true;
      } else {
        state.userToken = null;
        state.success = false;
      }
    });

    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for social signup-------
    builder.addCase(socialSignUp.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(socialSignUp.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        state.userToken = response.data.token;
        state.currentUser = response.data;
        state.success = true;
      } else {
        state.userToken = null;
        state.success = false;
      }
    });

    builder.addCase(socialSignUp.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for social Login-------
    builder.addCase(socialLogin.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(socialLogin.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        state.userToken = response.data.token;
        state.currentUser = response.data;
        state.success = true;
      } else {
        state.userToken = null;
        state.success = false;
      }
    });

    builder.addCase(socialLogin.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { userLogoutClear, handleNotificationKey } = userSlice.actions;
export default userSlice.reducer;
