import TopHeader from "../TopHeader";
import BottomHeader from "../BottomHeader";
import TrendingSection from "../../HomeModule/TrendingSection";
import Footer from "../../Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./GlobalSearch.module.css";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SublyApi from "../../../helpers/Api";
import { useNavigate, useParams } from "react-router-dom";
import {
  REQUEST_RECEIVE_STATUS,
  REQUEST_SENT_STATUS,
  REQUEST_ACTION_TYPE,
  FRIEND_STATUS,
} from "../../../utils/Constants";
import { useTranslation } from "react-i18next";
import { FaCheck, FaTimes } from "react-icons/fa";
import { STATUS_CODES } from "../../../utils/StatusCode";
import {
  fetchSearchList,
  handleSearchList,
} from "../../../store/Slices/SearchSlice";
import { Toast } from "../../../utils/Toster";
import { userLogoutClear } from "../../../store/Slices/UserSlice";

function GlobalSearch() {
  const { t } = useTranslation();
  const { search } = useParams();
  const navigate = useNavigate();
  const { searchList } = useSelector((state) => state.search);
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);

  useEffect(() => {
    const FetchSearchList = async () => {
      if (search) {
        dispatch(
          fetchSearchList({ userToken: userToken, search: search })
        ).then((responsejson) => {
          if (responsejson.status_code === STATUS_CODES.SUCCESS) {
            // setSearchList(responsejson.data.peoples);
          }
        });
      }
    };
    FetchSearchList();
  }, [search]);

  //====== function to send connection request ======
  async function connectHandler(id, index) {
    const requestData = new FormData();
    requestData.append("receiver_id", id);
    await SublyApi.sendRequestList(userToken, requestData).then((response) => {
      const responsejson = response.data;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        const list = [...searchList];
        list[index] = {
          ...list[index],
          is_request_sent: REQUEST_SENT_STATUS.REQUEST_SENT,
          request_status: REQUEST_ACTION_TYPE.REQUEST_PENDING,
        };
        dispatch(handleSearchList({ searchList: list }));
      } else if (responsejson.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      } else if (responsejson.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
        dispatch(userLogoutClear());
      } else if (responsejson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        Toast.fire({
          icon: "error",
          title: `${t("SERVER_ERR")}`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      }
    });
  }

  return (
    <>
      <div className="bg-background_color">
        <TopHeader />
        <BottomHeader search={search} />
        <div className="mx-0">
          <Container>
            <Row>
              <Col lg={9} md={12} sm={12}>
                <div className={`mb-6  bg-white ${styles.searchWrapper}`}>
                  {searchList &&
                    searchList.map((item, index) => (
                      <div
                        key={index}
                        className={`flex justify-between mb-2 ${styles.searchData}`}
                      >
                        <div
                          className="flex w-10/12 sm:w-9/12 cursor-pointer"
                          onClick={() =>
                            navigate(`/my-network/profile-detail/${item.id}`)
                          }
                        >
                          <div className={`p-2.5 ${styles.searchImg}`}>
                            <img
                              className="object-cover border-2 rounded border-border_colour_soft"
                              src={item.profile_img}
                            />
                          </div>
                          <div className={`pt-2`}>
                            <p className="font-bold text-base text-black">
                              {item.fullname}
                            </p>
                            <p className="text-sm "></p>
                            <p className="text-sm text-slate-700 font-semibold">
                              {item.location}
                            </p>
                            {item.current_designation && (
                              <p className="text-xs mb-1 text-slate-700">
                                Current: {item.current_designation}{" "}
                              </p>
                            )}
                          </div>
                        </div>

                        <div
                          className={`mt-2.5 w-2/12 sm:w-3/12 text-center ${styles.searchDataBtn}`}
                        >
                          {item.is_friend == FRIEND_STATUS.FRIEND ? (
                            <button
                              onClick={() => navigate(`/chat/chat-details`)}
                              className="text-sm font-semibold border-mid_background border-2 w-22 bg-sign_button py-2 px-3.5 rounded text-white"
                            >
                              {t("MESSAGE")}
                            </button>
                          ) : item.is_request_sent ==
                            REQUEST_SENT_STATUS.REQUEST_NOT_SENT ? (
                            item.is_request_received ==
                            REQUEST_RECEIVE_STATUS.NOT_RECEIVED ? (
                              <button
                                onClick={() => {
                                  connectHandler(item.id, index);
                                }}
                                className="text-sm font-semibold border-mid_background border-2 w-22 bg-sign_button py-2 px-3.5 rounded text-white"
                              >
                                {t("Connect")}
                              </button>
                            ) : item.request_status ==
                              REQUEST_ACTION_TYPE.REQUEST_PENDING ? (
                              <div className="flex justify-center">
                                <button className={styles.acceptBtn}>
                                  <FaCheck />
                                </button>
                                <button className={styles.declineBtn}>
                                  <FaTimes />
                                </button>
                              </div>
                            ) : item.request_status ==
                              REQUEST_ACTION_TYPE.REQUEST_ACCEPT ? (
                              <button
                                onClick={() => navigate(`/chat/chat-details`)}
                                className="text-sm font-semibold border-mid_background border-2 w-22 bg-sign_button py-2 px-3.5 rounded text-white"
                              >
                                {t("MESSAGE")}
                              </button>
                            ) : (
                              <button
                                onClick={() => {
                                  connectHandler(item.id, index);
                                }}
                                className="font-semibold text-sm border-mid_background border-2 w-22 bg-sign_button py-2 px-3.5 rounded text-white"
                              >
                                {t("Connect")}
                              </button>
                            )
                          ) : item.request_status ==
                            REQUEST_ACTION_TYPE.REQUEST_DECLINE ? (
                            <button
                              onClick={() => {
                                connectHandler(item.id, index);
                              }}
                              className="font-semibold text-sm border-mid_background border-2 w-22 bg-sign_button py-2 px-3.5 rounded text-white"
                            >
                              {t("Connect")}
                            </button>
                          ) : item.request_status ==
                            REQUEST_ACTION_TYPE.REQUEST_ACCEPT ? (
                            ""
                          ) : (
                            <button
                              className={`font-semibold text-mid_background border-mid_background border-2 text-sm w-22  cursor-default py-2 px-3.5 rounded`}
                            >
                              {t("Pending")}
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </Col>
              <Col lg={3} md={12} sm={12} className="mb-4">
                <TrendingSection isSetting={true} />
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default GlobalSearch;
