import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";

const initialState = {
  profile: {},
  profileDetail: {},
  user_fetchExperience: {},
  user_fetchEducation: {},
  isLoading: false,
  success: false,
  error: null,
};

// Thunk for user profile
export const profileData = createAsyncThunk(
  "profile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getProfileData(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user profile detail
export const profileDataDetail = createAsyncThunk(
  "profile_detail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.userProfileDetail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for fetch experience
export const fetchExperience = createAsyncThunk(
  "user_fetchExperience",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getExperience(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for fetch Education
export const fetchEducation = createAsyncThunk(
  "user_fetchEducation",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getEducation(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    userProfileClear: (state, action) => {
      state.profile = {};
      state.profileDetail = {};
      state.user_fetchExperience = {};
      state.user_fetchEducation = {};
      state.isLoading = false;
      state.userToken = null;
    },
  },
  extraReducers: (builder) => {
    // ------Builder for profile-------
    builder.addCase(profileData.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(profileData.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response?.status_code === STATUS_CODES.SUCCESS) {
        state.profile = response.data;
        state.success = true;
      } else {
        state.success = false;
      }
    });

    builder.addCase(profileData.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for profile detail-------
    builder.addCase(profileDataDetail.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(profileDataDetail.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response?.status_code === STATUS_CODES.SUCCESS) {
        state.profileDetail = response.data;
        state.success = true;
      } else {
        state.success = false;
      }
    });

    builder.addCase(profileDataDetail.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for fetch user experience-------
    builder.addCase(fetchExperience.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchExperience.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        state.profile.experience = response.data;
        state.success = true;
      } else {
        state.profile.experience = null;
        state.success = false;
      }
    });

    builder.addCase(fetchExperience.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for fetch user Education-------
    builder.addCase(fetchEducation.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchEducation.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        state.profile.education = response.data;
        state.success = true;
      } else {
        state.profile.education = null;
        state.success = false;
      }
    });

    builder.addCase(fetchEducation.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { userProfileClear } = profileSlice.actions;
export default profileSlice.reducer;
