import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";

const initialState = {
  searchList: [],
  isLoading: false,
  success: false,
  error: null,
};

export const fetchSearchList = createAsyncThunk(
  "searchList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.globalSeach(data.userToken, data.search);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const SearchSlice = createSlice({
  name: "search",
  initialState: initialState,
  reducers: {
    srachListClear: (state, action) => {
      state.searchList = [];
      state.isLoading = false;
      state.userToken = null;
    },
    handleSearchList: (state, action) => {
      state.searchList = action.payload.searchList;
    },
  },
  extraReducers: (builder) => {
    // ------Builder for request list-------

    builder.addCase(fetchSearchList.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchSearchList.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        state.searchList = response.data.peoples;
        state.success = true;
      } else {
        state.success = false;
      }
    });

    builder.addCase(fetchSearchList.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { srachListClear } = SearchSlice.actions;

export const { handleSearchList } = SearchSlice.actions;

export default SearchSlice.reducer;
