import React, { useEffect } from "react";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../Assests/Styles/Common.css";
import crossImg from "../../Assests/Images/popupCrossImg.webp";
import { RiDeleteBin6Line } from "react-icons/ri";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { blue } from "@mui/material/colors";
import { useSelector } from "react-redux";
import SublyApi from "../../helpers/Api";
import { Toast } from "../../utils/Toster";
import { useDispatch } from "react-redux";
import { fetchExperience, profileData } from "../../store/Slices/ProfileSlice";
import moment from "moment";
import dayjs from "dayjs";
import Loader from "../../utils/Loader/Loader";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";
import { STATUS_CODES } from "../../utils/StatusCode";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";

const ExperienceModal = ({ userExperience, setUserExperience }) => {
  const { t } = useTranslation();
  const { profile } = useSelector((state) => state.profile);
  const userExperienceData = profile.experience ? profile.experience : [];
  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  // const [selectedDate, setSelectedDate] = useState(null);
  const [counts, setCounts] = useState(
    userExperienceData ? userExperienceData.length : 1
  );

  useEffect(() => {
    setCounts(profile?.experience?.length || 0);
  }, [profile]);

  let reqError = "Please enter this field.";

  let resetError = {
    designation: "",
    company: "",
    company_address: "",
    start_date: "",
    end_date: "",
    skills: "",
  };

  const [errorMessageArr, setErrorMessageArr] = useState([
    {
      designation: "",
      company: "",
      company_address: "",
      skills: "",
      start_date: "",
      end_date: "",
    },
  ]);
  const [error, setError] = useState(false);
  const moment = require("moment");

  const [inputList, setInputList] = useState([
    {
      id: "",
      designation: "",
      company: "",
      company_address: "",
      skills: "",
      currently_working_here: 0,
      start_date: null,
      end_date: null,
      dataFullfill: false,
    },
  ]);

  // ----------function for logout when token expire-----------
  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  //----------function for currently working checkbox------------
  const handleCheckboxChange = (e, index) => {
    const itemList = [...inputList];
    itemList[index].currently_working_here =
      itemList[index].currently_working_here === 0 ? 1 : 0;

    inputList.map((obj, itemIndex) => {
      if (itemIndex != index) {
        itemList[itemIndex].currently_working_here = 0;
      }
    });
    setInputList(itemList);

    let errorArray = [...errorMessageArr];
    errorArray[index] = { ...errorArray[index], end_date: "" };
    setErrorMessageArr(errorArray);
    itemList[index] = { ...itemList[index], end_date: null };
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: blue[500],
      },
    },
  });

  //----------function for designation input-------------
  const handleDesignation = (e, index) => {
    const itemList = [...inputList];
    // if (e.nativeEvent.data == " ") {
    //   e.preventDefault(); // Prevent the default behavior (input of space)
    //   return;
    // }
    itemList[index].designation = e.target.value;
    setInputList(itemList);

    let arr = [...errorMessageArr];
    if (e.target.value) {
      arr.splice(index, 1, {
        ...arr[index],
        designation: "",
      });
      setErrorMessageArr(arr);
      setError(false);
    } else {
      arr.splice(index, 1, {
        ...arr[index],
        designation: reqError,
      });
      setErrorMessageArr(arr);
      setError(true);
    }
  };

  //----------function for company input-------------
  const handleCompany = (e, index) => {
    // if (e.nativeEvent.data === " ") {
    //   e.preventDefault(); // Prevent the default behavior (input of space)
    //   return;
    // }
    const itemList = [...inputList];
    itemList[index].company = e.target.value;
    setInputList(itemList);

    let arr = [...errorMessageArr];
    if (e.target.value) {
      arr.splice(index, 1, {
        ...arr[index],
        company: "",
      });
      setErrorMessageArr(arr);
      setError(false);
    } else {
      arr.splice(index, 1, {
        ...arr[index],
        company: reqError,
      });
      setErrorMessageArr(arr);
      setError(true);
    }
  };

  //----------function for address input-------------
  const handleCompanyAddress = (e, index) => {
    // if (e.nativeEvent.data === " ") {
    //   e.preventDefault(); // Prevent the default behavior (input of space)
    //   return;
    // }
    const itemList = [...inputList];
    itemList[index].company_address = e.target.value;
    setInputList(itemList);

    let arr = [...errorMessageArr];
    if (e.target.value) {
      arr.splice(index, 1, {
        ...arr[index],
        company_address: "",
      });
      setErrorMessageArr(arr);
      setError(false);
    } else {
      arr.splice(index, 1, {
        ...arr[index],
        company_address: reqError,
      });
      setErrorMessageArr(arr);
      setError(true);
    }
  };

  //----------function for start_date input-------------
  const handleStartDate = (e, index) => {
    const itemList = [...inputList];
    itemList[index].start_date = moment(e.$d).format("YYYY-MM-DD");
    setInputList(itemList);
    // setSelectedDate();
    let d1 =
      itemList[index].start_date &&
      new Date(itemList[index].start_date).getTime();
    let d2 =
      itemList[index].end_date && new Date(itemList[index].end_date).getTime();

    let arr = [...errorMessageArr];
    if (e) {
      if (d2 == d1) {
        arr.splice(index, 1, {
          ...arr[index],
          start_date: "Start date must be less then end date",
        });
        setErrorMessageArr(arr);
        setError(true);
      } else {
        arr.splice(index, 1, {
          ...arr[index],
          start_date: "",
          end_date: "",
        });
        setErrorMessageArr(arr);
        setError(false);
      }
    } else {
      arr.splice(index, 1, {
        ...arr[index],
        start_date: reqError,
      });
      setErrorMessageArr(arr);
      setError(true);
    }
  };

  //----------function for end_date input-------------
  const handleEndDate = (e, index) => {
    const itemList = [...inputList];
    itemList[index].end_date = moment(e.$d).format("YYYY-MM-DD");
    setInputList(itemList);
    // setSelectedDate();
    let d1 =
      itemList[index].start_date &&
      new Date(itemList[index].start_date).getTime();
    let d2 =
      itemList[index].end_date && new Date(itemList[index].end_date).getTime();

    let arr = [...errorMessageArr];
    if (e) {
      if (d1 == d2) {
        arr.splice(index, 1, {
          ...arr[index],
          end_date: "End date must be greater then start date",
        });
        setErrorMessageArr(arr);
        setError(true);
      } else {
        arr.splice(index, 1, {
          ...arr[index],
          end_date: "",
          start_date: "",
        });
        setErrorMessageArr(arr);
        setError(false);
      }
    } else {
      arr.splice(index, 1, {
        ...arr[index],
        end_date: reqError,
      });
      setErrorMessageArr(arr);
      setError(true);
    }
  };

  //----------function for skills input-------------
  const handleSkills = (e, index) => {
    // if (e.nativeEvent.data === " ") {
    //   e.preventDefault(); // Prevent the default behavior (input of space)
    //   return;
    // }
    const itemList = [...inputList];
    itemList[index].skills = e.target.value;
    setInputList(itemList);

    let arr = [...errorMessageArr];
    if (e.target.value) {
      arr.splice(index, 1, {
        ...arr[index],
        skills: "",
      });
      setErrorMessageArr(arr);
      setError(false);
    } else {
      arr.splice(index, 1, {
        ...arr[index],
        skills: reqError,
      });
      setErrorMessageArr(arr);
      setError(true);
    }
  };

  //----------function for check validations-------------
  const finalInput = async () => {
    const experienceList = [];
    let requiredCheck = 0;
    let arr = [];
    await inputList.map((item, index) => {
      if (
        item.currently_working_here == 1 &&
        item.designation &&
        item.company &&
        item.company_address &&
        item.start_date &&
        item.skills
      ) {
        arr.splice(index, 1, resetError);
        setErrorMessageArr(arr);
        setError(false);
      } else if (
        item.currently_working_here != 1 &&
        item.designation &&
        item.company &&
        item.company_address &&
        item.start_date &&
        item.end_date &&
        item.skills
      ) {
        arr.splice(index, 1, resetError);
        setErrorMessageArr(arr);
        setError(false);
      } else {
        requiredCheck = 1;

        if (!item.designation) {
          arr.splice(index, 1, {
            ...arr[index],
            designation: reqError,
          });
          setErrorMessageArr(arr);
        }

        if (!item.company) {
          arr.splice(index, 1, {
            ...arr[index],
            company: reqError,
          });
          setErrorMessageArr(arr);
        }

        if (!item.company_address) {
          arr.splice(index, 1, {
            ...arr[index],
            company_address: reqError,
          });
          setErrorMessageArr(arr);
        }

        if (!item.start_date) {
          arr.splice(index, 1, {
            ...arr[index],
            start_date: reqError,
          });
          setErrorMessageArr(arr);
        }

        if (!item.end_date) {
          arr.splice(index, 1, {
            ...arr[index],
            end_date: reqError,
          });
          setErrorMessageArr(arr);
        }

        if (!item.skills) {
          arr.splice(index, 1, {
            ...arr[index],
            skills: reqError,
          });
          setErrorMessageArr(arr);
        }
        setError(true);
        return false;
      }

      experienceList.push({
        id: item.id,
        designation: item.designation,
        company: item.company,
        company_address: item.company_address,
        currently_working_here: item.currently_working_here,
        start_date: item.start_date,
        end_date: item.end_date,
        skills: item.skills,
      });
    });

    if (requiredCheck == 0) {
      return experienceList;
    } else {
      return [];
    }
  };

  //----------function for call sev experience api-------------
  const onSaveExperience = async (item, index) => {
    const experienceList = await finalInput();

    if (experienceList.length > 0 && !error) {
      setIsLoader(true);
      let requestData = new FormData();
      requestData.append("experience", JSON.stringify(experienceList));
      await SublyApi.sevExperience(userToken, requestData).then(
        (responsejson) => {
          if (responsejson.status_code === 200) {
            Toast.fire({
              icon: "success",
              title: responsejson.message,
            });
            dispatch(fetchExperience(userToken));
            showExperinceData();
            dispatch(profileData(userToken));
            setUserExperience(false);
            setIsLoader(false);
            let arr = [...errorMessageArr];
            arr.splice(index, 1, resetError);
            setErrorMessageArr(arr);
            setError(false);
          } else if (
            responsejson.data.status_code === STATUS_CODES.INVALID_TOKEN
          ) {
            Toast.fire({
              icon: "error",
              title: responsejson.data.message,
            });
            logOutDataClear();
            setIsLoader(false);
          } else {
            Toast.fire({
              icon: "error",
              title: responsejson.data.message,
            });
            setIsLoader(false);
          }
        }
      );
    }
  };

  //----------function for show experience data-------------
  function showExperinceData() {
    const experienceListDisplay = [];
    if (userExperienceData.length > 0) {
      userExperienceData.length > 0 &&
        userExperienceData.map((item, index) => {
          experienceListDisplay.push({
            id: item.id,
            designation: item.designation,
            company: item.company,
            company_address: item.company_address,
            currently_working_here: item.currently_working_here,
            start_date: item.start_date,
            end_date: item.end_date,
            skills: item.skills,
            dataFullfill: true,
          });
        });
      setInputList(experienceListDisplay);
    } else {
      setInputList([
        {
          id: "",
          designation: "",
          company: "",
          company_address: "",
          skills: "",
          currently_working_here: 0,
          start_date: null,
          end_date: null,
          dataFullfill: false,
        },
      ]);
    }
  }

  useEffect(() => {
    showExperinceData();
  }, [userExperience]);

  //------------function for find error-----------------------------
  function findError(errorMessageArr) {
    for (let i = 0; i < errorMessageArr.length; i++) {
      const obj = errorMessageArr[i];
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] !== "") {
          return false;
        }
      }
    }
    return true;
  }

  //----------function for add new fields on modal-------------
  const handleAddMores = () => {
    if (
      (findError(errorMessageArr) && inputList[0].dataFullfill == true) ||
      (inputList[0].currently_working_here != 1 &&
        inputList[0].designation &&
        inputList[0].company &&
        inputList[0].company_address &&
        inputList[0].start_date &&
        inputList[0].end_date &&
        inputList[0].skills)
    ) {
      setInputList([
        {
          designation: "",
          company: "",
          company_address: "",
          skills: "",
          currently_working_here: 0,
          start_date: null,
          end_date: null,
          dataFullfill: false,
        },
        ...inputList,
      ]);
      setCounts(counts + 1);
    } else {
      onSaveExperience();
    }
  };

  //----------function for call remove experience api-------------
  const handleRemoveClicks = async (index, item) => {
    if (item.id) {
      setIsLoader(true);
      let requestData = new FormData();
      requestData.append("experience_id", item.id);
      await SublyApi.removeExperience(userToken, requestData).then(
        (responsejson) => {
          if (responsejson.status_code === 200) {
            Toast.fire({
              icon: "success",
              title: responsejson.message,
            });
            setCounts(counts - 1);
            dispatch(fetchExperience(userToken));
            setUserExperience(false);
            showExperinceData();
            setIsLoader(false);
            let arr = [...errorMessageArr];
            arr.splice(index, 1, resetError);
            setErrorMessageArr(arr);
            setError(false);
          } else if (
            responsejson.data.status_code === STATUS_CODES.INVALID_TOKEN
          ) {
            Toast.fire({
              icon: "error",
              title: responsejson.data.message,
            });
            logOutDataClear();
            setIsLoader(false);
          } else {
            Toast.fire({
              icon: "error",
              title: responsejson.data.message,
            });
            setIsLoader(false);
          }
        }
      );
    } else {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
      setCounts(counts - 1);

      let arr = [...errorMessageArr];
      arr.splice(index, 1, resetError);
      setErrorMessageArr(arr);
      setError(false);
    }
  };

  return (
    <>
      {isLoader ? <Loader /> : ""}
      <div>
        <Modal
          show={userExperience}
          onHide={() => setUserExperience(false)}
          backdrop="static"
        >
          <Modal.Header className="crossImg">
            <Modal.Title className="modalTitle">{t("EXPERIENCE")}</Modal.Title>
            <img
              className="cursor-pointer"
              src={crossImg}
              alt="cross-Image"
              onClick={(index) => {
                setUserExperience(false);
                let arr = [...errorMessageArr];
                arr.splice(index, 1, resetError);
                setErrorMessageArr(arr);
                setError(false);
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <div className="mt-2">
              <Form>
                <div className="btn">
                  <button
                    className="addMoreBtn"
                    type="button"
                    onClick={() => handleAddMores()}
                  >
                    {t("Add More")}
                  </button>
                </div>
                {inputList.map((item, index) => {
                  return (
                    <>
                      <div className="allFields" key={index}>
                        <div>
                          <Form.Group className="mb-3">
                            <Form.Label className="labelName">
                              {t("DESIGNATION")}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="h-12"
                              name={`designation${index}`}
                              value={item.designation}
                              onChange={(e) => {
                                handleDesignation(e, index);
                              }}

                            />

                            {errorMessageArr[index] &&
                              errorMessageArr[index].designation &&
                              !item.designation && (
                                <p className="text-red-600">
                                  {errorMessageArr[index].designation}
                                </p>
                              )}
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Form.Label className="labelName">
                              {t("COMPANY")}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className={`h-12`}
                              name={`company${index}`}
                              value={item.company}
                              onChange={(e) => {
                                handleCompany(e, index);
                              }}
                            />
                            <div>
                              {errorMessageArr[index] &&
                                errorMessageArr[index].company &&
                                !item.company && (
                                  <p className="text-red-600">
                                    {errorMessageArr[index].company}
                                  </p>
                                )}
                            </div>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Form.Label className="labelName">
                              {t("COMPANY_ADDRESS")}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className={`h-12`}
                              name={`company_address${index}`}
                              value={item.company_address}
                              onChange={(e) => {
                                handleCompanyAddress(e, index);
                              }}
                            />
                            <div>
                              {errorMessageArr[index] &&
                                errorMessageArr[index].company_address &&
                                !item.company_address && (
                                  <p className="text-red-600">
                                    {errorMessageArr[index].company_address}
                                  </p>
                                )}
                            </div>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <div className="startEndDate">
                              <div>
                                <Form.Label className="labelName">
                                  {t("START_DATE")}
                                </Form.Label>
                                <div className="startDate">
                                  <ThemeProvider theme={theme}>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        components={["DatePicker"]}
                                      >
                                        <DatePicker
                                          disableFuture
                                          className="custom-datepicker"
                                          format={t("DATE_FORMAT")}
                                          name={`start_date${index}`}
                                          maxDate={dayjs(
                                            moment(item.end_date).format(
                                              "YYYY/MM/DD"
                                            )
                                          )}
                                          value={dayjs(
                                            moment(item.start_date).format(
                                              "YYYY/MM/DD"
                                            )
                                          )}
                                          onChange={(e) => {
                                            handleStartDate(e, index);
                                          }}
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </ThemeProvider>
                                </div>
                                <div>
                                  {errorMessageArr[index] &&
                                    errorMessageArr[index].start_date && (
                                      <p className="text-red-600">
                                        {errorMessageArr[index].start_date}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="p-2.5"></div>
                              {item.currently_working_here == 0 ? (
                                <div>
                                  <Form.Label className="endDateLabelName">
                                    {t("END_DATE")}
                                  </Form.Label>
                                  <div className="endDate">
                                    <ThemeProvider theme={theme}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DemoContainer
                                          components={["DatePicker"]}
                                        >
                                          <DatePicker
                                            disableFuture
                                            disabled={!item.start_date}
                                            className="custom-datepicker"
                                            format={t("DATE_FORMAT")}
                                            name={`end_date${index}`}
                                            minDate={dayjs(
                                              moment(item.start_date).format(
                                                "YYYY/MM/DD"
                                              )
                                            )}
                                            value={dayjs(
                                              moment(item.end_date).format(
                                                "YYYY/MM/DD"
                                              )
                                            )}
                                            onChange={(e) => {
                                              handleEndDate(e, index);
                                            }}
                                          />
                                        </DemoContainer>
                                      </LocalizationProvider>
                                    </ThemeProvider>
                                  </div>
                                  {errorMessageArr[index] &&
                                    errorMessageArr[index].end_date &&
                                    !item.end_date && (
                                      <p className="text-red-600">
                                        {errorMessageArr[index].end_date}
                                      </p>
                                    )}
                                  {errorMessageArr[index] &&
                                    errorMessageArr[index].end_date &&
                                    item.end_date && (
                                      <p className="text-red-600">
                                        {errorMessageArr[index].end_date}
                                      </p>
                                    )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </Form.Group>

                          <Form.Check
                            name={`currently_working_here${index}`}
                            checked={item.currently_working_here === 1}
                            onChange={(e) => handleCheckboxChange(e, index)}
                            label="currently working"
                            className="text-no_color"
                          />

                          <Form.Group className="mb-3 mt-4">
                            <Form.Label className="labelName">
                              {t("SKILLS")}
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={6}
                              className="h-12"
                              name={`skills${index}`}
                              value={item.skills}
                              onChange={(e) => {
                                handleSkills(e, index);
                              }}
                            />
                            <div>
                              {errorMessageArr[index] &&
                                errorMessageArr[index].skills &&
                                !item.skills && (
                                  <p className="text-red-600">
                                    {errorMessageArr[index].skills}
                                  </p>
                                )}
                            </div>
                          </Form.Group>

                          {counts > 1 && (
                            <div className="btn">
                              <RiDeleteBin6Line className="deleteicon" />
                              <button
                                className="removeBtn"
                                type="button"
                                onClick={() => handleRemoveClicks(index, item)}
                              >
                                {t("Remove")}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
                <button
                  type="button"
                  className="saveBtn"
                  onClick={onSaveExperience}
                >
                  {t("SAVE")}
                </button>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ExperienceModal;
