import React from "react";
import Logo from "../../Assests/Images/linkeii_logo 2.webp";
import SignButton from "../../SignButton";
import { HeaderData } from "./HeaderData";
import { Navbar, Nav, Container } from "react-bootstrap";
import styles from "./Header.module.css";
import { NavLink } from "react-router-dom";
import "../../Assests/Styles/Common.css";
import { Outlet } from "react-router-dom";

const Header = () => {
  return (
    <>
      <div className={styles.main}>
        <div className={`fixed-top ${styles.fixedTop}`}>
          <Container className={styles.headerWrapper}>
            <Navbar expand="lg">
              <Navbar.Brand href="/">
                <div>
                  <img className="h-14 w-36" src={Logo} alt="Logo"></img>
                </div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <div className="2xl:px-72 xl:px-72 lgg:px-32 lg:px-1 md:px-1 sm:px-1">
                    <div className="2xl:flex xl:flex lgg:flex text-my_color2 2xl:space-x-4 xl:space-x-4 lgg:space-x-4">
                      {HeaderData.map((item) => (
                        <div key={item.id} className={styles.navSpace}>
                          {/* <Nav.Link href="javascript:;"> */}
                          <NavLink to={item.link} className={styles.navItems}>
                            {item.name}
                          </NavLink>
                          {/* </Nav.Link> */}
                        </div>
                      ))}
                    </div>
                  </div>
                </Nav>
                <SignButton />
              </Navbar.Collapse>
            </Navbar>
            <Outlet />
          </Container>
        </div>
      </div>
    </>
  );
};

export default Header;
