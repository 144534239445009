import React, { useCallback, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./HomeModule.module.css";
import emoji from "../../Assests/Images/emoji.svg";
import image from "../../Assests/Images/image.svg";
import video from "../../Assests/Images/video.svg";
import arrow from "../../Assests/Images/arrow.svg";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";
import { fetchPostMedia } from "../../store/Slices/MediaSlice";
import { Toast } from "../../utils/Toster";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import EmojiPicker from "../../commonComponent/EmojiPicker";
import $ from "jquery";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../Cropper/Cropper";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";
import mime from 'mime';
import getRandom from "../../utils/GetRandom";

function UploadSection(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { profileDetail } = useSelector((state) => state.profile);
  const { userToken } = useSelector((state) => state.user);
  const [imageSrc, setImageSrc] = useState([]);
  const [captionValue, setCaptionValue] = useState("");
  const [profileImage, setProfileImage] = useState([]);
  const [profileImageView, setProfileImageView] = useState([]);
  const [openEmoji, setOpenEmoji] = useState(false);
  const [getThumbnail, setThumbnail] = useState("");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [showHead, setShowHead] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [passThumbnail, setPassThumbnail] = useState("");

  // =======clear redux========
  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        profileImageView,
        croppedAreaPixels
      );
      imgCropper();

      var blockCrop = croppedImage.split(";");
      // Get the content type of the image
      var cropContentTypes = blockCrop[0].split(":")[1]; // In this case "image/png"
      // get the real base64 content of the file
      var blockRealData = blockCrop[1].split(",")[1];

      var cropBlobImg = await b64toBlob(blockRealData, cropContentTypes);

      let profileImageData = [...imageSrc];
      profileImageData.push(croppedImage);
      setImageSrc(profileImageData);

      let profileViewData = [...profileImage];
      profileViewData.push(cropBlobImg);
      setProfileImage(profileViewData);
      setProfileImageView("");
    } catch (e) {
      console.error(e);
    }
  }, [profileImageView, croppedAreaPixels]);

  function b64toBlob(cropData, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;
    var byteCharacters = window.atob(cropData); //decode string
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const imgCropper = (e) => {
    if (e && e.target.files && e.target.files.length == 0) {
      return false;
    } else {
      showHead ? setShowHead(false) : setShowHead(true);
    }
  };

  // ------for uploading multiple images---------
  const onFileChange = async (e) => {
    let fileReader,
      isCancel = false;
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (e.target.files && e.target.files.length > 0) {
        fileReader = new FileReader();
        fileReader.onload = async (e) => {
          const { result } = e.target;
          if (result && !isCancel) {
            setProfileImageView(result);
          }
        };
        fileReader.readAsDataURL(file);
      }
    }
  };

  // ------for uploading Video---------
  const onFileHandle = async (e) => {
    let fileReader,
      isCancel = false;
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size <= 49009500) {
        setProfileImage([file]);
        if (e.target.files && e.target.files.length > 0) {
          fileReader = new FileReader();
          fileReader.onload = async (e) => {
            const { result } = e.target;
            if (result && !isCancel) {
              await setImageSrc([result]);
            }
          };
          fileReader.readAsDataURL(file);
        }
      } else {
        Toast.fire({
          icon: "error",
          title: t("VIDEO_SIZE_ERROR"),
        });
      }
    }
  };

  // ---------------for removing images and video---------------
  async function onImageRemove(e, index, item) {
    e.preventDefault();
    let profileimages = [...imageSrc];
    profileimages.splice(index, 1);
    setImageSrc(profileimages);
    let profileImageData = [...profileImage];
    profileImageData.splice(index, 1);
    setProfileImage(profileImageData);
  }

  // ==============function for generating thumbnail from the video===========
  function getVideoThumbnail(event) {
    var file = event.target.files[0];
    var fileReader = new FileReader();
    if (file.type.match("video") && file.size <= 49009500) {
      var image = "";
      fileReader.onload = function () {
        var blob = new Blob([fileReader.result], { type: file.type });
        var url = URL.createObjectURL(blob);
        var video = document.createElement("video");
        var timeupdate = function () {
          if (snapImage()) {
            video.removeEventListener("timeupdate", timeupdate);
            video.pause();
          }
        };
        video.addEventListener("loadeddata", function () {
          if (snapImage()) {
            video.removeEventListener("timeupdate", timeupdate);
          }
        });
        var snapImage = function () {
          var canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas
            .getContext("2d")
            .drawImage(video, 0, 0, canvas.width, canvas.height);
          image = canvas.toDataURL();
          var success = image.length > 100000;
          if (success) {
            setThumbnail(image);
            const base64String = image; // Base64 string
            const binaryString = atob(base64String.split(",")[1]); // Binary data string
            const blob = new Blob([binaryString], { type: "image/png" });
            setPassThumbnail(blob);
            URL.revokeObjectURL(url);
          }
          return success;
        };
        video.addEventListener("timeupdate", timeupdate);
        video.preload = "metadata";
        video.src = url;
        // Load video in Safari / IE11
        video.muted = true;
        video.playsInline = true;
        video.play();
      };
      fileReader.readAsArrayBuffer(file);
    }
  }

  // function for create post
  async function postCreate() {
    const requestData = new FormData();
    requestData.append("caption", captionValue);
    passThumbnail && requestData.append("postThumbFile", passThumbnail);
    // if (profileImage && profileImage.length > 0) {
    //   profileImage.forEach((item, index) => {
    //     requestData.append("postFile", item);
    //   });
    // }
    if (profileImage && profileImage.length > 0) {
      profileImage.forEach((item, index) => {
        const formData = new FormData();
        formData.append('file', item, getRandom() + '.' + mime.getExtension(item.type));
        requestData.append("postFile", formData.get('file'));
      });
    }
    await SublyApi.createMediaPost(userToken, requestData).then(
      async (responsejson) => {
        if (responsejson.status_code === STATUS_CODES.SUCCESS) {
          Toast.fire({
            icon: "success",
            title: responsejson.message,
          });
          props.setModalShow(false);
          const requestData = new FormData();
          requestData.append("page_no", 1);
          await dispatch(
            fetchPostMedia({ userToken: userToken, requestData: requestData })
          );
          // =====here when come to screen or refresh back to top====
          $("document").ready(function () {
            $(window).scrollTop(0);
          });
          setImageSrc([]);
          setProfileImage([]);
          setCaptionValue("");
        } else if (
          responsejson.data.status_code === STATUS_CODES.INVALID_TOKEN
        ) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          logOutDataClear();
        } else {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
        }
      }
    );
  }

  // ====function to hide box on click outside====
  $(document).mouseup(function (e) {
    if ($(e.target).closest(".emojiUploadClass").length === 0) {
      setOpenEmoji(false);
    }
  });

  return (
    <section>
      <Modal
        show={props.modalShow}
        onHide={() => {
          props.setModalShow(false);
          setImageSrc([]);
          setProfileImage([]);
          setCaptionValue("");
          setOpenEmoji(false);
          setThumbnail("");
          setPassThumbnail("");
        }}
        className="modalCloseBtn"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles.modalTitle}>
            {t("CREATE_POST")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.textAreaClass}>
          <div
            className={`flex items-center gap-2 py-3 ${styles.commentUserIcon}`}
          >
            <img src={profileDetail.profile_img} alt="profile" />
            <h2>{profileDetail.fullname}</h2>
          </div>
          <textarea
            placeholder={t("WHATS_YOUR_MIND")}
            className="py-2 w-full"
            value={captionValue}
            onChange={(e) => setCaptionValue(e.target.value)}
          />
          <div
            className={`flex items-center gap-2 pt-12 overflow-x-auto ${styles.demoClass}`}
          >
            {imageSrc.length > 0
              ? imageSrc.map((image, index) =>
                profileImage[0].type == "video/mp4" ? (
                  <div className={styles.thumbnailImg} key={index}>
                    <img src={getThumbnail} />
                  </div>
                ) : (
                  <div
                    className={
                      profileImage.length > 4
                        ? styles.imgResize
                        : styles.uploadingImg
                    }
                    key={index}
                  >
                    <img
                      src={image}
                      alt={image}
                      className="w-full h-full object-cover"
                    />
                    <Icon
                      icon="zondicons:close-solid"
                      color="red"
                      width="15"
                      height="15"
                      className={styles.closeIcon}
                      onClick={(e) => onImageRemove(e, index, image)}
                    />
                  </div>
                )
              )
              : ""}
          </div>
          <div className="flex items-center justify-between">
            <div
              className={`flex items-center gap-3 mr-2.5 pt-7 pb-6 ${styles.uploadIcons}`}
            >
              <input
                type="file"
                accept="image/*"
                id="imgUpdate"
                style={{
                  display: "none",
                }}
                onClick={(e) => {
                  e.target.value = null;
                }}
                onChange={(e) => {
                  imageSrc.length < 5
                    ? onFileChange(e)
                    : Toast.fire({
                      icon: "error",
                      title: t("TMG_UPLOAD_ERROR"),
                    });
                }}
                onInputCapture={(e) => {
                  if (imageSrc.length < 5) {
                    imgCropper(e);
                  }
                }}
              />
              <input
                type="file"
                accept=".mp4"
                name="thumbnail"
                id="videoUpdate"
                style={{
                  display: "none",
                }}
                onClick={(e) => {
                  e.target.value = null;
                }}
                onChange={(e) => {
                  onFileHandle(e);
                  getVideoThumbnail(e);
                }}
              />
              <label for="imgUpdate">
                <img
                  src={image}
                  alt="image"
                  className="w-7 h-7 cursor-pointer"
                  onClick={() => {
                    if (
                      profileImage[0] &&
                      profileImage[0].type == "video/mp4"
                    ) {
                      setImageSrc([]);
                      setProfileImage([]);
                    }
                  }}
                />
              </label>
              <label for="videoUpdate">
                <img
                  src={video}
                  alt="video"
                  className="w-7 h-7 cursor-pointer"
                />
              </label>
              <label
                className={`${openEmoji
                  ? "relative cursor-pointer emojiUploadClass w-10"
                  : "relative cursor-pointer w-10"
                  }`}
              >
                <img
                  src={emoji}
                  alt="emoji"
                  className="w-7 h-7"
                  onClick={() => {
                    openEmoji ? setOpenEmoji(false) : setOpenEmoji(true);
                  }}
                />
                {openEmoji ? (
                  <EmojiPicker
                    setCaptionValue={setCaptionValue}
                    captionValue={captionValue}
                    setOpenEmoji={setOpenEmoji}
                    leftSide={true}
                    emojiClass={"manageRespoRight"}
                  />
                ) : (
                  ""
                )}
              </label>
            </div>
            <button
              className={`mt-0 bg-mid_background text-white font-medium w-32 h-12 text-lg ${styles.PostBtn}`}
              onClick={() => postCreate()}
              disabled={captionValue || profileImage.length > 0 ? false : true}
            >
              {t("POST_BTN")}
              <img src={arrow} />
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showHead}
        onHide={() => {
          imgCropper();
          setProfileImageView("");
          setCrop({ x: 0, y: 0 });
          setZoom(1);
          setCroppedAreaPixels(null);
        }}
        backdrop="static"
        className="modalCloseBtn"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("IMANGE_CROP")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="manageCropper">
          <Cropper
            image={profileImageView}
            crop={crop}
            zoom={zoom}
            aspect={4 / 3}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className={`bg-mid_background text-white font-medium text-lg ${styles.CropBtn}`}
            onClick={showCroppedImage}
          >
            {t("CROP")}
          </Button>
          <Button
            variant="secondary"
            className={`text-white font-medium text-lg ${styles.closeBtn}`}
            onClick={imgCropper}
          >
            {t("CLOSE")}
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}

export default UploadSection;
