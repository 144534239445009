import React, { useEffect, useState } from "react";
import "../../../Assests/Styles/Common.css";
import styles from "../MyNetwork.module.css";
import TopHeader from "../../UserHeaders/TopHeader";
import Footer from "../../Footer/Footer";
import profileBackgroundImg from "../../../Assests/Images/profilebackground.webp";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Modal } from "react-bootstrap";
import UserProfile from "../../../Assests/Images/user-profile.webp";
import AboutProfile from "./AboutProfile/AboutProfile";
import { useSelector, useDispatch } from "react-redux";
import { FaArrowLeft, FaUserMinus, FaShareAlt } from "react-icons/fa";
import { FaEllipsisVertical, FaCircleMinus, FaFlag } from "react-icons/fa6";
import ProfileExperience from "./ProfileExperience/ProfileExperience";
import ProfileSkills from "./ProfileSkills/ProfileSkills";
import { useNavigate, useParams } from "react-router";
import SublyApi from "../../../helpers/Api";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { userLogoutClear } from "../../../store/Slices/UserSlice";
import { userProfileClear } from "../../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../../store/Slices/NetworkSlice";
import { Toast } from "../../../utils/Toster";
import Loader from "../../../utils/Loader/Loader";
import Dropdown from "react-bootstrap/Dropdown";
import ShareUser from "./ShareUser";
import ProfileEducation from "./ProfileEducation/ProfileEducation";
import {
  FRIEND_STATUS,
  IS_PRIVATE_CHAT,
  REQUEST_RECEIVE_STATUS,
  REQUEST_SENT_STATUS,
  REQUEST_ACTION_TYPE,
} from "../../../utils/Constants";
import FollowersModal from "../NetworkProfile/Followers/FollowersModal";

const NetworkProfileDetail = () => {
  const otherUser = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();
  const { t } = useTranslation();
  const { userToken, currentUser } = useSelector((state) => state.user);
  const [otherUserInfo, setOtherUserInfo] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [isFriend, setIsFriend] = useState(false);
  const [coverPreview, setCoverPreview] = useState(profileBackgroundImg);
  const [openReport, setOpenReport] = useState(false);
  const [reportValue, setReportValue] = useState("");
  const [openShare, setOpenShare] = useState(false);
  const [userFollowers, setUserFollowers] = useState(false);

  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  async function fetchProfileDetail() {
    const requestData = new FormData();
    requestData.append("other_user_id", otherUser.id);
    setIsLoader(true);
    await SublyApi.otherProfileDetail(userToken, requestData).then(
      (responsejson) => {
        if (responsejson.status_code === 200) {
          //  console.log("otherProfileDetail responsejson", responsejson);
          setOtherUserInfo(responsejson.data);
          setIsLoader(false);
        } else if (
          responsejson.data.status_code === STATUS_CODES.INVALID_TOKEN
        ) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          logOutDataClear();
        } else {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          setIsLoader(false);
        }
      }
    );
  }

  useEffect(() => {
    fetchProfileDetail();
  }, [isSend, isFriend, otherUser.id]);

  //====== function to send connection request ======

  async function connectHandler() {
    const requestData = new FormData();
    requestData.append("receiver_id", otherUser.id);
    await SublyApi.sendRequestList(userToken, requestData).then((response) => {
      const responsejson = response.data;
      //  console.log("sendRequestList" ,responsejson);
      if (response.status_code === STATUS_CODES.SUCCESS) {
        setIsSend(!isSend);
        // Toast.fire({
        //     icon: "success",
        //     title: response.message,
        // });
      } else if (responsejson.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      } else if (responsejson.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
        dispatch(userLogoutClear());
        // dispatch(userNetworkClear());
      } else if (responsejson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        Toast.fire({
          icon: "error",
          title: `${t("SERVER_ERR")}`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      }
    });
  }

  //====== function to send follow request ======

  async function followHandler() {
    const requestData = new FormData();
    requestData.append("follow_to", otherUser.id);
    await SublyApi.followUser(userToken, requestData).then((response) => {
      const responsejson = response.data;
      // console.log(responsejson);
      if (response.status_code === STATUS_CODES.SUCCESS) {
        setIsSend(!isSend);
        // Toast.fire({
        //     icon: "success",
        //     title: response.message,
        // });
      } else if (responsejson.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      } else if (responsejson.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
        dispatch(userLogoutClear());
        // dispatch(userNetworkClear());
      } else if (responsejson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        Toast.fire({
          icon: "error",
          title: `${t("SERVER_ERR")}`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      }
    });
  }

  //====== function to disconnect user======

  async function disconnectHandler() {
    const requestData = new FormData();
    requestData.append("otherUserId", otherUser.id);
    await SublyApi.disconnectUser(userToken, requestData).then((response) => {
      const responsejson = response.data;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        setIsFriend(!isFriend);
        // Toast.fire({
        //     icon: "success",
        //     title: response.message,
        // });
      } else if (responsejson.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      } else if (responsejson.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
        dispatch(userLogoutClear());
        // dispatch(userNetworkClear());
      } else if (responsejson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        Toast.fire({
          icon: "error",
          title: `${t("SERVER_ERR")}`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      }
    });
  }

  //====== function to unfollow user======

  async function unfollowHandler() {
    const requestData = new FormData();
    requestData.append("follow_to", otherUser.id);
    await SublyApi.unfollowUser(userToken, requestData).then((response) => {
      const responsejson = response.data;
      // console.log(responsejson);
      if (response.status_code === STATUS_CODES.SUCCESS) {
        setIsFriend(!isFriend);
        // Toast.fire({
        //     icon: "success",
        //     title: response.message,
        // });
      } else if (responsejson.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      } else if (responsejson.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
        dispatch(userLogoutClear());
        // dispatch(userNetworkClear());
      } else if (responsejson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        Toast.fire({
          icon: "error",
          title: `${t("SERVER_ERR")}`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      }
    });
  }

  // ===Calling API for report user ===
  async function reportUserHandle() {
    setIsLoader(true);
    const requestData = new FormData();
    requestData.append("user_id", otherUser.id);
    requestData.append("report_comment", reportValue);
    await SublyApi.reportUser(userToken, requestData).then(async (response) => {
      if (response.status_code === STATUS_CODES.SUCCESS) {
        setReportValue("");
        setOpenReport(false);
        Toast.fire({
          icon: "success",
          title: response.message,
        });
        setIsLoader(false);
      } else if (response.data.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        setIsLoader(false);
      } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        logOutDataClear();
        setIsLoader(false);
      } else {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        setIsLoader(false);
      }
    });
  }

  // function for handle request
  const RequestHandler = async (isAccepted) => {
    const requestData = new FormData();
    requestData.append("request_action", isAccepted);
    requestData.append("request_by", otherUserInfo.request_by);
    requestData.append("request_id", otherUserInfo.request_id);
    await SublyApi.requestConnectionAction(userToken, requestData).then(
      (responsjson) => {
        if (responsjson.status_code === STATUS_CODES.SUCCESS) {
          setIsSend(!isSend);
        } else if (responsjson.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: responsjson.message,
          });
        } else if (
          responsjson.data.status_code === STATUS_CODES.INVALID_TOKEN
        ) {
          Toast.fire({
            icon: "error",
            title: responsjson.data.message,
          });
          dispatch(userLogoutClear());
        } else if (responsjson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
          Toast.fire({
            icon: "error",
            title: `${t("SERVER_ERR")}`,
          });
        } else {
          Toast.fire({
            icon: "error",
            title: responsjson.message,
          });
        }
      }
    );
  };

  return (
    <>
      {isLoader ? <Loader /> : ""}
      <div className="profilDetailWrapper">
        <TopHeader />
        <div
          className={`pt-4 ${styles.profileCoverImg}`}
          style={{
            backgroundImage: `url(${
              otherUserInfo.cover_img ? otherUserInfo.cover_img : coverPreview
            })`,
            backgroundSize: "cover",
            width: "100%",
            height: "350px",
          }}
        >
          <Container>
            <div
              className={`${styles.backAndAction} flex justify-between items-center`}
            >
              <div
                onClick={() => {
                  navigate(-1);
                }}
                className={`${styles.backBtnWrapper} cursor-pointer flex justify-between items-center`}
              >
                <FaArrowLeft className="text-xl text-white" />
                <span className="text-white text-base font-bold ml-2.5">
                  {t("BACK")}
                </span>
              </div>
              <div
                className={`${styles.actionBtn} userActionBtn flex justify-between`}
              >
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <FaEllipsisVertical className="text-xl text-white" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {otherUserInfo.is_following == 1 && (
                      <Dropdown.Item as="button" onClick={unfollowHandler}>
                        {" "}
                        <FaCircleMinus />
                        {t("UNFOLLOW")}{" "}
                      </Dropdown.Item>
                    )}

                    {otherUserInfo.is_friend == 1 && (
                      <Dropdown.Item as="button" onClick={disconnectHandler}>
                        <FaUserMinus />
                        {t("DISCONNECT")}{" "}
                      </Dropdown.Item>
                    )}

                    <Dropdown.Item
                      as="button"
                      onClick={() => setOpenShare(true)}
                    >
                      <FaShareAlt />
                      {t("SHARE")}{" "}
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() => setOpenReport(true)}
                    >
                      <FaFlag />
                      {t("REPORT")}{" "}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Container>
        </div>

        {/* User Information part */}
        <div>
          <div className={`bg-bg_colour ${styles.profileBasicDetailWrapper}`}>
            <Container>
              {/* <div className="flex justify-between"> */}
              <Row>
                <Col xl={3} lg={4} md={3} sm={4} xs={12}>
                  <div className={styles.userProfileImage}>
                    <img
                      className={styles.profileImg}
                      src={
                        otherUserInfo.profile_img
                          ? otherUserInfo.profile_img
                          : UserProfile
                      }
                      alt="user-profile"
                    />
                    <div className={styles.connectionDetails}>
                      <div>
                        <p className="flex justify-center	font-semibold	text-xl text-mid_background mt-1 lg:text-lg md:text-lg sm:text-lg">
                          {otherUserInfo.connection_count}
                        </p>
                        <p className="font-medium	text-base mt-1 lg:text-sm md:text-sm sm:text-sm">
                          {t("Connections")}
                        </p>
                      </div>
                      <span className="betweenLine"></span>
                      <div
                        onClick={() => setUserFollowers(true)}
                        className="cursor-pointer w-fit"
                      >
                        <p className="flex justify-center	font-semibold	text-xl text-mid_background mt-1 lg:text-lg md:text-lg sm:text-lg">
                          {otherUserInfo.follower_count}
                        </p>
                        <p className="font-medium	text-base mt-1 lg:text-sm md:text-sm sm:text-sm">
                          {t("FOLLOWERS")}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  xl={9}
                  lg={8}
                  md={9}
                  sm={8}
                  xs={12}
                  className={`${styles.infoWrapper} 2xl:flex xl:flex lgg:flex lg:flex md:flex content-between items-center sm:mt-44`}
                >
                  <div
                    className={`${styles.detailText} my-4 ml-5 sm:ml-0 w-full sm:mb-2.5 sm:mt-4 relative`}
                  >
                    <p
                      className={`font-bold text-4xl text-mid_background	${styles.userName}`}
                    >
                      {otherUserInfo.fullname ? otherUserInfo.fullname : "NA"}
                    </p>

                    <p
                      className={`font-medium text-xl text-my_color1	${styles.userDesigination}`}
                    >
                      {otherUserInfo.current_designation
                        ? otherUserInfo.current_designation
                        : ""}
                      {otherUserInfo.current_company_name && " - "}
                      {otherUserInfo.current_company_name
                        ? otherUserInfo.current_company_name
                        : ""}
                    </p>

                    <p
                      className={`font-normal text-lg text-my_color2	${styles.userAddress}`}
                    >
                      {otherUserInfo.location ? otherUserInfo.location : ""}
                    </p>
                    {otherUserInfo.is_request_received ==
                      REQUEST_RECEIVE_STATUS.RECEIVED &&
                      otherUserInfo.request_status ==
                        REQUEST_ACTION_TYPE.REQUEST_PENDING && (
                        <div className={styles.requestProfileBtn}>
                          <button
                            className={styles.acceptBtn}
                            onClick={() => {
                              RequestHandler(
                                REQUEST_ACTION_TYPE.REQUEST_ACCEPT
                              );
                            }}
                          >
                            {t("Accept")}
                          </button>
                          <button
                            className={styles.declineBtn}
                            onClick={() => {
                              RequestHandler(
                                REQUEST_ACTION_TYPE.REQUEST_DECLINE
                              );
                            }}
                          >
                            {t("Decline")}
                          </button>
                        </div>
                      )}
                  </div>
                  <div className={styles.actionBtn}>
                    {otherUserInfo.is_friend == FRIEND_STATUS.FRIEND ? (
                      <button
                        onClick={() =>
                          navigate(`/chat/chat-details`, {
                            state: {
                              room_id: "",
                              refrence_id: otherUser.id,
                              room_type: 1,
                              chatDetails: otherUserInfo,
                              is_private_chat:
                                IS_PRIVATE_CHAT.PRIVATE_CONNECTION,
                            },
                          })
                        }
                        className={` bg-mid_background text-white font-medium w-32 md:w-24 h-12 md:h-10 text-lg sm:w-24 sm:h-10 sm:text-base ${styles.connectBtn}`}
                      >
                        {t("MESSAGE")}
                      </button>
                    ) : otherUserInfo.is_request_sent ==
                      REQUEST_SENT_STATUS.REQUEST_NOT_SENT ? (
                      otherUserInfo.is_request_received ==
                      REQUEST_RECEIVE_STATUS.NOT_RECEIVED ? (
                        <button
                          onClick={connectHandler}
                          className={` bg-mid_background text-white font-medium w-32 md:w-24 h-12 md:h-10 text-lg md:text-base sm:w-24 sm:h-10 sm:text-base ${styles.connectBtn}`}
                        >
                          {t("Connect")}
                        </button>
                      ) : otherUserInfo.request_status ==
                          REQUEST_ACTION_TYPE.REQUEST_PENDING ||
                        otherUserInfo.request_status ==
                          REQUEST_ACTION_TYPE.REQUEST_ACCEPT ? (
                        ""
                      ) : (
                        <button
                          onClick={connectHandler}
                          className={` bg-mid_background text-white font-medium w-32 md:w-24 h-12 md:h-10 text-lg md:text-base sm:w-24 sm:h-10 sm:text-base ${styles.connectBtn}`}
                        >
                          {t("Connect")}
                        </button>
                      )
                    ) : otherUserInfo.request_status ==
                      REQUEST_ACTION_TYPE.REQUEST_DECLINE ? (
                      <button
                        onClick={connectHandler}
                        className={` bg-mid_background text-white font-medium w-32 md:w-24 h-12 md:h-10 text-lg md:text-base sm:w-24 sm:h-10 sm:text-base ${styles.connectBtn}`}
                      >
                        {t("Connect")}
                      </button>
                    ) : otherUserInfo.request_status ==
                      REQUEST_ACTION_TYPE.REQUEST_ACCEPT ? (
                      ""
                    ) : (
                      <button
                        className={`text-mid_background font-medium w-32 md:w-24 h-12 md:h-10 text-lg md:text-base sm:w-24 sm:h-10 sm:text-base ${styles.connectBtn} ${styles.pendingBtn}`}
                      >
                        {t("Pending")}
                      </button>
                    )}
                    {otherUserInfo.is_friend != 1 ? (
                      otherUserInfo.is_following == 0 ? (
                        <button
                          onClick={followHandler}
                          className={`mt-3 bg-white  font-medium w-32 md:w-24 h-12 md:h-10 text-lg md:text-base sm:w-24 sm:h-10 sm:text-base ${styles.followBtn}`}
                        >
                          {t("FOLLOW")}
                        </button>
                      ) : (
                        <button
                          className={`mt-3 bg-white  font-medium w-32 md:w-24  h-12 md:h-10 text-lg md:text-base sm:w-24 sm:h-10 sm:text-base ${styles.followBtn}`}
                        >
                          {t("FOLLOWING")}
                        </button>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
              {/* </div> */}
            </Container>
          </div>
        </div>

        {/* About me section */}
        <div className="mt-28 lg:mt-16 md:mt-14 sm:mt-8 ">
          <AboutProfile aboutData={otherUserInfo.about_me} />
        </div>

        {/* skills section */}
        <div className="mt-8">
          <ProfileSkills skillData={otherUserInfo.skills} />
        </div>

        {/* Experience section */}
        <div className="mt-8">
          <ProfileExperience
            experienceData={otherUserInfo.experience}
            educationData={otherUserInfo.education}
          />
        </div>

        {/* Education section */}
        <div className="mt-8">
          <ProfileEducation educationData={otherUserInfo.education} />
        </div>

        {/* Footer section */}
        <div className="mt-8">
          <Footer />
        </div>
        <div>
          <Modal
            show={openReport}
            onHide={() => {
              setOpenReport(false);
              setReportValue("");
            }}
            className="modalCloseBtn"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title className={styles.modalTitle}>
                {t("REPORT_USER")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.textAreaClass}>
              <div>
                <textarea
                  placeholder={t("PLACEHOLDER_TEXT")}
                  className="py-2 w-full"
                  value={reportValue}
                  onChange={(e) => {
                    setReportValue(e.target.value);
                  }}
                />
                <div className="flex justify-end">
                  <button
                    className={`mt-0 bg-mid_background text-white font-medium w-32 h-12 text-lg ${styles.PostBtn}`}
                    onClick={() => reportUserHandle()}
                    disabled={reportValue ? false : true}
                  >
                    {t("REPORT")}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <ShareUser
          openShare={openShare}
          setOpenShare={setOpenShare}
          userId={otherUser.id}
        />
      </div>
      <FollowersModal
        userFollowers={userFollowers}
        setUserFollowers={setUserFollowers}
      />
    </>
  );
};

export default NetworkProfileDetail;
