import React from "react";
import styles from "./ChatModule.module.css";
import addGroup from "../../Assests/Images/add-box.svg";
import { useTranslation } from "react-i18next";
import CreateGroupModal from "./CreateGroupModal";

function CreateGroup({
  setOpenGroup,
  openGroup,
  setChatState,
  isFromChatList,
}) {
  const { t } = useTranslation();
  return (
    <>
      <section
        className={`cursor-pointer ${styles.chatListSection}`}
        onClick={() => {
          setOpenGroup(true);
        }}
      >
        <div className={`${styles.createGroupBox} flex gap-4 items-center`}>
          <div>
            <img src={addGroup} alt="addGroup" />
          </div>
          <p>{t("CREATE_GROUP")}</p>
        </div>
      </section>
      <CreateGroupModal
        setChatState={setChatState}
        openGroup={openGroup}
        setOpenGroup={setOpenGroup}
        isFromChatList={isFromChatList}
      />
    </>
  );
}

export default CreateGroup;
