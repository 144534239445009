import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import styles from "./Setting.module.css";
import { useTranslation } from "react-i18next";
import Loader from "../../utils/Loader/Loader";
import SublyApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";
import { Toast } from "../../utils/Toster";
import { STATUS_CODES } from "../../utils/StatusCode";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

function PasswordChange({ isLoader, openModal, setOpenModal, setIsLoader }) {
  const { t } = useTranslation();
  const { userToken } = useSelector((state) => state.user);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [errorNew, setErrorNew] = useState("");
  const [errorMatch, setErrorMatch] = useState("");
  const dispatch = useDispatch();
  const [passwordShow, setPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  const handleKeyUp = (e) => {
    sanitizeHtmlTags().validate(e.target.value);
  };

  // =======clear redux========
  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  // ===========Here calling API for change password============
  async function chanageHandle() {
    if (password !== newPassword) {
      setIsLoader(true);
      const requestData = new FormData();
      requestData.append("current_password", password);
      requestData.append("confirm_password", confirmPassword);
      requestData.append("password", newPassword);
      await SublyApi.changePassword(userToken, requestData).then((response) => {
        if (response.status_code === STATUS_CODES.SUCCESS) {
          setIsLoader(false);
          setError("");
          setPassword("");
          setErrorMatch("");
          setErrorNew("");
          setNewPassword("");
          setConfirmPassword("");
          setOpenModal(false);
          Toast.fire({
            icon: "success",
            title: response.message,
          });
        } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: response.message,
          });
          setIsLoader(false);
        } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
          Toast.fire({
            icon: "error",
            title: response.data.message,
          });
          setIsLoader(false);
          logOutDataClear();
        } else if (response.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
          setIsLoader(false);
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.message,
          });
          setIsLoader(false);
        }
      });
    } else {
      setErrorMatch(`${t("SIMILAR_PASSWORD")}`)
    }
  }

  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <Modal
        show={openModal}
        onHide={() => {
          setOpenModal(false);
          setError("");
          setPassword("");
          setErrorMatch("");
          setErrorNew("");
          setNewPassword("");
          setConfirmPassword("");
        }}
        className="modalCloseBtn"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles.modalTitle}>
            {t("CHANGE_PASSWORD")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={`${styles.textAreaClass} ${styles.classForLabel}`}
        >
          <div>
            <div className="flex flex-col mb-6 setIconPassword">
              <label>{t("CURRENT_PASSWORD")}</label>
              <input
                type={passwordShow ? "text" : "password"}
                placeholder={t("CURRENT_PASSWORD")}
                onKeyDown={(e) => {
                  if (e.keyCode == 32 && !e.target.value.length) {
                    e.preventDefault();
                  }
                }}
                onKeyUp={handleKeyUp}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (/<(\/)?[a-z][\s\S]*>/i.test(e.target.value)) {
                    setError("HTML tags are not allowed.");
                  } else {
                    // Value is valid, proceed with your logic
                    setError("");
                    if (
                      e.target.value &&
                      !e.target.value.match(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                      )
                    ) {
                      setError(t("PASSWORD_MUST_CONTAIN"));
                    } else {
                      setError("");
                    }
                  }
                }}

              />
              <span className="text-sm text-red-600">{error}</span>
              <div className="passwordicons">
                {passwordShow ? (
                  <FaEye
                    className="icon"
                    onClick={() => setPasswordShow(!passwordShow)}
                  />
                ) : (
                  <FaEyeSlash
                    className="icon"
                    onClick={() => setPasswordShow(!passwordShow)}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col mb-6 setIconPassword">
              <label>{t("NEW_PASSWORD")}</label>
              <input
                type={newPasswordShow ? "text" : "password"}
                placeholder={t("NEW_PASSWORD")}
                onKeyDown={(e) => {
                  if (e.keyCode == 32 && !e.target.value.length) {
                    e.preventDefault();
                  }
                }}
                onKeyUp={handleKeyUp}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  if (/<(\/)?[a-z][\s\S]*>/i.test(e.target.value)) {
                    setErrorNew("HTML tags are not allowed.");
                  } else {
                    // Value is valid, proceed with your logic
                    setErrorNew("");
                    if (
                      e.target.value &&
                      !e.target.value.match(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                      )
                    ) {
                      setErrorNew(t("PASSWORD_MUST_CONTAIN"));
                    } else {
                      setErrorNew("");
                    }
                  }
                }}
              />
              <span className="text-sm text-red-600">{errorNew}</span>
              <div className="passwordicons">
                {newPasswordShow ? (
                  <FaEye
                    className="icon"
                    onClick={() => setNewPasswordShow(!newPasswordShow)}
                  />
                ) : (
                  <FaEyeSlash
                    className="icon"
                    onClick={() => setNewPasswordShow(!newPasswordShow)}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col mb-6 setIconPassword">
              <label>{t("CONFIRM_PASSWORD")}</label>
              <input
                type={confirmPasswordShow ? "text" : "password"}
                placeholder={t("CONFIRM_PASSWORD")}
                onKeyDown={(e) => {
                  if (e.keyCode == 32 && !e.target.value.length) {
                    e.preventDefault();
                  }
                }}
                onKeyUp={handleKeyUp}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  if (/<(\/)?[a-z][\s\S]*>/i.test(e.target.value)) {
                    setErrorMatch("HTML tags are not allowed.");
                  } else {
                    // Value is valid, proceed with your logic
                    setErrorMatch("");
                    if (!(e.target.value == newPassword)) {
                      setErrorMatch(t("CONFIRM_PASSWORD_NOT_MATCH"));
                    } else {
                      setErrorMatch("");
                    }
                  }
                }
                }
              />
              <span className="text-sm text-red-600">{errorMatch}</span>
              <div className="passwordicons">
                {confirmPasswordShow ? (
                  <FaEye className="icon" onClick={() => setConfirmPasswordShow(!confirmPasswordShow)} />
                ) : (
                  <FaEyeSlash
                    className="icon"
                    onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                  />
                )}
              </div>
            </div>
            <div className={styles.submitBtn}>
              <button
                disabled={
                  password &&
                    newPassword &&
                    confirmPassword &&
                    !error &&
                    !errorNew &&
                    !errorMatch
                    ? false
                    : true
                }
                className={`bg-mid_background`}
                onClick={() => {
                  chanageHandle();
                }}
              >
                {t("CHANGE_PASSWORD")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default PasswordChange;
