import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";

const initialState = {
  postMedia: {},
  isLoading: false,
  success: false,
  error: null,
  postMediaList: {},
};

// Thunk for user sign-up
export const fetchPostMedia = createAsyncThunk(
  "fetchPostMedia",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getPostMedia(
        data.userToken,
        data.requestData
      );
      return { response: response, loadValue: data.loadValue };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const postMediaSlice = createSlice({
  name: "postMedia",
  initialState,
  reducers: {
    userMediaClear: (state, action) => {
      state.postMedia = {};
      state.isLoading = false;
      state.userToken = null;
    },
    handleKeyValues: (state, action) => {
      state.postMediaList = action.payload.postMediaList;
    },
  },
  extraReducers: (builder) => {
    // ------Builder for login-------
    builder.addCase(fetchPostMedia.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchPostMedia.fulfilled, (state, action) => {
      const response = action.payload.response;
      state.isLoading = false;
      if (response?.status_code === STATUS_CODES.SUCCESS) {
        if (action.payload.loadValue == true) {
          state.postMediaList = state.postMediaList
            .concat(response.data.postData)
            .map((item) => ({ ...item, seeMore: 0 }));
        } else {
          state.postMediaList = response.data.postData.map((item) => ({
            ...item,
            seeMore: 0,
          }));
        }
        state.postMedia = response.data;
        state.success = true;
      } else {
        state.success = false;
      }
    });

    builder.addCase(fetchPostMedia.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { handleKeyValues } = postMediaSlice.actions;
export const { userMediaClear } = postMediaSlice.actions;
export default postMediaSlice.reducer;
