import React from "react";
import { Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./ProfileExperience.module.css";
import moment from "moment";

const ProfileExperience = ({ experienceData }) => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <div className={`bg-bg_colour ${styles.profileExperience}`}>
          <Row>
            <div className={styles.ExperienceMain}>
              <div className={`flex ${styles.experience}`}>
                <div>
                  <p
                    className={`text-solid_black font-semibold ${styles.experienceHeading}`}
                  >
                    {t("EXPERIENCE")}
                  </p>
                </div>
              </div>
              {experienceData && experienceData.length > 0 ? (
                experienceData.map((item, index) => (
                  <>
                    <div key={index}>
                      <div className="font-medium text-lg text-my_color1 mt-3">
                        <p className="text-solid_black font-semibold">
                          {item.designation}
                        </p>{" "}
                        <p className="text-sm"> {item.company} </p>
                        {item.start_date && item.end_date ? (
                          <span className="text-sm">
                            {moment(item.start_date).format("MMM YYYY")}
                            {" - "}
                            {moment(item.end_date).format("MMM YYYY")}
                          </span>
                        ) : (
                          <span className="text-sm">
                            {moment(item.start_date).format("MMM YYYY")}
                            {" - Current"}
                          </span>
                        )}
                      </div>
                      <p className="font-normal	text-sm	text-my_color2">
                        {item.company_address}
                      </p>
                    </div>
                    <div className="mt-3">
                      <span className="text-base font-medium	text-no_color mt-3">
                        {t("SKILLS")} :{" "}
                      </span>
                      <span className="text-base	font-normal	text-skills_color">
                        {item.skills}
                      </span>
                    </div>
                    <hr className={`${styles.separationLine} mt-2`} />
                  </>
                ))
              ) : (
                <p>{t("NOT_AVAILABLE")}</p>
              )}
            </div>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default ProfileExperience;
