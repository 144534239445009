import React, { useCallback, useState } from "react";
import TopHeader from "../UserHeaders/TopHeader";
import Footer from "../Footer/Footer";
import BottomHeader from "../UserHeaders/BottomHeader";
import profileBackgroundImg from "../../Assests/Images/profilebackground.webp";
import styles from "./UserProfile.module.css";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import editImg1 from "../../Assests/Images/editImage.webp";
import editImg2 from "../../Assests/Images/editGrayImg.webp";
import "../../Assests/Styles/Common.css";
import AboutMe from "./AboutMe/AboutMe";
import ExpeSkills from "./ExpeerienceSkills/ExpeSkills";
import Education from "./Education/Education";
import MyTag from "./MyTags/MyTag";
import UserInfoModal from "./UserInfoModal";
import { useSelector, useDispatch } from "react-redux";
import { STATUS_CODES } from "../../utils/StatusCode";
import { profileData } from "../../store/Slices/ProfileSlice";
import SublyApi from "../../helpers/Api";
import { Toast } from "../../utils/Toster";
import { useEffect } from "react";
import moment from "moment";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";
import Loader from "../../utils/Loader/Loader";
import { useNavigate } from "react-router-dom";
import Skills from "./Skills/Skills";
import { getCroppedImg } from "../Cropper/Cropper";
import Cropper from "react-easy-crop";

const UserProfile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { profile } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const { userToken, currentUser } = useSelector((state) => state.user);
  const [userInfo, setUserInfo] = useState(false);
  const [coverImg, setCoverImg] = useState();
  const [coverPreview, setCoverPreview] = useState(profileBackgroundImg);
  const [profileImageView, setProfileImageView] = useState("");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [showHead, setShowHead] = useState(false);
  const [zoom, setZoom] = useState(1);

  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  //  === function for fetch profile data ===
  useEffect(() => {
    async function fetchProfileData() {
      await dispatch(profileData(userToken)).then((responsejson) => {
        const response = responsejson.payload;
        if (response.status_code === STATUS_CODES.SUCCESS) {
        } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: response.message,
          });
        } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
          Toast.fire({
            icon: "error",
            title: response.data.message,
          });
          logOutDataClear();
        } else if (response.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        } else {
          Toast.fire({
            icon: "error",
            title: response.message,
          });
        }
      });
    }
    fetchProfileData();
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        profileImageView,
        croppedAreaPixels
      );
      imgCropper();

      var blockCrop = croppedImage.split(";");
      // Get the content type of the image
      var cropContentTypes = blockCrop[0].split(":")[1]; // In this case "image/png"
      // get the real base64 content of the file
      var blockRealData = blockCrop[1].split(",")[1];

      var cropBlobImg = await b64toBlob(blockRealData, cropContentTypes);

      setCoverImg(cropBlobImg);
      setCoverPreview(croppedImage);
      setProfileImageView("");
      imgCropper();
      coverImgUpdate(cropBlobImg);
    } catch (e) {
      console.error(e);
    }
  }, [profileImageView, croppedAreaPixels]);

  function b64toBlob(cropData, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;
    var byteCharacters = window.atob(cropData); //decode string
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const imgCropper = (e) => {
    if (e && e.target.files && e.target.files.length == 0) {
      return false;
    } else {
      showHead ? setShowHead(false) : setShowHead(true);
    }
  };

  const onImgChange = (e) => {
    if (e.target.files.length !== 0) {
      setProfileImageView(URL.createObjectURL(e.target.files[0]));
      // setProfileImageView(e.target.files[0]);
    }
  };

  //  === function for upload cover image ===
  const coverImgUpdate = async (img) => {
    setIsLoader(true);
    let requestData = new FormData();
    requestData.append("cover_img", img);
    await SublyApi.uploadCoverImg(userToken, requestData).then(
      (responsejson) => {
        if (responsejson.status_code === 200) {
          Toast.fire({
            icon: "success",
            title: responsejson.message,
          });
          dispatch(profileData(userToken));
          setIsLoader(false);
        } else if (
          responsejson.data.status_code === STATUS_CODES.INVALID_TOKEN
        ) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          logOutDataClear();
          setIsLoader(false);
        } else {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          setIsLoader(false);
        }
      }
    );
  };

  useEffect(() => {
    setCoverImg(profile.cover_img);
    setCoverPreview(profile.cover_img);
  }, []);

  return (
    <>
      <Modal
        show={showHead}
        onHide={() => {
          imgCropper();
          setCrop({ x: 0, y: 0 });
          setZoom(1);
          setCroppedAreaPixels(null);
          setCoverImg("");
          setCoverPreview("");
        }}
        backdrop="static"
        className="modalCloseBtn"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("IMANGE_CROP")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="manageCropper">
          <Cropper
            image={profileImageView}
            crop={crop}
            zoom={zoom}
            aspect={4 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className={`bg-mid_background text-white font-medium text-lg ${styles.CropBtn}`}
            onClick={showCroppedImage}
          >
            {t("CROP")}
          </Button>
          <Button
            variant="secondary"
            className={`text-white font-medium text-lg ${styles.closeBtn}`}
            onClick={imgCropper}
          >
            {t("CLOSE")}
          </Button>
        </Modal.Footer>
      </Modal>
      {isLoader ? <Loader /> : ""}
      <div className={styles.userProfileBack}>
        <div className={styles.upper}>
          <TopHeader />
          <img src={profile.cover_img} className={styles.backgImg} />

          <Container className={styles.mainEditImg}>
            <label htmlFor="uploadCoverImage" className="flex">
              <img
                className={styles.editImage1}
                src={editImg1}
                alt="edit-image"
              />
              <input
                id="uploadCoverImage"
                type="file"
                accept="image/*"
                style={{
                  display: "none",
                }}
                onChange={(e) => {
                  onImgChange(e);
                  imgCropper(e);
                }}
                onClick={(e) => {
                  e.target.value = "";
                }}
              />
            </label>
          </Container>
          <div className={styles.inner}>
            <BottomHeader />
          </div>
        </div>

        {/* User Information part */}
        <div className={`bg-bg_colour ${styles.user}`}>
          <Container>
            <div className={` ${styles.userInfo}`}>
              <Row>
                <Col lg={4} xl={3} xxl={3}>
                  <div className={styles.userProfileImage}>
                    <img
                      className={styles.profileImg}
                      src={profile.profile_img}
                      alt="user-profile"
                    />
                    <div className={styles.connection}>
                      <div
                        onClick={() => {
                          navigate(`/my-network/${1}`);
                        }}
                        className="cursor-pointer w-1/2 text-center"
                      >
                        <p className="flex justify-center	font-semibold	text-xl text-mid_background mt-1 lg:text-lg md:text-lg sm:text-lg">
                          {profile.connection_count}
                        </p>
                        <p className={`font-medium	text-base mt-1 ${styles.connectionText}`}>
                          {t("MY_CONNECTION")}
                        </p>
                      </div>
                      <span className="betweenLine"></span>
                      <div
                        // onClick={() => {
                        //   navigate("/followers");
                        // }}

                        onClick={() => {
                          navigate(`/followers/${currentUser.id}`);
                        }}
                        className="cursor-pointer w-1/2 text-center"
                      >
                        <p className="flex justify-center	font-semibold	text-xl text-mid_background mt-1 lg:text-lg md:text-lg sm:text-lg">
                          {profile.follower_count}
                        </p>
                        <p className={`font-medium	text-base mt-1 ${styles.connectionText}`}>
                          {t("MY_FOLLOWERS")}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  lg={8}
                  xl={9}
                  xxl={9}
                  className={`${styles.infoWrap} 2xl:flex xl:flex lgg:flex lg:flex md:flex content-between item-center sm:mt-44`}
                >
                  <div
                    className={`mt-4 ml-10 sm:ml-0 w-full relative ${styles.tests}`}
                  >
                    <p
                      className={`font-bold text-4xl text-mid_background	${styles.userName}`}
                    >
                      {profile.fullname}
                    </p>
                    {profile.current_designation &&
                      profile.current_company_name ? (
                      <p
                        className={`font-medium text-xl text-my_color1	${styles.userDesigination}`}
                      >
                        {profile.current_designation}
                        {" - "}
                        {profile.current_company_name}
                      </p>
                    ) : (
                      ""
                    )}
                    <p
                      className={`font-normal text-lg text-my_color2	${styles.userAddress}`}
                    >
                      {profile.location}
                    </p>
                    <p
                      className={`font-normal text-base text-my_color2	${styles.userDOB}`}
                    >
                      {t("USER_DOB")}
                      {moment(profile.dob).format("DD/MM/YYYY")}
                    </p>
                  </div>
                  <div className={styles.editUserInfo}>
                    <img
                      src={editImg2}
                      alt="edit-image"
                      className={styles.editImage}
                      onClick={() => setUserInfo(true)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <UserInfoModal userInfo={userInfo} setUserInfo={setUserInfo} />

        {/* About me section */}
        <div className={`mt-36 lg:mt-20 md:mt-20 sm:mt-8 ${styles.aboutUpper}`}>
          <AboutMe />
        </div>

        {/* Experience section */}
        <div className="mt-8">
          <ExpeSkills />
        </div>

        {/* Skills section */}
        <div className="mt-8">
          <Skills />
        </div>

        {/* Education section */}
        <div className="mt-8">
          <Education />
        </div>

        {/* My tag section */}
        <div className="mt-8">
          <MyTag />
        </div>

        {/* Footer section */}
        <div className="mt-8">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default UserProfile;
