import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import styles from "./Setting.module.css";
import { useTranslation } from "react-i18next";
import Loader from "../../utils/Loader/Loader";
import SublyApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";
import { Toast } from "../../utils/Toster";
import { STATUS_CODES } from "../../utils/StatusCode";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";

function NeedHelp({ isLoader, openModal, setOpenModal, setIsLoader }) {
  const { t } = useTranslation();
  const { userToken } = useSelector((state) => state.user);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [errorDis, setErrorDis] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  // =======clear redux========
  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  // ===========Here calling API for need help============
  async function needHelpHandle() {
    setIsLoader(true);
    const requestData = new FormData();
    requestData.append("help_title", title.trim());
    requestData.append("help_description", description.trim());
    await SublyApi.needHelp(userToken, requestData).then((response) => {
      if (response.status_code === STATUS_CODES.SUCCESS) {
        setIsLoader(false);
        setTitle("");
        setDescription("");
        setOpenModal(false);
        Toast.fire({
          icon: "success",
          title: response.message,
        });
      } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: response.message,
        });
        setIsLoader(false);
      } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        setIsLoader(false);
        logOutDataClear();
      } else if (response.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        setIsLoader(false);
      } else {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        setIsLoader(false);
      }
    });
  }
  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <Modal
        show={openModal}
        onHide={() => {
          setOpenModal(false);
          setErrorDis("");
          setError("");
          setDescription("");
          setTitle("");
        }}
        className="modalCloseBtn"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles.modalTitle}>
            {t("NEED_HELP")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.textAreaClass}>
          <div>
            <div className="flex flex-col mb-6">
              <label>{t("TITLE")}</label>
              <input
                type="text"
                placeholder={t("CREATE_TITLE")}
                onKeyUp={(e) => sanitizeHtmlTags().validate(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode == 32 && !e.target.value.length) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setTitle(e.target.value);
                  if (/<(\/)?[a-z][\s\S]*>/i.test(e.target.value)) {
                    setError("HTML tags are not allowed.");
                  } else {
                    // Value is valid, proceed with your logic
                    setError("");
                    if (
                      e.target.value &&
                      (e.target.value.trim().length > 250 ||
                        e.target.value.trim().length <= 3)
                    ) {
                      setError(
                        "Text should be less then or equal to 250 characters and more then or equal to 3 characters"
                      );
                    } else {
                      setError("");
                    }
                  }
                }}
              />
              <span className="text-sm text-red-600">{error}</span>
            </div>
            <div className="flex flex-col mb-6">
              <label>{t("DESCRIPTION")}</label>
              <textarea
                type="text"
                placeholder={t("TYPE_HERE")}
                onKeyUp={(e) => sanitizeHtmlTags().validate(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode == 32 && !e.target.value.length) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setDescription(e.target.value);
                  if (/<(\/)?[a-z][\s\S]*>/i.test(e.target.value)) {
                    setErrorDis("HTML tags are not allowed.");
                  } else {
                    // Value is valid, proceed with your logic
                    setErrorDis("");
                    if (
                      e.target.value &&
                      (e.target.value.trim().length > 1000 ||
                        e.target.value.trim().length <= 5)
                    ) {
                      setErrorDis(
                        "Text should be less then or equal to 1000 characters and more then or equal to 5 characters"
                      );
                    } else {
                      setErrorDis("");
                    }
                  }
                }}
              />
              <span className="text-sm text-red-600">{errorDis}</span>
            </div>
            <div className={styles.submitBtn}>
              <button
                className={`bg-mid_background`}
                onClick={() => {
                  needHelpHandle();
                }}
                disabled={
                  title && description && !error && !errorDis ? false : true
                }
              >
                {t("SUBMIT")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default NeedHelp;
