import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "../appComponent/Home/Home";
import SignUp from "../authComponents/SignUp/SignUp";
import Login from "../authComponents/Login/Login";
import PageNotFound from "../appComponent/PageNotFound/PageNotFound";
import ForgotPassword from "../authComponents/ForgotPassword/ForgotPassword";
import EmailVerify from "../authComponents/EmailVerify/Email.Verify";
import AboutPage from "../appComponent/AboutPage/AboutPage";
import VerifyForgotPassword from "../authComponents/VerifyForgotPassword/VerifyForgotPassword";
import UserProfile from "../appComponent/UserProfile/UserProfile";
import HomeModule from "../appComponent/HomeModule/HomeModule";
import { useSelector } from "react-redux";
import UserInfoModal from "../appComponent/UserProfile/UserInfoModal";
import MyNetwork from "../appComponent/MyNetwork/MyNetwork";
import NetworkProfileDetail from "../appComponent/MyNetwork/NetworkProfile/NetworkProfileDetail";
import UserFollowers from "../appComponent/MyNetwork/NetworkProfile/Followers/UserFollowers";
import PostDetail from "../appComponent/PostDetail/PostDetail";
import Setting from "../appComponent/SettingModule/Setting";
import ChatModule from "../appComponent/ChatModule/ChatModule";
import ChatDetail from "../appComponent/ChatModule/ChatDetails";
import GlobalSearch from "../appComponent/UserHeaders/GlobalSearch/GlobalSearch";
import ChatGroupDetail from "../appComponent/ChatModule/ChatGroupDetail";
import ScrollToTop from "../helpers/ScrollToTop";

export const Router = () => {
  const { currentUser } = useSelector((state) => state.user);
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <Routes>
        <Route
          exact
          path="/"
          element={
            Object.keys(currentUser).length == 0 ? (
              <Home />
            ) : (
              <Navigate to="/user-home" />
            )
          }
        />
        <Route
          exact
          path="/home"
          element={
            Object.keys(currentUser).length == 0 ? (
              <Home />
            ) : (
              <Navigate to="/user-home" />
            )
          }
        />
        <Route
          exact
          path="/about-us"
          element={
            Object.keys(currentUser).length == 0 ? (
              <AboutPage />
            ) : (
              <Navigate to="/user-home" />
            )
          }
        />
        <Route
          exact
          path="/sign-up"
          element={
            Object.keys(currentUser).length == 0 ? (
              <SignUp />
            ) : (
              <Navigate to="/user-home" />
            )
          }
        />
        <Route
          exact
          path="/login"
          element={
            Object.keys(currentUser).length == 0 ? (
              <Login />
            ) : (
              <Navigate to="/user-home" />
            )
          }
        />
        <Route
          exact
          path="/forgot-password"
          element={
            Object.keys(currentUser).length == 0 ? (
              <ForgotPassword />
            ) : (
              <Navigate to="/user-home" />
            )
          }
        />
        <Route exact path="/verify/:emailToken" element={<EmailVerify />} />
        <Route
          exact
          path="/verify-forgot-password/:tokenData"
          element={
            Object.keys(currentUser).length == 0 ? (
              <VerifyForgotPassword />
            ) : (
              <Navigate to="/user-home" />
            )
          }
        />
        <Route
          exact
          path="/user-profile"
          element={
            Object.keys(currentUser).length == 0 ? (
              <Navigate to="/" />
            ) : (
              <UserProfile />
            )
          }
        />
        <Route
          exact
          path="/user-home"
          element={
            Object.keys(currentUser).length == 0 ? (
              <Navigate to="/" />
            ) : (
              <HomeModule />
            )
          }
        />
        <Route
          exact
          path="/my-network/:tab"
          element={
            Object.keys(currentUser).length == 0 ? (
              <Navigate to="/" />
            ) : (
              <MyNetwork />
            )
          }
        />
        <Route
          exact
          path="/my-network/profile-detail/:id"
          element={
            Object.keys(currentUser).length == 0 ? (
              <Navigate to="/" />
            ) : (
              <NetworkProfileDetail />
            )
          }
        />
        <Route
          exact
          path="/followers/:id"
          element={
            Object.keys(currentUser).length == 0 ? (
              <Navigate to="/" />
            ) : (
              <UserFollowers />
            )
          }
        />
        <Route
          exact
          path="/post-detail/:id"
          element={
            Object.keys(currentUser).length == 0 ? (
              <Navigate to="/" />
            ) : (
              <PostDetail />
            )
          }
        />
        <Route
          exact
          path="/settings"
          element={
            Object.keys(currentUser).length == 0 ? (
              <Navigate to="/" />
            ) : (
              <Setting />
            )
          }
        />
        <Route
          exact
          path="/chat"
          element={
            Object.keys(currentUser).length == 0 ? (
              <Navigate to="/" />
            ) : (
              <ChatModule />
            )
          }
        />
        <Route
          exact
          path="/chat/chat-details"
          element={
            Object.keys(currentUser).length == 0 ? (
              <Navigate to="/" />
            ) : (
              <ChatDetail />
            )
          }
        />
        <Route
          exact
          path="/search-for/:search"
          element={
            Object.keys(currentUser).length == 0 ? (
              <Navigate to="/" />
            ) : (
              <GlobalSearch />
            )
          }
        />
        <Route
          exact
          path="/chat/chat-group-detail"
          element={
            Object.keys(currentUser).length == 0 ? (
              <Navigate to="/" />
            ) : (
              <ChatGroupDetail />
            )
          }
        />
        <Route
          exact
          path="/user-info-modal"
          element={<UserInfoModal />}
        ></Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
