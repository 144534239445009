import { t } from "i18next";

export const services = [
  {
    id: "1",
    name: `${t("SOCIAL_CONNECT")}`,
    link: "javascript:;",
  },
  {
    id: "2",
    name: `${t("DISCOVER")}`,
    link: "javascript:;",
  },
  {
    id: "3",
    name: `${t("HIRING")}`,
    link: "javascript:;",
  },
  {
    id: "4",
    name: `${t("COMPANIES_JOB")}`,
    link: "javascript:;",
  },
  {
    id: "5",
    name: `${t("REQUIREMENTS")}`,
    link: "javascript:;",
  },
];
