import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import editImg2 from "../../../Assests/Images/editGrayImg.webp";
import styles from "./Skills.module.css";
import SkillModal from "../SkillModal";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";

const Skills = () => {
  const [userSkill, setUserSkill] = useState(false);
  const { profile } = useSelector((state) => state.profile);
  const userSkillsData = profile.skills ? profile.skills : [];

  const { t } = useTranslation();

  return (
    <>
      <Container>
        <div className={`bg-bg_colour ${styles.Skill}`}>
          <div className={`flex ${styles.skills}`}>
            <div>
              <p
                className={`text-solid_black font-semibold ${styles.skillHeading}`}
              >
                {t("SKILLS")}
              </p>
            </div>
            <div>
              <img
                src={editImg2}
                alt="edit-image"
                className="w-5 h-5 cursor-pointer"
                onClick={() => setUserSkill(true)}
              />
            </div>
          </div>
          <div className="mt-2 flex flex-wrap">
            {userSkillsData && userSkillsData.length > 0 ? (
              userSkillsData.map((item, index) => (
                <span
                  key={index}
                  className={`text-my_color2 text-base mt-3 ml-1 ${styles.userSkills}`}
                >
                  {item.skill_name}{" "}
                </span>
              ))
            ) : (
              <p>{t("NOT_AVAILABLE")}</p>
            )}
          </div>
        </div>
      </Container>
      <SkillModal userSkill={userSkill} setUserSkill={setUserSkill} />
    </>
  );
};

export default Skills;
