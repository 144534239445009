import React from "react";
import styles from "./PageNotFound.module.css";
import { Container } from "react-bootstrap";
import Header from "../Header/Header";
import { useTranslation } from "react-i18next";

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className={styles.main}>
        <Container>
          <h1 className={styles.mainHeading}>{t("OOPS")}</h1>
          <div className={styles.subHeading}>{t("PAGE_404")}</div>
          <p className={styles.text404}>{t("SORRY_PAGE_NOT_EXIST")}</p>
          <div className={styles.toHome}>
          </div>
        </Container>
      </div>
    </>
  );
};

export default PageNotFound;
