import React, { useState } from "react";
import styles from "./HomeModule.module.css";
import { Container } from "react-bootstrap";
import image from "../../Assests/Images/image.svg";
import video from "../../Assests/Images/video.svg";
import arrow from "../../Assests/Images/arrow.svg";
import { useTranslation } from "react-i18next";
import UploadSection from "./UploadSection";
import { useSelector } from "react-redux";

function HomeHeader() {
  const { t } = useTranslation();
  const { profileDetail } = useSelector((state) => state.profile);
  const [modalShow, setModalShow] = useState(false);

  return (
    <section className={`px-2 ${styles.homeHeaderBody} commonLinkClass`}>
      <Container>
        <div
          className={styles.homeHeaderContain}
          onClick={() => {
            setModalShow(true);
          }}
        >
          <div className={`flex items-center ${styles.userIconClass}`}>
            <img src={profileDetail.profile_img} />
            <input
              placeholder={t("WHATS_YOUR_MIND")}
              className="w-full pr-6 cursor-pointer"
              value={""}
            />
          </div>
          <div
            className={`flex items-center gap-3 mr-2.5 ${styles.responsiveIcons}`}
          >
            <img
              src={image}
              alt="image"
              className="w-7 h-7 cursor-pointer"
              onClick={() => {
                setModalShow(true);
              }}
            />
            <img
              src={video}
              alt="video"
              className="w-9 h-9 cursor-pointer"
              onClick={() => {
                setModalShow(true);
              }}
            />
            <button
              className={`mt-0 bg-mid_background text-white font-medium w-32 h-12 text-lg ${styles.PostBtn}`}
              onClick={() => setModalShow(true)}
            >
              {t("POST_BTN")}
              <img src={arrow} />
            </button>
          </div>
        </div>
      </Container>
      <UploadSection modalShow={modalShow} setModalShow={setModalShow} />
    </section>
  );
}

export default HomeHeader;
