import React, { useCallback, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import styles from "./ChatModule.module.css";
import { useTranslation } from "react-i18next";
import Loader from "../../utils/Loader/Loader";
import SublyApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";
import { Toast } from "../../utils/Toster";
import { STATUS_CODES } from "../../utils/StatusCode";
import placeHolderImg from "../../Assests/Images/image_placeholder.png";
import camera from "../../Assests/Images/camera.svg";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../Cropper/Cropper";
import AddMamberModal from "./AddMemberModal";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";
import { useNavigate } from "react-router-dom";
import { IS_PRIVATE_CHAT } from "../../utils/Constants";

function CreateGroupModal({
  setOpenGroup,
  openGroup,
  setChatState,
  isFromChatList,
}) {
  const { t } = useTranslation();
  const { userToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [groupImg, setGroupImg] = useState("");
  const [groupTitle, SetGroupTitle] = useState("");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [showHead, setShowHead] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [profileImageView, setProfileImageView] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [openAddMember, SetOpenAddMember] = useState(false);
  const [membersList, setMembersList] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // =======clear redux========
  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  //  === function for show cropper in craete group section ===
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        profileImageView,
        croppedAreaPixels
      );
      imgCropper();

      var blockCrop = croppedImage.split(";");
      // Get the content type of the image
      var cropContentTypes = blockCrop[0].split(":")[1]; // In this case "image/png"
      // get the real base64 content of the file
      var blockRealData = blockCrop[1].split(",")[1];

      var cropBlobImg = await b64toBlob(blockRealData, cropContentTypes);
      setGroupImg(croppedImage);
      setImageSrc(cropBlobImg);

      setProfileImageView("");
    } catch (e) {
      console.error(e);
    }
  }, [profileImageView, croppedAreaPixels]);

  function b64toBlob(cropData, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;
    var byteCharacters = window.atob(cropData); //decode string
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const imgCropper = (e) => {
    if (e && e.target.files && e.target.files.length == 0) {
      return false;
    } else {
      showHead ? setShowHead(false) : setShowHead(true);
    }
  };

  // function for upload images onchange
  const onImgChange = (e) => {
    let fileReader,
      isCancel = false;
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (e.target.files && e.target.files.length > 0) {
        fileReader = new FileReader();
        fileReader.onload = async (e) => {
          const { result } = e.target;
          if (result && !isCancel) {
            setProfileImageView(result);
          }
        };
        fileReader.readAsDataURL(file);
      }
    }
  };

  // function for create group
  async function createGroupHandle() {
    const requestData = new FormData();
    requestData.append("group_title", groupTitle && groupTitle);
    requestData.append("memberData", membersList && membersList);
    requestData.append(
      "group_description",
      "Testing the create group static data"
    );
    requestData.append("group_image", imageSrc && imageSrc);
    await SublyApi.createGroupChat(userToken, requestData).then((response) => {
      if (response.status_code === STATUS_CODES.SUCCESS) {
        // console.log("createGroupChat response", response);
        setOpenGroup(false);
        SetOpenAddMember(false);
        SetGroupTitle("");
        setGroupImg("");
        Toast.fire({
          icon: "success",
          title: response.message,
        });

        if (isFromChatList && isFromChatList == 1) {
          navigate("/chat/chat-details", {
            state: {
              room_id: response.data.chat_room_id,
              refrence_id: response.data.refrence_id,
              room_type: response.data.room_type,
              is_group_created: true,
              chatDetails: response.data,
              is_private_chat: IS_PRIVATE_CHAT.CHAT_LIST_PRIVATE_CONNECTION,
            },
          });
        } else {
          setChatState({
            room_id: response.data.chat_room_id,
            refrence_id: response.data.refrence_id,
            room_type: response.data.room_type,
            is_group_created: true,
            chatDetails: response.data,
            is_private_chat: IS_PRIVATE_CHAT.CHAT_LIST_PRIVATE_CONNECTION,
          });
        }

        setIsLoader(false);
      } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: response.message,
        });
        setIsLoader(false);
      } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        logOutDataClear();
        setIsLoader(false);
      } else {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        setIsLoader(false);
      }
    });
  }

  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <Modal
        show={openGroup}
        onHide={() => {
          setOpenGroup(false);
          SetGroupTitle("");
          setGroupImg("");
          setMembersList("");
        }}
        className="modalCloseBtn"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles.modalTitle}>
            {t("GROUP_CREATE")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.textAreaClass}>
          <div className={styles.groupImageBox}>
            <img
              src={groupImg ? groupImg : placeHolderImg}
              className={styles.groupImage}
            />
            <label for="groupImg">
              <img src={camera} />
            </label>
            <input
              type="file"
              id="groupImg"
              accept="image/*"
              onClick={(e) => {
                e.target.value = "";
              }}
              onChange={(e) => {
                onImgChange(e);
                imgCropper(e);
              }}
            />
          </div>
          <div>
            <div className="flex flex-col mb-6">
              <label>{t("GROUP_TITLE")}</label>
              <input
                type="text"
                placeholder={t("CREATE_TITLE")}
                value={groupTitle}
                onKeyUp={(e) => sanitizeHtmlTags().validate(e.target.value)}
                onChange={(e) => {
                  SetGroupTitle(e.target.value);
                  if (/<(\/)?[a-z][\s\S]*>/i.test(e.target.value)) {
                    setError("HTML tags are not allowed.");
                  } else {
                    // Value is valid, proceed with your logic
                    setError("");
                  }
                }}
              />
              <span className="text-sm text-red-600">{error}</span>
            </div>
            <div className={styles.submitBtn}>
              <button
                className={`bg-mid_background ${styles.createGroup}`}
                onClick={() => {
                  setOpenGroup(false);
                  SetOpenAddMember(true);
                }}
                disabled={groupTitle ? false : true}
              >
                {t("NEXT")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showHead}
        onHide={() => {
          imgCropper();
          setProfileImageView("");
          setCrop({ x: 0, y: 0 });
          setZoom(1);
          setCroppedAreaPixels(null);
        }}
        backdrop="static"
        className="modalCloseBtn"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("IMANGE_CROP")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="manageCropper">
          <Cropper
            image={profileImageView}
            crop={crop}
            zoom={zoom}
            aspect={1 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className={`bg-mid_background text-white font-medium text-lg ${styles.CropBtn}`}
            onClick={showCroppedImage}
          >
            {t("CROP")}
          </Button>
          <Button
            variant="secondary"
            className={`text-white font-medium text-lg ${styles.closeBtn}`}
            onClick={imgCropper}
          >
            {t("CLOSE")}
          </Button>
        </Modal.Footer>
      </Modal>
      <AddMamberModal
        SetOpenAddMember={SetOpenAddMember}
        openAddMember={openAddMember}
        createGroupHandle={createGroupHandle}
        setMembersList={setMembersList}
        isCreate={true}
        setIsLoader={setIsLoader}
        membersList={membersList}
      />
    </section>
  );
}

export default CreateGroupModal;
