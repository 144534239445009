import React from "react";
import styles from "../MyNetwork.module.css";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FacebookShareButton } from "react-share";
import { TwitterShareButton } from "react-share";
import { LinkedinShareButton } from "react-share";
import { WhatsappShareButton } from "react-share";
import linkdinicon from "../../../Assests/Images/LinkedIN_ico_new.png";
import twittericon from "../../../Assests/Images/twitter_ico.png";
import facebookicon from "../../../Assests/Images/facebook_ico.png";
import whatappiconnew from "../../../Assests/Images/whatsapp_ico_new.png";

function ShareUser({ openShare, setOpenShare, userId }) {
  const { t } = useTranslation();

  return (
    <section>
      <Modal
        show={openShare}
        onHide={() => {
          setOpenShare(false);
        }}
        className="modalCloseBtn"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles.modalTitle}>
            {t("SHARE_POST")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.shareIcon}>
            <FacebookShareButton url={`${window.location.host}/my-network/profile-detail/${userId}`}>
              <img src={facebookicon} />
              <p>{t("FACEBOOK")}</p>
            </FacebookShareButton>

            <TwitterShareButton url={`${window.location.host}/my-network/profile-detail/${userId}`}>
              <img src={twittericon} />
              <p>{t("TWITTER")}</p>
            </TwitterShareButton>

            <LinkedinShareButton url={`${window.location.host}/my-network/profile-detail/${userId}`}>
              <img src={linkdinicon} />
              <p>{t("LINKEDIN")}</p>
            </LinkedinShareButton>

            <WhatsappShareButton url={`${window.location.host}/my-network/profile-detail/${userId}`}>
              <img src={whatappiconnew} />
              <p>{t("WHATSAPP")}</p>
            </WhatsappShareButton>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default ShareUser;
