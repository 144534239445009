import React, { useState } from "react";
import ChatList from "./ChatList";
import ProfileSection from "../HomeModule/ProfileSection";
import { Col, Container, Row } from "react-bootstrap";
import TopHeader from "../UserHeaders/TopHeader";
import BottomHeader from "../UserHeaders/BottomHeader";
import styles from "./ChatModule.module.css";
import { useTranslation } from "react-i18next";
import TrendingSection from "../HomeModule/TrendingSection";
import Footer from "../Footer/Footer";
import chatBackground from "../../Assests/Images/Chat_bg.png";
import CreateGroup from "./CreateGroup";
import ChatDetailResponsive from "./ChatDetailResponsive";

function ChatModule() {
  const { t } = useTranslation();
  const [openGroup, setOpenGroup] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  window.addEventListener("resize", function (event) {
    setScreenWidth(window.innerWidth);
  });
  return (
    <section>
      {screenWidth > 991 ? (
        <div className={`${styles.myNetworkWrapper} background_color`}>
          <TopHeader />
          <div
            className={`${styles.backgImg} bg-cover w-full bg-center relative`}
            style={{
              backgroundImage: `url(${chatBackground})`,
            }}
          >
            <div className={styles.btmHeader}>
              <BottomHeader />
            </div>
            <p className={styles.myNetworkHeading}>{t("CHAT")}</p>
          </div>
          <div className={`mt-8 mx-0 ${styles.chatMain}`}>
            <Container>
              <Row>
                <Col lg={3} md={12} sm={12} className="mb-4">
                  <div className={`"mt" ${styles.hideInResponsive}`}>
                    <ProfileSection isSetting={true} />
                  </div>
                </Col>
                <Col lg={6} md={12} sm={12} className="mb-4">
                  <ChatList openGroup={openGroup} />
                </Col>
                <Col lg={3} md={12} sm={12} className="mb-4">
                  <div className="mb-4">
                    <CreateGroup
                      openGroup={openGroup}
                      setOpenGroup={setOpenGroup}
                      isFromChatList={1}
                    />
                  </div>
                  <div className={`"mt" ${styles.hideInResponsive}`}>
                    <TrendingSection isSetting={"chat"} />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      ) : (
        <ChatDetailResponsive />
      )}
    </section>
  );
}

export default ChatModule;
