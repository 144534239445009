import React, { useEffect, useState } from "react";
import forgotPasswordImg from "../../Assests/Images/forgotPassword.webp";
import bakeImg from "../../Assests/Images/back.webp";
import Header from "../../appComponent/Header/Header";
import Footer from "../../appComponent/Footer/Footer";
import styles from "./ForgotPassword.module.css";
import { Col, Form, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import SublyApi from "../../helpers/Api";
import { Toast } from "../../utils/Toster";
import Loader from "../../utils/Loader/Loader";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";
import $ from "jquery";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  // === function for forgot password api calling ===
  const onSubmit = async (formdata) => {
    setIsLoader(true);
    let requestData = new FormData();
    requestData.append("email", formdata.email);
    await SublyApi.forgotPassword(requestData).then((responsejson) => {
      if (responsejson.status_code === 200) {
        Toast.fire({
          icon: "success",
          title: responsejson.message,
        });
        navigate(`/verify-forgot-password/${responsejson.data.token}`);
        setIsLoader(false);
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.data.message,
        });
        setIsLoader(false);
      }
    });
  };

  // disable space on key press input email field
  const onInputChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  return (
    <>
      {isLoader ? <Loader /> : ""}
      <Header />
      <div className={styles.forgotPasswordMain}>
        <Container>
          <div className={styles.forgotContainer}>
            <Row>
              <Col lg={6}>
                <div className={styles.forgotSide}>
                  <div className="flex mb-3 pb-12 ">
                    <img
                      onClick={() => navigate(-1)}
                      className="pt-4 w-5 h-11 cursor-pointer"
                      src={bakeImg}
                      alt="back"
                    />
                    <p className="font-medium mt-6 ml-2.5">{t("BACK")}</p>
                  </div>

                  <p
                    className={`text-4xl text-sign_button ${styles.forgotHeading}`}
                  >
                    {t("FORGOT_PASSWORD")}
                  </p>

                  <p
                    className={`text-my_color1 leading-5 ${styles.forgotSubHeading}`}
                  >
                    {t("FORGOT_SUB_HEADING")}
                  </p>

                  <Form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        className={` h-12 ${styles.email}`}
                        placeholder={t("EMAIL")}
                        {...register("email", {
                          onChange: (e) => onInputChange(e),
                          required: {
                            value: true,
                            message: `${t("EMAIL_REQUIRED")}`,
                          },
                          pattern: {
                            value:
                              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                            message: `${t("EMAIL_INVALID")}`,
                          },
                          ...sanitizeHtmlTags(),
                        })}
                      />
                      <div className="text-error_message">
                        {errors.email ? (
                          <span>{errors.email.message}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </Form.Group>

                    <div>
                      <p className="text-center mt-12 font-semibold">
                        <Link to="/login">{t("BACK_TO_SIGNIN")}</Link>
                      </p>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className={`h-12 text-2xl bg-sign_button text-white rounded ${styles.submitBtn} `}
                      >
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </Form>
                </div>
              </Col>
              <Col lg={6}>
                <img
                  className={`${styles.forgotPassword}`}
                  src={forgotPasswordImg}
                  alt="forgot-password"
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;
