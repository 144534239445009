import React, { useState, useCallback, memo } from "react";
import styles from "./ChatModule.module.css";
import { useTranslation } from "react-i18next";
import three_dot from "../../Assests/Images/three_dot.svg";
import vertical_three_dot from "../../Assests/Images/three-dot-vertical.svg";
import { useLocation, useNavigate } from "react-router-dom";
import camera from "../../Assests/Images/blue-camera.svg";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../Cropper/Cropper";
import { Modal, Button } from "react-bootstrap";
import AddMamberModal from "./AddMemberModal";
import { useSelector } from "react-redux";
import $ from "jquery";
import SublyApi from "../../helpers/Api";
import { Toast } from "../../utils/Toster";
import { STATUS_CODES } from "../../utils/StatusCode";

function ChatGroupDetailModule() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [groupImg, setGroupImg] = useState("");
  const [openAddMember, SetOpenAddMember] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [showHead, setShowHead] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [profileImageView, setProfileImageView] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [membersList, setMembersList] = useState("");
  const { currentUser, userToken } = useSelector((state) => state.user);
  const [showRemoveOptions, setShowRemovOptions] = useState(false);
  const [selectedMemberIndex, setSelectedMemberIndex] = useState(null);
  const [details, setDetails] = useState(location.state);
  const refrence_id = location.state && location.state.refrence_id;

  // ====function to hide box on click outside====
  $(document).mouseup(function (e) {
    //Hide clear chat option box on outside click
    if ($(e.target).closest(".openBoxClass").length === 0) {
      setShowRemovOptions(false);
    }
  });

  // Function to open user profile
  function openUserProfile(userId) {
    // Check if the clicked user ID is the same as the current user's ID
    if (userId === currentUser.id) {
      // Redirect to the current user's profile page
      navigate("/user-profile");
    } else {
      // Redirect to the profile page of the clicked user
      navigate(`/my-network/profile-detail/${userId}`);
    }
  }

  // function for open clear chatHistory option
  const handleImageClick = (index) => {
    setSelectedMemberIndex(index);
    setShowRemovOptions(!showRemoveOptions);
  };

  // function for calling api remove members
  async function handleRemoveMemberClick(index) {
    const memberToRemove = details.groupMemberList[index];
    const requestData = new FormData();
    requestData.append("refrence_id", refrence_id);
    requestData.append("user_id", memberToRemove.user_id);
    await SublyApi.removeGroupMember(userToken, requestData).then(
      (response) => {
        if (response.status_code === STATUS_CODES.SUCCESS) {
          Toast.fire({
            icon: "success",
            title: response.message,
          });
          // Remove the member from the state
          const updatedMembersList = [...details.groupMemberList];
          updatedMembersList.splice(index, 1);
          setDetails({
            ...details,
            groupMemberList: updatedMembersList,
          });
          setShowRemovOptions(false);
        } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: response.message,
          });
        } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
          Toast.fire({
            icon: "error",
            title: response.data.message,
          });
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.message,
          });
        }
      }
    );
    setSelectedMemberIndex(null);
    setShowRemovOptions(false);
  }

  // function for crop chat group detail image
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // function for show croper in chat group detail
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        profileImageView,
        croppedAreaPixels
      );
      imgCropper();
      var blockCrop = croppedImage.split(";");
      // Get the content type of the image
      var cropContentTypes = blockCrop[0].split(":")[1]; // In this case "image/png"
      // get the real base64 content of the file
      var blockRealData = blockCrop[1].split(",")[1];
      var cropBlobImg = await b64toBlob(blockRealData, cropContentTypes);
      setGroupImg(croppedImage);
      setImageSrc(cropBlobImg);
      setProfileImageView("");
    } catch (e) {
      console.error(e);
    }
  }, [profileImageView, croppedAreaPixels]);

  function b64toBlob(cropData, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;
    var byteCharacters = window.atob(cropData); //decode string
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const imgCropper = (e) => {
    if (e && e.target.files && e.target.files.length == 0) {
      return false;
    } else {
      showHead ? setShowHead(false) : setShowHead(true);
    }
  };

  // function for upload on onchange
  const onImgChange = (e) => {
    let fileReader,
      isCancel = false;
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (e.target.files && e.target.files.length > 0) {
        fileReader = new FileReader();
        fileReader.onload = async (e) => {
          const { result } = e.target;
          if (result && !isCancel) {
            setProfileImageView(result);
          }
        };
        fileReader.readAsDataURL(file);
      }
    }
  };

  const filterData =
    details.groupMemberList &&
    details.groupMemberList.filter((item) => item.user_id == currentUser.id);

  return (
    <section
      className={`${styles.memberListSection} ${styles.chatListSection}`}
    >
      <div className={styles.groupDetailBox}>
        <div className={styles.chatListHead}>
          <div className={styles.detailHeadFlex}>
            <div className={styles.detailHead}>
              <div className={styles.groupImageBox}>
                <img
                  src={groupImg ? groupImg : details.groupDetail.group_image}
                  className={styles.groupImage}
                />
                <label for="groupImg">
                  <img src={camera} />
                </label>
                <input
                  type="file"
                  id="groupImg"
                  accept="image/*"
                  onClick={(e) => {
                    e.target.value = "";
                  }}
                  onChange={(e) => {
                    onImgChange(e);
                    imgCropper(e);
                  }}
                />
              </div>
              <p>{details.groupDetail.group_title}</p>
              <span>
                {details.groupMemberList.length} {t("GROUP_MEMBER")}
              </span>
            </div>
          </div>
          <div>
            <img
              src={three_dot}
              alt="three dot"
              width={"35px"}
              height={"35px"}
            />
          </div>
        </div>
        <div className={styles.chatMemberList}>
          <div className={styles.membersHead}>
            <p>{t("MEMBERS")}</p>

            {filterData &&
            filterData.length > 0 &&
            filterData[0].is_admin == 1 ? (
              <button
                onClick={() => {
                  SetOpenAddMember(true);
                }}
              >
                {t("ADD_MEMBERS")}
              </button>
            ) : (
              ""
            )}
          </div>

          {details.groupMemberList.length > 0
            ? details.groupMemberList.map((item, index) => (
                <div className={`${styles.memberItem}`}>
                  <div className={styles.profileArea}>
                    <div className={styles.profileImg}>
                      <img src={item.profile_picture} />
                    </div>
                    <div
                      className={styles.userInfo}
                      onClick={() => openUserProfile(item.user_id)}
                    >
                      <h4>{item.fullname}</h4>
                    </div>
                  </div>
                  {filterData &&
                  filterData.length > 0 &&
                  filterData[0].is_admin == 1 ? (
                    <div
                      className={`${
                        showRemoveOptions && selectedMemberIndex === index
                          ? "relative openBoxClass"
                          : "relative closeBoxClass"
                      }`}
                    >
                      <img
                        src={vertical_three_dot}
                        alt="read"
                        onClick={() => handleImageClick(index)}
                      />
                      {showRemoveOptions && selectedMemberIndex === index && (
                        <div className={styles.option}>
                          <ul>
                            <li
                              style={{ cursor: "pointer" }}
                              onClick={() => handleRemoveMemberClick(index)}
                            >
                              {t("REMOVE_MEMBER")}
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))
            : ""}
        </div>
      </div>

      <Modal
        show={showHead}
        onHide={() => {
          imgCropper();
          setProfileImageView("");
          setCrop({ x: 0, y: 0 });
          setZoom(1);
          setCroppedAreaPixels(null);
        }}
        backdrop="static"
        className="modalCloseBtn"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("IMANGE_CROP")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="manageCropper">
          <Cropper
            image={profileImageView}
            crop={crop}
            zoom={zoom}
            aspect={1 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className={`bg-mid_background text-white font-medium text-lg ${styles.CropBtn}`}
            onClick={showCroppedImage}
          >
            {t("CROP")}
          </Button>
          <Button
            variant="secondary"
            className={`text-white font-medium text-lg ${styles.closeBtn}`}
            onClick={imgCropper}
          >
            {t("CLOSE")}
          </Button>
        </Modal.Footer>
      </Modal>
      <AddMamberModal
        SetOpenAddMember={SetOpenAddMember}
        openAddMember={openAddMember}
        room_id={details.room_id}
        setMembersList={setMembersList}
        membersList={membersList}
      />
    </section>
  );
}

export default memo(ChatGroupDetailModule);
