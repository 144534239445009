import React, { useEffect } from "react";
import styles from "./EmailVerify.module.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SublyApi from "../../helpers/Api";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";
import { STATUS_CODES } from "../../utils/StatusCode";
import { Toast } from "../../utils/Toster";
import Loader from "../../utils/Loader/Loader";

const EmailVerify = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const params = useParams();
  const [isSuccess, setIsSuccess] = useState();
  const [verifyMsg, setverifyMsg] = useState();

  useEffect(() => {
    verifyEmail();
  }, []);

  // === function for verify email api calling ===
  const verifyEmail = async () => {
    setIsLoader(true);
    let requestData = new FormData();
    requestData.append("token_data", params.emailToken);
    await SublyApi.verifyEmail(requestData).then((responsejson) => {
      if (responsejson.status_code === 200) {
        setIsSuccess(true);
        setverifyMsg(responsejson.message);
        setIsLoader(false);
      } else if (responsejson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        Toast.fire({
          icon: "error",
          title: `${t("SERVER_ERR")}`,
        });
        setIsLoader(false);
      } else {
        setIsSuccess(false);
        setverifyMsg(responsejson.data.message);
        setIsLoader(false);
      }
      setTimeout(() => {
        navigate("/login");
      }, 7000);
    });
  };
  return (
    <>
      {isLoader ? <Loader /> : ""}
      <div>
        <div className={` ${styles.EmailVerifyMsg}`}>
          <div className={isSuccess ? styles.successMsg : styles.errorMsg}>
            {isSuccess ? (
              <div className={styles.checkIcon}>
                <FaRegCheckCircle />
              </div>
            ) : (
              <div className={styles.checkIcon}>
                <FaRegTimesCircle />
              </div>
            )}
            <p>{verifyMsg}</p>
          </div>
        </div>
        <div className={styles.backToBtn}>
          <Link to="/login">
            <button
              className={`w-36 h-10 text-sm bg-sign_button text-white rounded`}
            >
              <p className={`text-white ${styles.backTo}`}>
                {t("BACK_TO_BUTTON")}
              </p>
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default EmailVerify;
