import BottomHeader from "../UserHeaders/BottomHeader";
import TopHeader from "../UserHeaders/TopHeader";
import styles from "./MyNetwork.module.css";
import networkBackground from "../../Assests/Images/network.jpg";
import { Col, Container, Row } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Footer from "../Footer/Footer";
import ProfileSection from "../HomeModule/ProfileSection";
import TrendingSection from "../HomeModule/TrendingSection";
import MyRequests from "./MyRequests/MyRequests";
import MyConnections from "./MyConnections/MyConnections";
import MySuggestions from "./MySuggestions/MySuggestions";
import { useState, useEffect } from "react";
// import SublyApi from "../../helpers/Api";
import { useSelector, useDispatch } from "react-redux";
import { STATUS_CODES } from "../../utils/StatusCode";
import { Toast } from "../../utils/Toster";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import { fetchCountData } from "../../store/Slices/NetworkSlice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MyNetwork() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tab } = useParams();
  const { userToken } = useSelector((state) => state.user);
  const countData = useSelector((state) => state.network.count);
  const [value, setValue] = useState(Number(tab));
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  window.addEventListener("resize", function (event) {
    setScreenWidth(window.innerWidth);
  });

  // === function for handle tabchange data ===
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // === function for calling countData api ===
  useEffect(() => {
    async function fetchData() {
      dispatch(fetchCountData(userToken)).then((responsejson) => {
        const response = responsejson.payload;
        if (response.status_code === STATUS_CODES.SUCCESS) {
        } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: response.message,
          });
        } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
          Toast.fire({
            icon: "error",
            title: response.data.message,
          });
          dispatch(userLogoutClear());
        } else if (response.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        } else {
          Toast.fire({
            icon: "error",
            title: response.message,
          });
        }
      });
    }
    fetchData();
  }, [value]);

  return (
    <>
      <div className={`${styles.myNetworkWrapper} bg-background_color`}>
        <TopHeader />
        <div
          className={`${styles.backgImg} bg-cover w-full bg-center relative`}
          style={{
            backgroundImage: `url(${networkBackground})`,
          }}
        >
          <div className={styles.btmHeader}>
            <BottomHeader />
          </div>
          <p className={styles.myNetworkHeading}>{t("My_Network")}</p>
        </div>
        <div className="mt-8 mx-0">
          <Container>
            <Row>
              <Col lg={3} md={12} sm={12} className="mb-4">
                <Row>
                  <Col lg={12}>
                    {" "}
                    <ProfileSection />
                  </Col>
                  <Col lg={12}>
                    <div className="myNetworkTab">
                      <Tabs
                        orientation={
                          screenWidth > 991 ? "vertical" : "horizonal"
                        }
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: "divider" }}
                      >
                        <Tab
                          label={
                            <div className="tabContent">
                              <span>{t("Requests")} </span>
                              <small>{countData.total_request}</small>
                            </div>
                          }
                          {...a11yProps(0)}
                        />
                        <Tab
                          label={
                            <div className="tabContent">
                              <span>{t("Connections")} </span>
                              <small>{countData.connection_count}</small>
                            </div>
                          }
                          {...a11yProps(1)}
                        />
                        <Tab
                          label={
                            <div className="tabContent">
                              <span>{t("Suggestions")} </span>
                            </div>
                          }
                          {...a11yProps(2)}
                        />
                      </Tabs>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} md={12} sm={12}>
                <TabPanel value={value} index={0}>
                  <MyRequests count={countData.total_request} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <MyConnections count={countData.connection_count} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <MySuggestions />
                </TabPanel>
              </Col>
              <Col lg={3} md={12} sm={12} className="mb-4">
                <TrendingSection isSetting={true} />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="mt-2">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default MyNetwork;
