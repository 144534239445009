import React, { useState, useEffect, memo, useCallback } from "react";
import styles from "./ChatModule.module.css";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import $ from "jquery";
import moment from "moment";
import socket from "../../socket";
import images from "../../Assests/Images/icon.png";
import {
  IS_PRIVATE_CHAT,
  MESSAGE_IS_FILE,
  ROOM_TYPE,
} from "../../utils/Constants";

function ChatList({ isDetail, openGroup, setChatState, chatState }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useSelector((state) => state.user);
  const [getChatHistoryList, setChatHistoryList] = useState([]);
  const [search, setSearch] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [filterData, setFilterData] = useState([]);

  const refrence_id =
    chatState && chatState.refrence_id
      ? chatState.refrence_id
      : location.state && location.state.refrence_id;
  const room_id =
    chatState && chatState.room_id
      ? chatState.room_id
      : location.state && location.state.room_id;
  const is_group_created =
    chatState && chatState.is_group_created
      ? chatState.is_group_created
      : location.state && location.state.is_group_created;

  const [active, setActive] = useState(room_id);
  //   // Slide up displayed paragraphs
  $(document).ready(function () {
    $(".closedNotify").click(function () {
      $("#panelNotify").slideUp();
      setTimeout(() => setSearch(true), 100);
    });

    // Slide down hidden paragraphs
    $(".openedNotify").click(function () {
      $("#flipNotify").slideDown();
      setTimeout(() => setSearch(false), 100);
    });
  });

  // function for manage getChatListHistory listenors
  const getHistoryEventHandler = useCallback((socketData) => {
    setChatHistoryList(socketData);
    setFilterData(socketData);
  });

  // function for handle search in chatList
  useEffect(() => {
    if (searchValue == "") {
      setFilterData(getChatHistoryList);
    } else {
      if (searchValue.trim()) {
        const filterData = getChatHistoryList.filter((item) => {
          return searchValue && item.room_type === ROOM_TYPE.GROUP
            ? item.group_title.toLowerCase().includes(searchValue.toLowerCase())
            : item.refrence_username
                .toLowerCase()
                .includes(searchValue.toLowerCase());
        });
        setFilterData(filterData);
      }
    }
  }, [searchValue]);

  useEffect(() => {
    socket.on("get-history", getHistoryEventHandler);
    return () => socket.off("get-history", getHistoryEventHandler);
  }, [openGroup]);

  // function for calling joinconversion and getHistoryList on pageload
  useEffect(() => {
    joinConversion();
    socket.on("join-conversion-list", (e) => {});
  }, [openGroup]);

  // function for calling join-conversion-list listeners
  function joinConversion() {
    socket.emit("join-conversion-list", { user_id: currentUser.id });
    getHistoryList();
  }

  // function for manage getHistoryList and read-messages emit
  function getHistoryList() {
    socket.emit("get-history", {
      user_id: currentUser.id,
      search: searchValue,
    });
    if (room_id) {
      socket.emit("read-message", {
        user_id: currentUser.id,
        room_id: room_id,
        refrence_id: refrence_id,
        message_id: "",
      });
    }
  }

  // function for open chatDetail on  chat-list click
  function handleChatListClick(item) {
    setActive(item.chat_room_id);
    if (isDetail) {
      setChatState({
        room_id: item.chat_room_id,
        room_type: item.room_type,
        refrence_id: item.refrence_id,
        chatDetails: item,
        is_private_chat: IS_PRIVATE_CHAT.CHAT_LIST_PRIVATE_CONNECTION,
      });
    } else {
      navigate("/chat/chat-details", {
        state: {
          room_id: item.chat_room_id,
          room_type: item.room_type,
          refrence_id: item.refrence_id,
          chatDetails: item,
          is_private_chat: IS_PRIVATE_CHAT.CHAT_LIST_PRIVATE_CONNECTION,
        },
      });
    }
  }

  return (
    <>
      {getChatHistoryList && getChatHistoryList.length > 0 ? (
        <section
          className={`${styles.chatListSectionBox} ${
            isDetail ? styles.hideInResponsive : ""
          }`}
        >
          <div>
            <div
              className={
                isDetail ? styles.listHeadDetail : styles.chatListHeadClass
              }
            >
              <div className={styles.flexClass}>
                <p>{t("CHAT_LIST")}</p>
                <div
                  className={`${search ? "openedNotify" : "closedNotify"} ${
                    styles.searchClass
                  }`}
                >
                  {isDetail ? (
                    <Icon
                      icon="ri:search-2-line"
                      color="#4d4e66"
                      width="26"
                      height="26"
                      cursor={"pointer"}
                    />
                  ) : (
                    <Icon
                      icon="ri:search-2-line"
                      color="white"
                      width="28"
                      height="28"
                      cursor={"pointer"}
                    />
                  )}
                </div>
              </div>
              <div
                id={`${search ? "flipNotify" : "panelNotify"}`}
                className={`${
                  isDetail ? styles.searchClassWhite : styles.searchClass
                }`}
              >
                <input
                  type="text"
                  placeholder={t("SEARCH")}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.chatListBox}>
              {filterData && filterData.length > 0 ? (
                filterData.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      handleChatListClick(item);
                    }}
                    className={`cursor-pointer ${
                      isDetail
                        ? item.unread_count !== 0
                          ? styles.chatListAreaDetail
                          : styles.chatReadAreaDetail
                        : item.unread_count !== 0
                        ? styles.chatListArea
                        : styles.chatReadListArea
                    } ${
                      item.chat_room_id ===
                      (!is_group_created && active ? active : room_id)
                        ? styles.currentChatWindow
                        : ""
                    }`}
                  >
                    <div className={styles.profileArea}>
                      <div className={styles.profileImg}>
                        <img
                          src={
                            item.room_type == ROOM_TYPE.GROUP
                              ? item.group_image
                              : item.refrence_profile_picture_url
                          }
                        />
                      </div>
                      {item.is_file == MESSAGE_IS_FILE.IS_FILE_TEXT && (
                        <div className={styles.userInfo}>
                          <h4>
                            {item.room_type == ROOM_TYPE.GROUP
                              ? item.group_title
                              : item.refrence_username}
                          </h4>
                          <p>{item.last_message}</p>
                        </div>
                      )}

                      {item.is_file == MESSAGE_IS_FILE.IS_FILE_MEDIA && (
                        <div className={styles.userInfo}>
                          <h4>
                            {item.room_type == ROOM_TYPE.GROUP
                              ? item.group_title
                              : item.refrence_username}
                          </h4>
                          <img
                            src={images}
                            alt="Photo"
                            onError={(e) => {
                              e.target.style.display = "none"; // Hide the image
                            }}
                          ></img>
                        </div>
                      )}
                    </div>
                    <div className={`${styles.chatTime}`}>
                      <p>
                        {item.last_message_time &&
                          moment(item.last_message_time).format("hh:mm A")}
                      </p>
                      {item.unread_count !== 0 && (
                        <div>{item.unread_count}</div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className={styles.noChatAvaibleSearch}>
                  <p>{t("NO_SEARCH_AVAILABLE_TEXT")}</p>
                </div>
              )}
            </div>
          </div>
        </section>
      ) : (
        <div
          className={`${styles.noChatAvaible} ${
            isDetail ? styles.hideInResponsive : ""
          }`}
        >
          <p>{t("NO_CHAT_AVAILABLE_TEXT")}</p>
        </div>
      )}
    </>
  );
}

export default memo(ChatList);
