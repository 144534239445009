import { t } from "i18next";

export const BottomHeaderData = [
  {
    id: "1",
    name: `${t("HOME")}`,
    link: "/user-home",
  },
  {
    id: "2",
    name: `${t("MY_NETWORK")}`,
    link: `/my-network/${0}`,
  },
  {
    id: "3",
    name: `${t("CHAT")}`,
    link: "/chat",
  },
];
