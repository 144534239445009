import React, { useEffect } from "react";
import styles from "./AboutPage.module.css";
import { useTranslation } from "react-i18next";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import aboutImg from "../../Assests/Images/aboutUs.webp";
import { Container, Row, Col } from "react-bootstrap";
import rightImg from "../../Assests/Images/Block_combind.webp";
import appStoreImg from "../../Assests/Images/app-store2.webp";
import playStroreImg from "../../Assests/Images/play-store2.webp";
import $ from "jquery";

const AboutPage = () => {
  const { t } = useTranslation();

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);
  return (
    <>
      <Header />
      <div className={`${styles.AboutPageMain}`}>
        <div className="mt-20">
          <img className={`${styles.aboutImg}`} src={aboutImg} alt="aboutImg" />
        </div>
        <div className="mt-4 mb-4">
          <Container>
            <div>
              <p
                className={`text-mid_background font-semibold flex ${styles.aboutMainHeading}`}
              >
                {t("TALENT_PROJECT")}
                <span className={styles.line}></span>
              </p>
            </div>
            <p
              className={`text-mid_background font-bold text-5xl mt-4 ${styles.aboutHeading}`}
            >
              {t("COLLABORATION_NETWORKING")}
            </p>
            <p className={`mt-4 ${styles.aboutPara}`}>
              {t("ABOUT_PARAGRAPH_TEXT")}
            </p>
            <div className={`pl-4	${styles.listItems}`}>
              <ul className={styles.items}>
                <li>{t("LIST_ITEM_ONE")}</li>
                <li>{t("LIST_ITEM_TWO")}</li>
                <li>{t("LIST_ITEM_THREE")}</li>
                <li>{t("LIST_ITEM_FOUR")}</li>
                <li>{t("LIST_ITEM_FIVE")}</li>
              </ul>
            </div>
          </Container>
        </div>
        <div className="pt-12 mb-2 bg-mid_background">
          <Container>
            <Row>
              <Col lg={6}>
                <div className="pl-16 pr-7">
                  <p
                    className={`text-white sm:text-2xl md:text-2xl lg:text-3xl lgg:text-3xl text-5xl sm:text-center leading-10 font-bold ${styles.youCan}`}
                  >
                    {t("HOME_MID_HEADING")}
                  </p>
                  <p className="text-mid_text3 font-normal leading-8 font-sans-serif">
                    {t("HOME_MID_TEXT3")}
                  </p>
                  <div className="flex lg:pl-14">
                    <img
                      className={styles.storeIMg}
                      src={appStoreImg}
                      alt="app-store"
                    />
                    <img src={playStroreImg} alt="play-store" />
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <img
                    className="2xl:pl-28 xl:pl-28 lgg:pl-28 lg:pl-28 md:pl-14"
                    src={rightImg}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
