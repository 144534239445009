import React, { useState } from "react";
import styles from "./Setting.module.css";
import lock from "../../Assests/Images/lock.svg";
import notification from "../../Assests/Images/notification.svg";
import suggestion from "../../Assests/Images/suggetion.svg";
import question from "../../Assests/Images/notification.svg";
import { useTranslation } from "react-i18next";
import "react-toggle/style.css";
import Toggle from "react-toggle";
import SublyApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import {
  handleNotificationKey,
  userLogoutClear,
} from "../../store/Slices/UserSlice";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";
import { STATUS_CODES } from "../../utils/StatusCode";
import { Toast } from "../../utils/Toster";
import Loader from "../../utils/Loader/Loader";
import NeedHelp from "./NeedHelp";
import PasswordChange from "./PasswordChange";
import NotificationModal from "./NotificationModal";
import { Icon } from "@iconify/react";
import DeleteAccount from "./DeleteAccount";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";

function SettingModule() {
  const { t } = useTranslation();
  const [suggestionValue, setSuggestionValue] = useState("");
  const { userToken, currentUser } = useSelector((state) => state.user);
  const [isLoader, setIsLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showError, setShowError] = useState("");
  const [openPassword, setOpenPassword] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const dispatch = useDispatch();

  // =======clear redux========
  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  // ===========Here calling API for suggestion of user=============
  async function suggestionHandle() {
    if (suggestionValue && !showError) {
      setIsLoader(true);
      const requestData = new FormData();
      requestData.append("suggestion_description", suggestionValue);
      await SublyApi.saveSuggestion(userToken, requestData).then((response) => {
        if (response.status_code === STATUS_CODES.SUCCESS) {
          setIsLoader(false);
          setSuggestionValue("");
          Toast.fire({
            icon: "success",
            title: response.message,
          });
        } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: response.message,
          });
          setIsLoader(false);
        } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
          Toast.fire({
            icon: "error",
            title: response.data.message,
          });
          setIsLoader(false);
          logOutDataClear();
        } else if (response.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
          setIsLoader(false);
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.message,
          });
          setIsLoader(false);
        }
      });
    }
  }

  // ===========Here calling API for notification status update=============
  async function updateNotification(action) {
    // setIsLoader(true);
    const requestData = new FormData();
    requestData.append("notification_enable", action);
    await SublyApi.notificationStatus(userToken, requestData).then(
      (response) => {
        if (response.status_code === STATUS_CODES.SUCCESS) {
          setIsLoader(false);
          const notificationKey = [currentUser];
          notificationKey[0] = {
            ...notificationKey[0],
            notification_enable: action,
          };
          dispatch(handleNotificationKey({ currentUser: notificationKey[0] }));
        } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: response.message,
          });
          setIsLoader(false);
        } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
          Toast.fire({
            icon: "error",
            title: response.data.message,
          });
          setIsLoader(false);
          logOutDataClear();
        } else if (response.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
          setIsLoader(false);
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.message,
          });
          setIsLoader(false);
        }
      }
    );
  }
  return (
    <section className={styles.settingSection}>
      {isLoader ? <Loader /> : ""}
      <div className={styles.settingUpperClass}>
        <div className={`${styles.notificationFlexClass} pb-7`}>
          <div className={styles.flexBoxClass}>
            <div
              className={`cursor-pointer ${styles.imageBoxClass}`}
              onClick={() => {
                setOpenNotification(true);
              }}
            >
              <img src={notification} />
            </div>
            <p
              onClick={() => {
                setOpenNotification(true);
              }}
              className="cursor-pointer"
            >
              {t("NOTIFICATION")}
            </p>
          </div>
          <div className="toggleClass">
            <Toggle
              aria-label="No label tag"
              defaultChecked={currentUser.notification_enable}
              onChange={(e) => {
                updateNotification(e.target.checked == true ? 1 : 0);
              }}
            />
          </div>
        </div>
        <div className={`${styles.flexBoxClass} pb-7`}>
          <div
            className={`${styles.imageBoxClass} cursor-pointer`}
            onClick={() => setOpenPassword(true)}
          >
            <img src={lock} />
          </div>
          <p onClick={() => setOpenPassword(true)} className="cursor-pointer">
            {t("CHANGE_PASSWORD")}
          </p>
        </div>
        <div className={`${styles.flexBoxClass} pb-7`}>
          <div
            className={`${styles.imageBoxClass} cursor-pointer`}
            onClick={() => setOpenModal(true)}
          >
            <img src={question} />
          </div>
          <p onClick={() => setOpenModal(true)} className="cursor-pointer">
            {t("NEED_HELP")}
          </p>
        </div>
        <div className={`${styles.flexBoxClass}`}>
          <div
            className={`${styles.imageBoxClass} cursor-pointer`}
            onClick={() => setOpenDelete(true)}
          >
            <Icon
              icon="fluent:delete-48-filled"
              color="#0A66C2"
              width="30"
              height="50"
            />
          </div>
          <p
            onClick={() => setOpenDelete(true)}
            className={`cursor-pointer ${styles.dlt}`}
          >
            {t("DELETE_ACCOUNT")}
          </p>
        </div>
      </div>
      <div className={styles.settingLowerClass}>
        <div className={`${styles.flexBoxClass} pb-6`}>
          <div>
            <img src={suggestion} />
          </div>
          <p>{t("SUGGESTION")}</p>
        </div>
        <div className={styles.suggestionInput}>
          <textarea
            placeholder={t("WHATS_YOUR_MIND")}
            value={suggestionValue}
            onKeyUp={(e) => sanitizeHtmlTags().validate(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode == 32 && !e.target.value.length) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              setSuggestionValue(e.target.value);
              // Check if the value contains HTML tags
              if (/<(\/)?[a-z][\s\S]*>/i.test(e.target.value)) {
                setShowError("HTML tags are not allowed.");
              } else {
                // Value is valid, proceed with your logic
                setShowError("");
                if (
                  e.target.value &&
                  (e.target.value.trim().length > 1000 ||
                    e.target.value.trim().length < 5)
                ) {
                  setShowError(
                    "Text should be less than or equal to 1000 characters and more than or equal to 5 characters"
                  );
                } else {
                  setShowError("");
                }
              }
            }}
          />
          <span className="text-sm text-red-600">{showError}</span>
        </div>
        <div className={styles.submitBtnClass}>
          <button
            className={`bg-mid_background`}
            disabled={suggestionValue && !showError ? false : true}
            onClick={() => {
              suggestionHandle();
            }}
          >
            {t("SUBMIT")}
          </button>
        </div>
      </div>
      <NeedHelp
        isLoader={isLoader}
        setOpenModal={setOpenModal}
        openModal={openModal}
        setIsLoader={setIsLoader}
      />
      <PasswordChange
        isLoader={isLoader}
        setOpenModal={setOpenPassword}
        openModal={openPassword}
        setIsLoader={setIsLoader}
      />
      <NotificationModal
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
        setIsLoader={setIsLoader}
        isLoader={isLoader}
      />
      <DeleteAccount
        isLoader={isLoader}
        setOpenDelete={setOpenDelete}
        openDelete={openDelete}
        setIsLoader={setIsLoader}
      />
    </section>
  );
}

export default SettingModule;
