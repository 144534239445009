import { t } from "i18next";

export const HeaderData = [
  {
    id: "1",
    name: `${t("HOME")}`,
    link: "/",
  },
  {
    id: "2",
    name: `${t("SERVICES")}`,
    link: "javascript:;",
  },
  {
    id: "3",
    name: `${t("ABOUT_US")}`,
    link: "/about-us",
  },
  {
    id: "4",
    name: `${t("CONTACT_US")}`,
    link: "javascript:;",
  },
];
