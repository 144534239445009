import React, { useEffect, useState } from "react";
import styles from "./Setting.module.css";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import SublyApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";
import { Toast } from "../../utils/Toster";
import { STATUS_CODES } from "../../utils/StatusCode";
import Loader from "../../utils/Loader/Loader";
import moment from "moment";
import { REQUEST_ACTION_TYPE } from "../../utils/Constants";
import { useNavigate } from "react-router-dom";

function NotificationModal({
  setOpenNotification,
  openNotification,
  isLoader,
  setIsLoader,
}) {
  const { t } = useTranslation();
  const { userToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notificationData, setNotificationData] = useState("");

  // =======clear redux========
  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  //   ===========Here calling API for fetching notifictaion list============
  async function fetchNotificationList() {
    await SublyApi.notificationList(userToken).then((response) => {
      if (response.status_code === STATUS_CODES.SUCCESS) {
        setIsLoader(false);
        setNotificationData(response.data);
      } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: response.message,
        });
        setIsLoader(false);
      } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        setIsLoader(false);
        logOutDataClear();
      } else if (response.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        setIsLoader(false);
      } else {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        setIsLoader(false);
      }
    });
  }

  useEffect(() => {
    fetchNotificationList();
  }, [openNotification]);

  //   ============Here calling API for read notifictaion status=============
  async function notificationHandle(id, index) {
    setIsLoader(true);
    const requestData = new FormData();
    requestData.append("notificationId", id);
    await SublyApi.readNotification(userToken, requestData).then((response) => {
      if (response.status_code === STATUS_CODES.SUCCESS) {
        setIsLoader(false);
        const notificationValue = [...notificationData];
        notificationValue[index] = {
          ...notificationValue[index],
          read_status: 1,
        };
        setNotificationData(notificationValue);
      } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: response.message,
        });
        setIsLoader(false);
      } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        setIsLoader(false);
        logOutDataClear();
      } else if (response.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        setIsLoader(false);
      } else {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        setIsLoader(false);
      }
    });
  }

  //   ==========Here calling API for accept and decline connection request============
  const RequestHandler = async (userData, isAccepted, index) => {
    const requestData = new FormData();
    requestData.append("request_action", isAccepted);
    requestData.append("request_by", userData.request_by);
    requestData.append("request_id", userData.request_id);
    await SublyApi.requestConnectionAction(userToken, requestData).then(
      (responsjson) => {
        if (responsjson.status_code === STATUS_CODES.SUCCESS) {
          Toast.fire({
            icon: "success",
            title: responsjson.message,
          });
          const notificationValue = [...notificationData];
          notificationValue[index] = {
            ...notificationValue[index],
            is_request_pending: 0,
          };
          setNotificationData(notificationValue);
        } else if (responsjson.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: responsjson.data.message,
          });
        } else if (
          responsjson.data.status_code === STATUS_CODES.INVALID_TOKEN
        ) {
          Toast.fire({
            icon: "error",
            title: responsjson.data.message,
          });
          dispatch(userLogoutClear());
        } else if (responsjson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
          Toast.fire({
            icon: "error",
            title: `${t("SERVER_ERR")}`,
          });
        } else {
          Toast.fire({
            icon: "error",
            title: responsjson.data.message,
          });
        }
      }
    );
  };
  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <Modal
        show={openNotification}
        onHide={() => {
          setOpenNotification(false);
        }}
        className="modalCloseBtn"
        backdrop="static"
        keyboard="false"
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles.modalTitle}>
            {t("NOTIFICATION")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.NotificationBody}>
          {notificationData
            ? notificationData.map((item, index) => (
              <div
                key={index}
                className={` ${styles.notificationItem} ${item.read_status == 0 ? styles.backColor : ""
                  }`}
              >
                <div
                  className="flex items-flex-start gap-3.5 cursor-pointer"
                  onClick={() => {
                    if (
                      item.is_request_pending !== 1 &&
                      item.read_status == 0
                    ) {
                      notificationHandle(item.id, index);
                    }
                    if (item.notification_type == "post_liked") {
                      navigate(`/post-detail/${item.reference_id}`);
                    } else {
                      if (item.notification_type == "send_request") {
                        navigate(
                          `/my-network/profile-detail/${item.request_by}`
                        );
                        setOpenNotification(false);
                      }
                      else if (item.notification_type == "accept_connection_request" ||
                        item.notification_type == "decline_connection_request") {
                        navigate(
                          `/my-network/profile-detail/${item.reference_id}`
                        );
                        setOpenNotification(false);
                      }
                      else if (item.notification_type == "start_follow") {
                        navigate(
                          `/my-network/profile-detail/${item.reference_id}`
                        );
                        setOpenNotification(false);
                      }
                    }
                  }}
                >
                  <div
                    className={styles.profileNotify}
                    onClick={() => {
                      if (item.read_status == 0) {
                        notificationHandle(item.id, index);
                      }
                    }}
                  >
                    <img src={item.profile_img} />
                  </div>
                  <div className={`w-4/5 ${styles.userAction}`}>
                    <div
                      onClick={() => {
                        if (item.read_status == 0) {
                          notificationHandle(item.id, index);
                        }
                      }}
                    >
                      {item.description.includes(item.highlight_name) ?
                        <>
                          <h6>
                            <span>
                              {item.description.includes(item.highlight_name) ? (
                                item.description.split(item.highlight_name).map((text, index) => (
                                  <div key={index}>
                                    {index > 0 && <span style={{ color: '#202020', fontSize: '17px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal', }}>{item.highlight_name}</span>}
                                    {text}
                                  </div>
                                ))
                              ) : (
                                item.description
                              )}
                            </span>
                          </h6>
                        </> : <>
                          <h6>
                            {item.highlight_name} <span>{item.description}</span>
                          </h6>
                        </>
                      }
                      <p>{moment(item.notification_time).fromNow()}</p>
                    </div>
                  </div>
                </div>
                {item.is_request_pending == 1 ? (
                  <div className={styles.requestBtns}>
                    <button
                      className={styles.acceptBtn}
                      onClick={() => {
                        if (item.read_status == 0) {
                          notificationHandle(item.id, index);
                        }
                        RequestHandler(
                          item,
                          REQUEST_ACTION_TYPE.REQUEST_ACCEPT,
                          index
                        );
                      }}
                    >
                      {t("Accept")}
                    </button>
                    <button
                      className={styles.declineBtn}
                      onClick={() => {
                        if (item.read_status == 0) {
                          notificationHandle(item.id, index);
                        }
                        RequestHandler(
                          item,
                          REQUEST_ACTION_TYPE.REQUEST_DECLINE,
                          index
                        );
                      }}
                    >
                      {t("Decline")}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))
            : ""}
          {notificationData && notificationData.length > 0 ? (
            ""
          ) : (
            <p className="text-center my-4">{t("No_Data")}</p>
          )}
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default NotificationModal;
