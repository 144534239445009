import { useEffect, useState } from "react";
import styles from "../../MyNetwork.module.css";
import { Container, Col, Row, Form, Dropdown } from "react-bootstrap";
import Footer from "../../../Footer/Footer";
import TopHeader from "../../../UserHeaders/TopHeader";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../../../utils/StatusCode";
import { Toast } from "../../../../utils/Toster";
import { userLogoutClear } from "../../../../store/Slices/UserSlice";
import SublyApi from "../../../../helpers/Api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import ProfileSection from "../../../HomeModule/ProfileSection";
import TrendingSection from "../../../HomeModule/TrendingSection";
import { BiArrowBack } from "react-icons/bi";
import { userProfileClear } from "../../../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../../../store/Slices/NetworkSlice";
import { useParams } from "react-router-dom";
import Loader from "../../../../utils/Loader/Loader";
import { FaCircleMinus, FaEllipsis, FaCirclePlus } from "react-icons/fa6";

function UserFollowers() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetail = useParams();
  const { userToken } = useSelector((state) => state.user);
  const [followerList, setFollwerList] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isFriend, setIsFriend] = useState(false);

  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  // ====function for fetch followerList====
  async function fetchFollowerList(isSearch, event) {
    var search = isSearch ? event.target.value : "";
    const requestData = new FormData();
    requestData.append("user_id", userDetail.id);
    requestData.append("search", search ? search : "");
    setIsLoader(true);
    await SublyApi.followerList(userToken, requestData).then((responsejson) => {
      if (responsejson.status_code === 200) {
        setFollwerList(responsejson.data);
        setIsLoader(false);
      } else if (responsejson.data.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: responsejson.data.message,
        });
        logOutDataClear();
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.data.message,
        });
        setIsLoader(false);
      }
    });
  }

  useEffect(() => {
    fetchFollowerList(false);
  }, [userDetail.id, isFriend]);

  //====== function to send follow request ======
  async function followHandler(id) {
    const requestData = new FormData();
    requestData.append("follow_to", id);
    await SublyApi.followUser(userToken, requestData).then((response) => {
      const responsejson = response.data;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        setIsFriend(!isFriend);
      } else if (responsejson.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      } else if (responsejson.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
        dispatch(userLogoutClear());
      } else if (responsejson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        Toast.fire({
          icon: "error",
          title: `${t("SERVER_ERR")}`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      }
    });
  }

  //====== function to unfollow user======
  async function unfollowHandler(id) {
    const requestData = new FormData();
    requestData.append("follow_to", id);
    await SublyApi.unfollowUser(userToken, requestData).then((response) => {
      const responsejson = response.data;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        setIsFriend(!isFriend);
      } else if (responsejson.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      } else if (responsejson.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
        dispatch(userLogoutClear());
      } else if (responsejson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        Toast.fire({
          icon: "error",
          title: `${t("SERVER_ERR")}`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      }
    });
  }

  return (
    <>
      {isLoader ? <Loader /> : ""}
      <div className="profilDetailWrapper">
        <TopHeader />
        <div className="mt-6 mx-0">
          <Container>
            <Row>
              <Col lg={3} md={2} sm={3} xs={3}>
                <div
                  onClick={() => {
                    navigate(-1);
                  }}
                  className={`${styles.followBackBtn} cursor-pointer w-fit flex items-center`}
                >
                  <BiArrowBack />
                  <span className="xs:text-sm text-lg text-center text-black font-medium">
                    {t("BACK")}
                  </span>
                </div>
              </Col>
              <Col lg={6} md={10} sm={9} xs={9}>
                <Form.Group className="mb-3 searchShow">
                  <Form.Control
                    type="text"
                    className="h-12"
                    placeholder="Search"
                    onKeyUp={(e) => fetchFollowerList(true, e)}
                    id="search"
                  />
                </Form.Group>
              </Col>
              <Col lg={3} md={1} sm={1} xs={1}></Col>
            </Row>
          </Container>
        </div>
        <div className="mt-3.5 mb-8 mx-0">
          <Container>
            <Row>
              <Col lg={3} md={12} sm={12} className="mb-4">
                <Row>
                  <Col lg={12}>
                    {" "}
                    <ProfileSection />
                  </Col>
                </Row>
              </Col>
              <Col lg={6} md={12} sm={12}>
                <div className="searchHideResponsive">
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      className="h-12"
                      placeholder="Search"
                      onKeyUp={(e) => fetchFollowerList(true, e)}
                      id="search"
                    />
                  </Form.Group>
                </div>
                <div className={`${styles.mySuggestionsData} mb-6  bg-white`}>
                  <h3 className="text-left font-semibold mb-3.5 tracking-norma">
                    {t("MY_FOLLOWERS")}
                  </h3>
                  <Row>
                    {followerList && followerList.length ? (
                      followerList.map((follow, index) => (
                        <Col key={index} lg={12} sm={12}>
                          <div className={`${styles.suggestionsList}`}>
                            <div
                              onClick={() =>
                                navigate(
                                  `/my-network/profile-detail/${follow.follow_by}`
                                )
                              }
                              className={`${styles.userInfo} cursor-pointer`}
                            >
                              <span className={styles.requestImg}>
                                <img src={follow.profile_img} alt="img" />
                              </span>
                              <div className={styles.requestDetail}>
                                <p>{follow.fullname}</p>
                                <span> {follow.current_designation}</span>
                              </div>
                            </div>

                            <div className={`userActionBtn connectionsList`}>
                              <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                  <FaEllipsis
                                    className={`text-xl text-slate-700`}
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {follow.is_following == 0 ? (
                                    <Dropdown.Item
                                      as="button"
                                      onClick={() =>
                                        followHandler(follow.follow_by)
                                      }
                                    >
                                      <FaCirclePlus />
                                      {t("FOLLOW")}
                                    </Dropdown.Item>
                                  ) : (
                                    <Dropdown.Item
                                      as="button"
                                      onClick={() =>
                                        unfollowHandler(follow.follow_by)
                                      }
                                    >
                                      <FaCircleMinus />
                                      {t("UNFOLLOW")}
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </Col>
                      ))
                    ) : (
                      <span className="text-center">{t("NO_FOLLOWER")}</span>
                    )}
                  </Row>
                  {followerList && followerList.length > 10 ? (
                    <div className={`${styles.seeMoreBtn} text-center mb-1`}>
                      <span className="cursor-pointer">{t("SEE_MORE")}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col lg={3} md={12} sm={12} className="mb-4">
                <TrendingSection />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="mt-8">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default UserFollowers;
