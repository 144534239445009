import React from "react";
import styles from "./BottomHeader.module.css";
import logout from "../../Assests/Images/logout.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";
import { userMediaClear } from "../../store/Slices/MediaSlice";
import { Icon } from "@iconify/react";
import { NavLink, useNavigate } from "react-router-dom";

function ProfileModal() {
  const { t } = useTranslation();
  const { profileDetail } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const nevigate = useNavigate();

  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  return (
    <section className={styles.ProfileModalBody}>
      <div className={styles.profileSection}>
        <div className="flex items-center">
          <div className={styles.userIcon}>
            <img
              src={profileDetail.profile_img}
              alt="profile"
              className="mr-3"
            />
          </div>
          <div className={styles.userDetail}>
            <h2>{profileDetail.fullname}</h2>
            <p>{profileDetail.current_designation}</p>
          </div>
        </div>
        <div className="text-center pt-2 pb-2">
          <NavLink to="/user-profile">{t("VIEW_PROFILE")}</NavLink>
        </div>
      </div>
      <div className={`${styles.accountSection}`}>
        <div
          className={`${styles.setting} flex items-center gap-3 cursor-pointer pt-2`}
          onClick={() => {
            nevigate("/settings");
          }}
        >
          <Icon
            icon="material-symbols:settings"
            color="#4d4e66"
            width="20"
            height="20"
          />
          <p>{t("SETTING")}</p>
        </div>
        <div
          className={`${styles.logout} flex items-center gap-3 cursor-pointer pt-1.5`}
          onClick={() => {
            logOutDataClear();
          }}
        >
          <img src={logout} alt="copy" />
          <p>{t("LOGOUT")}</p>
        </div>
      </div>
    </section>
  );
}

export default ProfileModal;
