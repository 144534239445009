import { useEffect, useState, useRef } from "react";
import styles from "../MyNetwork.module.css";
import { Col, Row } from "react-bootstrap";
import {
  fetchCountData,
  requestList,
} from "../../../store/Slices/NetworkSlice";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { Toast } from "../../../utils/Toster";
import { userLogoutClear } from "../../../store/Slices/UserSlice";
import SublyApi from "../../../helpers/Api";
import { REQUEST_ACTION_TYPE } from "../../../utils/Constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

function MyRequests({ count }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.user);
  const requests = useSelector((state) => state.network.requests);
  const [isListUpdate, setIsListUpdate] = useState(false);
  const listRef = useRef(null);

  // function for fetch requestList
  useEffect(() => {
    async function fetchRequestList() {
      dispatch(requestList(userToken)).then((responsejson) => {
        const response = responsejson.payload;
        if (response.status_code === STATUS_CODES.SUCCESS) {
        } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: response.message,
          });
        } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
          Toast.fire({
            icon: "error",
            title: response.data.message,
          });
          dispatch(userLogoutClear());
        } else if (response.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        } else {
          Toast.fire({
            icon: "error",
            title: response.message,
          });
        }
      });
    }
    fetchRequestList();
  }, [isListUpdate]);

  // function for handle request
  const RequestHandler = async (userData, isAccepted) => {
    const requestData = new FormData();
    requestData.append("request_action", isAccepted);
    requestData.append("request_by", userData.request_by);
    requestData.append("request_id", userData.id);
    await SublyApi.requestConnectionAction(userToken, requestData).then(
      (responsjson) => {
        if (responsjson.status_code === STATUS_CODES.SUCCESS) {
          dispatch(fetchCountData(userToken));
          setIsListUpdate(!isListUpdate);
        } else if (responsjson.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: responsjson.message,
          });
        } else if (
          responsjson.data.status_code === STATUS_CODES.INVALID_TOKEN
        ) {
          Toast.fire({
            icon: "error",
            title: responsjson.data.message,
          });
          dispatch(userLogoutClear());
        } else if (responsjson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
          Toast.fire({
            icon: "error",
            title: `${t("SERVER_ERR")}`,
          });
        } else {
          Toast.fire({
            icon: "error",
            title: responsjson.message,
          });
        }
      }
    );
  };

  return (
    <>
      <div
        className={`${styles.myRequestData} bg-bg_colour mb-6 requestWrapper`}
      >
        <h3 className="text-left font-semibold tracking-norma">
          {t("Requests")} ({count})
        </h3>
        <Row>
          {requests.length > 0 ? (
            requests.map((item, index) => (
              <Col ref={listRef} lg={6} md={4} sm={6} xs={6} key={index}>
                <div className={`${styles.requestList} p-2.5 `}>
                  <div
                    className={styles.requestDetailWrapper}
                    onClick={() =>
                      navigate(`/my-network/profile-detail/${item.request_by}`)
                    }
                  >
                    <span className={styles.requestImg}>
                      <img src={item.profile_img} alt={item.fullname} />
                    </span>
                    <div className={styles.requestDetail}>
                      <p>{item.fullname}</p>
                      <span>{item.current_designation}</span>
                    </div>
                  </div>
                  <div className={styles.requestBtns}>
                    <button
                      onClick={() => {
                        RequestHandler(
                          item,
                          REQUEST_ACTION_TYPE.REQUEST_ACCEPT
                        );
                      }}
                      className={styles.acceptBtn}
                    >
                      {t("Accept")}
                    </button>
                    <button
                      onClick={() => {
                        RequestHandler(
                          item,
                          REQUEST_ACTION_TYPE.REQUEST_DECLINE
                        );
                      }}
                      className={styles.declineBtn}
                    >
                      {t("Decline")}
                    </button>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <span className={`${styles.noData} text-center mt-2`}>
              {t("No_Request")}
            </span>
          )}
        </Row>
      </div>
    </>
  );
}

export default MyRequests;
