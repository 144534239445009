import React, { useState, useEffect, memo, useRef, useCallback } from "react";
import styles from "./ChatModule.module.css";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import read from "../../Assests/Images/read.svg";
import three_dot from "../../Assests/Images/three_dot.svg";
import { useNavigate } from "react-router-dom";
import EmojiPicker from "../../commonComponent/EmojiPicker";
import $ from "jquery";
import emoji from "../../Assests/Images/emoji.svg";
import gallary from "../../Assests/Images/gallary.svg";
import TextareaAutosize from "react-textarea-autosize";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/dist";
import moment from "moment";
import SublyApi from "../../helpers/Api";
import socket from "../../socket";
import ChatLoader from "../../utils/Loader/ChatLoader";
import { debounce } from "lodash";
import {
  IS_BLOCKED,
  IS_PRIVATE_CHAT,
  MESSAGE_FILE_TYPE,
  MESSAGE_IS_FILE,
  MESSAGE_TYPE,
  ROOM_TYPE,
} from "../../utils/Constants";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// Tooltip button for show message date
const BlueOnGreenTooltip = withStyles({
  tooltip: {
    color: "#000",
    backgroundColor: "#f3e7e7",
    fontSize: "15px",
    fontWeight: "500",
    padding: "10px 10px",
    borderRadius: "8px",
    marginTop: "30px",
    sx: {
      bgcolor: "#22b1a3a8",
      "& .MuiTooltip-arrow": {
        color: "#22b1a3a8",
      },
    },
  },
})(Tooltip);

function ChatDetailsModule({ chatState, setChatState }) {
  const { userToken } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(false);
  const [typingUser, setTypingUser] = useState({});
  const [getGroupDetail, setGroupDetail] = useState([]);
  const [chatDetailHistory, setChatDetailHistory] = useState([]);
  const { currentUser } = useSelector((state) => state.user);
  const [openEmoji, setOpenEmoji] = useState(false);
  const [chatValue, setChatValue] = useState("");
  const [blockStatus, setBlockStatus] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loadMoreOn, setLoadMoreOn] = useState(false);
  const [loadMoreScroll, setLoadMoreScroll] = useState(0);

  // Add a useRef to open chat-list on bottom
  const chatListRef = useRef(null);

  // Add a useRef to store the debounce function for prevent typing function called multiple times
  const debounceTyping = useRef(debounce(() => {}, 500));

  // Add a useRef to delet selected textsocketData
  const textareaRef = useRef(null);

  const room_id = chatState.room_id
    ? chatState.room_id
    : location.state && location.state.room_id;

  const chat_detail = chatState.chatDetails
    ? chatState.chatDetails
    : location.state && location.state.chatDetails;

  const refrence_id = chatState.refrence_id
    ? chatState.refrence_id
    : location.state && location.state.refrence_id;

  const room_type = chatState.room_type
    ? chatState.room_type
    : location.state && location.state.room_type;

  const is_private_chat = chatState.is_private_chat
    ? chatState.is_private_chat
    : location.state && location.state.is_private_chat;

  const clearHistoryHandler = useCallback((socketData) => {});

  useEffect(() => {
    socket.on("close-delete", clearHistoryHandler);
    return () => socket.off("close-delete", clearHistoryHandler);
  }, [room_id]);

  const clearChatHistory = () => {
    socket.emit("close-delete", {
      user_id: currentUser.id,
      room_id: room_id,
      refrence_id: refrence_id,
    });
  };

  /* ----Function to load more messages when scrolling to the top----
----Call get-chat-detail listeners emit  when scrolling to the top----
----Show Loader when scroll to the top and load-more messages----  */
  const handleScroll = (e) => {
    if (
      chatDetailHistory.length > 0 &&
      e.target.scrollTop === 0 &&
      loadMoreOn == true
    ) {
      // prevent load more called when future data available
      if (loadMoreOn == true) {
        setLoadMoreScroll(e.target.scrollHeight);
        setShowLoader(true);
        socket.emit("get-chat-detail", {
          user_id: currentUser.id,
          room_id: room_id,
          refrence_id: refrence_id,
          loadmore: 1,
          limit: limit,
          offset: offset + limit,
        });
        setTimeout(() => {
          setOffset((prevOffset) => prevOffset + limit);
        }, 0);
      }
    }
  };

  /* ---Function for scrolling down chat list---
   ---Adjusting the scroll position using scrollHeight----  */
  const scrollToBottom = () => {
    if (chatListRef.current) {
      let previousScrollheight =
        chatListRef.current.scrollHeight - loadMoreScroll;
      chatListRef.current.scrollTop = previousScrollheight;
    }
  };

  /* ---useEffect to scroll to the bottom when chatDetailHistory changes--- 
    ---Hide the loader when new messages are loaded--- */
  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 500);
    scrollToBottom();
  }, [chatDetailHistory]);

  // function for open clear chatHistory option
  const handleImageClick = () => {
    setShowOptions(!showOptions);
  };

  /* === function for sending images in chat section ===
    === Call api for upload media in chat ===  */
  const uploadImage = async (img) => {
    let requestData = new FormData();
    requestData.append("file", img);
    await SublyApi.sendMedia(userToken, requestData).then((responsejson) => {
      if (responsejson.status_code === 200) {
        socket.emit("message", {
          user_id: currentUser.id,
          room_id: room_id,
          chat_room_id: room_id,
          refrence_id: refrence_id,
          message: "",
          room_type: room_type,
          mesage_type: MESSAGE_TYPE.NORMAL_MESSAGE,
          is_file: MESSAGE_IS_FILE.IS_FILE_MEDIA,
          file_type: MESSAGE_FILE_TYPE.IMAGE,
          file_url: responsejson.data,
          media_timing: "",
        });
      }
    });
  };

  // ====function to hide box on click outside====
  $(document).mouseup(function (e) {
    if ($(e.target).closest(".emojiCommentClass").length === 0) {
      setOpenEmoji(false);
    }
    //Hide clear chat option box on outside click
    if ($(e.target).closest(".openBoxClass").length === 0) {
      setShowOptions(false);
    }
  });

  // ====function to select images in folder for  send images in chat ====
  const handleImageChange = (event) => {
    const file = event.target.files[0]; // Get the first file from the input
    if (file) {
      uploadImage(file);
    }
  };

  // function for handle message response
  const messageResponseEventHandler = useCallback((socketData) => {
    setChatValue("");
    if (room_id == socketData.chat_room_id) {
      setChatDetailHistory((previous) => [...previous, socketData]);
      socket.emit("read-message", {
        user_id: currentUser.id,
        room_id: room_id,
        refrence_id: refrence_id,
        message_id: "",
      });
    }
  });

  // function for handle message socket on and off
  useEffect(() => {
    socket.on("message", messageResponseEventHandler);
    return () => socket.off("message", messageResponseEventHandler);
  }, [room_id]);

  // function for handle get chat detail
  const getChatDetailEventHandler = useCallback((socketData) => {
    if (socketData.blockStatus) {
      setBlockStatus(socketData.blockStatus);
    }
    if (socketData.data.length < 10) {
      // stop load more if no data available
      setLoadMoreOn(false);
    }
    // check loadmore method is called then merge previous and new data
    if (socketData.requestData.loadmore == 1) {
      setChatDetailHistory((prevMessages) => [
        ...socketData.data.reverse(),
        ...prevMessages,
      ]);
    } else {
      // set chat messages detail with new data
      setChatDetailHistory(socketData.data.reverse());
    }
  });

  // function for handle get chat detail socket on and off
  useEffect(() => {
    socket.on("get-chat-detail", getChatDetailEventHandler);
    return () => socket.off("get-chat-detail", getChatDetailEventHandler);
  }, [room_id]);

  // function for handle get group detail
  const getGroupDetailEventHandler = useCallback((socketData) => {
    setGroupDetail(socketData);
  });

  // function for handle message socket on and off
  useEffect(() => {
    socket.on("get-group-detail", getGroupDetailEventHandler);
    return () => socket.off("get-group-detail", getGroupDetailEventHandler);
  }, [room_id]);

  /* === function for manage private chat ===
  === Call join-room emit and get-user-detail emit for manage privatechat ===  */
  function getPrivateChatDetail() {
    socket.emit("join-room", {
      user_id: currentUser.id,
      refrence_id: refrence_id,
      room_type: room_type,
    });
    socket.emit("get-user-detail", {
      user_id: currentUser.id,
      refrence_id: refrence_id,
      room_type: room_type,
    });
    socket.emit("online-offline", {
      user_id: currentUser.id,
      room_type: room_type,
      refrence_id: refrence_id,
    });
  }

  /* === function for calling private chat dteail === */
  const getPrivateChatEventHandler = useCallback(async (socketData) => {
    location.state.room_id = socketData;
    setChatDetailHistory([]);
    socket.emit("get-chat-detail", {
      user_id: currentUser.id,
      room_id: socketData,
      refrence_id: refrence_id,
      loadmore: 0,
      limit: limit,
      offset: offset,
    });
  });

  // is_private_chat
  useEffect(() => {
    socket.on("join-room", getPrivateChatEventHandler);
    return () => socket.off("join-room", getPrivateChatEventHandler);
  }, [room_id]);

  // function for call get-userDetail listeners
  const getUserDetailEventHandler = useCallback(async (socketData) => {
    // console.log("get-user-detail on called", socketData);
  });

  useEffect(() => {
    socket.on("get-user-detail", getUserDetailEventHandler);
    return () => socket.off("get-user-detail", getUserDetailEventHandler);
  }, [room_id]);

  // call function on page load
  useEffect(() => {
    // if (chatState.room_id) {
    //   location.state.room_id = chatState.room_id;
    // }
    // if (chatState.chatDetails) {
    //   location.state.chatDetails = chatState.chatDetails;
    // }
    // if (chatState.refrence_id) {
    //   location.state.refrence_id = chatState.refrence_id;
    // }
    // if (chatState.room_type) {
    //   location.state.room_type = chatState.room_type;
    // }
    // if (chatState.is_private_chat) {
    //   location.state.is_private_chat = chatState.is_private_chat;
    // }
    chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
    setLoadMoreScroll(0);
    joinRoom();
    setChatDetailHistory([]);
    setOffset(0);
    if (room_type == ROOM_TYPE.GROUP) {
      getGroupDetailCall();
    }
    getChatDetail();
    if (is_private_chat == IS_PRIVATE_CHAT.PRIVATE_CONNECTION) {
      getPrivateChatDetail();
    }
    setLoadMoreOn(true);
  }, [room_id]);

  // function for call get-chat-detail listeners
  function getChatDetail() {
    socket.emit("get-chat-detail", {
      user_id: currentUser.id,
      room_id: room_id,
      refrence_id: refrence_id,
      loadmore: 0,
      limit: limit,
      offset: offset,
    });
    socket.emit("read-message", {
      user_id: currentUser.id,
      room_id: room_id,
      refrence_id: refrence_id,
      message_id: "",
    });
  }
  function getGroupDetailCall() {
    socket.emit("get-group-detail", {
      user_id: currentUser.id,
      refrence_id: refrence_id,
    });
  }

  // function for clear chat
  const clearMessageHandler = useCallback(() => {
    setChatDetailHistory([]);
  });
  useEffect(() => {
    socket.on("clear-message", clearMessageHandler);
    return () => socket.off("clear-message", clearMessageHandler);
  }, [room_id]);

  const clearMessages = () => {
    socket.emit("clear-message", {
      user_id: currentUser.id,
      room_id: room_id,
      refrence_id: refrence_id,
    });
    setShowOptions(false);
  };

  // function for report user
  const reportUserHandler = useCallback((socketData) => {
    // console.log("report-user on ", socketData);
  });

  useEffect(() => {
    socket.on("report-user", reportUserHandler);
    return () => socket.off("report-user", reportUserHandler);
  }, [room_id]);

  const reportUser = () => {
    socket.emit("report-user", {
      user_id: currentUser.id,
      room_id: room_id,
      refrence_id: refrence_id,
      report_id: "",
    });
  };

  // function for check online and offline user
  const onlineOfflineUserHandler = useCallback((socketData) => {
    // console.log("online-offline on ", socketData);
    // socket.emit("online-offline", {
    //   user_id: currentUser.id,
    //   room_type: room_type,
    //   refrence_id: refrence_id,
    // });
  });

  useEffect(() => {
    socket.on("online-offline", onlineOfflineUserHandler);
    // console.log("online-offline on ");
    return () => socket.off("online-offline", onlineOfflineUserHandler);
  }, [room_id]);

  // Function for show typing in chat section
  const showTyping = useCallback(() => {
    socket.emit("typing", {
      user_id: currentUser.id,
      room_id: room_id,
      refrence_id: refrence_id,
    });
    // Clear typing indicator after 3 seconds of inactivity
    debounceTyping.current();
  }, [currentUser.id, room_id, refrence_id]);

  useEffect(() => {
    socket.on("typing", handleTyping);
    return () => socket.off("typing", handleTyping);
  }, [room_id]);

  const handleTyping = useCallback(
    debounce((socketData) => {
      setTypingUser((prevTypingUsers) => ({
        ...prevTypingUsers,
        [socketData.room_id]: socketData.user_id,
      }));
      setTimeout(() => {
        setTypingUser((prevTypingUsers) => {
          const updatedTypingUsers = { ...prevTypingUsers };
          delete updatedTypingUsers[socketData.room_id];
          return updatedTypingUsers;
        });
      }, 3000); // Clear typing indicator after 3 seconds
    }, 500),
    []
  );

  // Function for manage typing indicator in design
  const renderTypingIndicator = () => {
    if (typingUser[room_id]) {
      return (
        <div className={styles.typingIndicator}>
          {`${typingUser[room_id]} is typing...`}
        </div>
      );
    }
    return null;
  };

  // function for manage block user listeners
  const blockUserHandler = useCallback(
    (socketData) => {
      if (socketData.chat_room_id == room_id) {
        if (chatState.chatDetails) {
          setChatState((prevState) => ({
            ...prevState,
            chatDetails: {
              ...prevState.chatDetails,
              is_blocked: 1,
              block_by: socketData.block_by,
            },
          }));
        } else {
          location.state.chatDetails.is_blocked = 1;
          location.state.chatDetails.block_by = socketData.block_by;
        }
        setBlockStatus({ block_by: socketData.block_by, is_blocked: 1 });
      }
    },
    [currentUser.id, refrence_id]
  );

  useEffect(() => {
    socket.on("block-chat", blockUserHandler);
    return () => socket.off("block-chat", blockUserHandler);
  }, [room_id]);

  const blockUser = () => {
    socket.emit("block-chat", {
      user_id: currentUser.id,
      refrence_id: refrence_id,
    });
    setShowOptions(false);
  };

  // function for manage  unblock user listeners
  const unblockUserHandler = useCallback((socketData) => {
    if (socketData.chat_room_id == room_id) {
      if (chatState.chatDetails) {
        setChatState((prevState) => ({
          ...prevState,
          chatDetails: {
            ...prevState.chatDetails,
            is_blocked: 0,
            block_by: null,
          },
        }));
      } else {
        location.state.chatDetails.is_blocked = 0;
        location.state.chatDetails.block_by = null;
      }
      setBlockStatus({ block_by: null, is_blocked: 0 });
    }
  });

  useEffect(() => {
    socket.on("unblock-chat", unblockUserHandler);
    return () => socket.off("unblock-chat", unblockUserHandler);
  }, [room_id]);

  const unblockUser = () => {
    socket.emit("unblock-chat", {
      user_id: currentUser.id,
      refrence_id: refrence_id,
    });
    setShowOptions(false);
  };

  // function for join-room in chat section
  function joinRoom() {
    socket.emit("join", {
      user_id: currentUser.id,
      room_type: room_type,
      group_id: refrence_id,
    });
  }
  // function for handle text message
  const handleMessageChange = (event) => {
    const { value } = event.target;
    if (!value.trim()) {
      return;
    }
    setChatValue(value);
  };

  // function for remove  text after press backspace
  const handleKeyDown = (event) => {
    const { key, target } = event;
    if (key === "Backspace") {
      // Check if there is a selection
      if (target.selectionStart === target.selectionEnd) {
        // No selection, delete the last character
        const newText = chatValue.slice(0, -1);
        setChatValue(newText);
      } else {
        // Selection exists, delete all text
        setChatValue("");
      }
    }
  };

  // function for send message on press enter
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    } else {
      showTyping(); // Emit "typing" event when a key is pressed (except Enter)
    }
  };

  // function for send message on send icon click
  const sendMessage = () => {
    if (chatValue.trim() !== "") {
      socket.emit("message", {
        user_id: currentUser.id,
        room_id: room_id,
        chat_room_id: room_id,
        refrence_id: refrence_id,
        message: chatValue,
        room_type: room_type,
        mesage_type: MESSAGE_TYPE.NORMAL_MESSAGE,
        is_file: MESSAGE_IS_FILE.IS_FILE_TEXT,
        file_type: MESSAGE_FILE_TYPE.TEXT,
        file_url: "",
        media_timing: "",
      });
      setChatValue("");
    }
  };

  return (
    <section className="relative">
      <div className={styles.chatListDetailSection}>
        <div className={styles.chatDetailBox}>
          <div className={styles.chatListHead}>
            {room_type == ROOM_TYPE.GROUP && (
              <div className={styles.detailHeadFlex}>
                <div className={styles.groupeImageBox}>
                  <img
                    src={
                      getGroupDetail &&
                      getGroupDetail.groupDetail &&
                      getGroupDetail.groupDetail.group_image
                    }
                  />
                </div>
                <div
                  className={`${styles.detailHead} cursor-pointer`}
                  onClick={() => {
                    navigate("/chat/chat-group-detail", {
                      state: {
                        room_id: room_id,
                        groupDetail: getGroupDetail.groupDetail,
                        groupMemberList: getGroupDetail.members,
                        refrence_id: refrence_id,
                        chat_detail: chat_detail,
                      },
                    });
                  }}
                >
                  <p>
                    {getGroupDetail.groupDetail &&
                      getGroupDetail.groupDetail.group_title}
                  </p>
                  <div className={styles.chatListBox}>
                    {getGroupDetail &&
                    getGroupDetail.members &&
                    getGroupDetail.members.length > 0
                      ? getGroupDetail.members.map((item, index) => (
                          <span key={index}>
                            {item.fullname}
                            {index !== getGroupDetail.members.length - 1 &&
                              ", "}
                          </span>
                        ))
                      : ""}
                  </div>
                </div>
              </div>
            )}
            {room_type == ROOM_TYPE.PRIVATE && (
              <div className={styles.detailHeadFlex}>
                <div className={styles.groupeImageBox}>
                  <img
                    src={
                      is_private_chat == IS_PRIVATE_CHAT.PRIVATE_CONNECTION
                        ? chat_detail.profile_img
                        : chat_detail.refrence_profile_picture_url
                    }
                  />
                </div>
                <div className={`${styles.detailHead} cursor-pointer`}>
                  <div className={styles.chatListBox}>
                    <span>
                      {is_private_chat == IS_PRIVATE_CHAT.PRIVATE_CONNECTION
                        ? chat_detail.fullname
                        : chat_detail.refrence_username}
                    </span>
                  </div>
                  {renderTypingIndicator()}
                  {/* {chat_detail.refrence_is_online == 1 ? (
                    <span className={styles.onlineStatus}>Online</span>
                  ) : (
                    <span className={styles.offlineStatus}>Offline</span>
                  )} */}
                </div>
              </div>
            )}
            <div className={styles.detailOptionFlex}>
              {/* <Icon
                icon="ri:search-2-line"
                color="white"
                width="28"
                height="28"
              /> */}
              <div
                className={`${
                  showOptions
                    ? "relative openBoxClass"
                    : "relative closeBoxClass"
                }`}
              >
                <img
                  src={three_dot}
                  alt="three dot"
                  width={"35px"}
                  height={"35px"}
                  onClick={handleImageClick}
                  style={{ cursor: "pointer" }}
                />

                {showOptions && (
                  <div className={styles.option}>
                    <ul>
                      <li style={{ cursor: "pointer" }} onClick={clearMessages}>
                        {t("CLEAR_CHAT")}
                      </li>

                      {room_type === ROOM_TYPE.PRIVATE &&
                        (chat_detail.is_blocked == 0 ||
                        (chat_detail.is_blocked == 1 &&
                          chat_detail.block_by == currentUser.id) ? (
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              chat_detail.is_blocked == IS_BLOCKED.BLOCKED_USER
                                ? unblockUser()
                                : blockUser()
                            }
                          >
                            {chat_detail.is_blocked == IS_BLOCKED.BLOCKED_USER
                              ? "Unblock"
                              : "Block"}
                          </li>
                        ) : (
                          ""
                        ))}

                      {room_type === ROOM_TYPE.PRIVATE && (
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => reportUser}
                        >
                          {t("REPORT_USER")}
                        </li>
                      )}
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => clearChatHistory}
                      >
                        {t("CLEAR_CHAT_HISTORY")}
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            onScroll={handleScroll}
            ref={chatListRef}
            className={styles.test}
          >
            {showLoader && (
              <>
                <ChatLoader />
              </>
            )}

            {chatDetailHistory &&
              chatDetailHistory.length > 0 &&
              chatDetailHistory.map((item, index) => (
                <div className={styles.chatListBoxDetail} key={index}>
                  {item.sender_id == currentUser.id ? (
                    <div className={`${styles.myMessage}`}>
                      <div className={styles.messageInfo}>
                        <div className={`mb-2 ${styles.detailHeadFlex}`}>
                          <span>
                            {moment(item.message_date).format("hh:mm A")}
                          </span>
                          <p>You</p>
                        </div>
                        {item.file_type === MESSAGE_FILE_TYPE.TEXT && (
                          <div className={styles.chatMessage}>
                            <BlueOnGreenTooltip
                              title={moment(item.message_date).format("lll")}
                              placement="top-start"
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    backgroundColor: "#111233",
                                    "& .MuiTooltip-arrow": {
                                      color: "#111233",
                                    },
                                  },
                                },
                              }}
                            >
                              <p>{item.message}</p>
                            </BlueOnGreenTooltip>
                          </div>
                        )}
                        {item.file_type == MESSAGE_FILE_TYPE.IMAGE && (
                          <div className={styles.chatImage}>
                            <BlueOnGreenTooltip
                              title={moment(item.message_date).format("lll")}
                              placement="top-start"
                              arrow
                            >
                              <img src={item.filename}></img>
                            </BlueOnGreenTooltip>
                          </div>
                        )}
                      </div>
                      <div className={styles.userProfileChat}>
                        <img
                          src={currentUser.profile_img}
                          className={styles.userProfile}
                        />
                        <img src={read} className={styles.readImgClass} />
                      </div>
                    </div>
                  ) : (
                    <div className={`${styles.yourMessage}`}>
                      <div className={styles.userProfileChat}>
                        <img
                          src={item.sender_picture_name}
                          className={styles.userProfile}
                        />
                        {/* {item.refrence_is_online == 1 ? (
                          <span className={styles.onlineStatus}>Online</span>
                        ) : (
                          <span className={styles.offlineStatus}>Offline</span>
                        )} */}
                      </div>
                      <div className={styles.messageInfo}>
                        <div className={`mb-2 ${styles.detailHeadFlex}`}>
                          <p>{item.sender_username}</p>
                          <span>
                            {moment(item.message_date).format("hh:mm A")}
                          </span>
                        </div>
                        {item.file_type === MESSAGE_FILE_TYPE.TEXT && (
                          <div className={styles.chatMessage}>
                            <BlueOnGreenTooltip
                              title={moment(item.message_date).format("lll")}
                              placement="top-start"
                              arrow
                            >
                              <p>{item.message}</p>
                            </BlueOnGreenTooltip>
                          </div>
                        )}

                        {item.file_type == MESSAGE_FILE_TYPE.IMAGE && (
                          <div className={styles.chatImage}>
                            <BlueOnGreenTooltip
                              title={moment(item.message_date).format("lll")}
                              placement="top-start"
                              arrow
                            >
                              <img src={item.filename}></img>
                            </BlueOnGreenTooltip>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div
            className={
              chatValue.length > 60
                ? styles.chatInputClass
                : styles.chatInputFlex
            }
          >
            {blockStatus.is_blocked == IS_BLOCKED.UNBLOCKED_USER && (
              <div
                className={`${
                  chatValue.length > 60
                    ? "flex items-start w-full justify-between"
                    : "flex items-center w-full justify-between"
                } ${styles.commentUserIcon}`}
              >
                <div className={styles.textUserProfile}>
                  <img src={currentUser.profile_img} className="mr-3" />
                </div>

                <TextareaAutosize
                  placeholder={t("CHAT_PLACEHOLDER_TEXT")}
                  className={`${"w-full"} ${styles.chatTextarea}`}
                  ref={textareaRef}
                  value={chatValue}
                  onChange={handleMessageChange}
                  onKeyDown={handleKeyDown}
                  onKeyPress={handleKeyPress}
                />
              </div>
            )}

            {blockStatus.is_blocked == IS_BLOCKED.BLOCKED_USER && (
              <>
                <div
                  className={`${
                    chatValue.length > 60
                      ? "flex items-start w-full justify-between"
                      : "flex items-center w-full justify-between"
                  } ${styles.commentUserIcon}`}
                >
                  <TextareaAutosize
                    placeholder={
                      blockStatus.block_by &&
                      blockStatus.block_by == currentUser.id
                        ? "You cannot send messages because you have blocked this user."
                        : "This user has blocked you. You cannot send messages."
                    }
                  ></TextareaAutosize>
                </div>
              </>
            )}

            {blockStatus.is_blocked == IS_BLOCKED.UNBLOCKED_USER && (
              <div className={`flex items-center gap-2 ${styles.commentBtn}`}>
                <label
                  className={`${
                    styles.uploadGallary
                  } ${"relative cursor-pointer"}`}
                >
                  <input
                    type="file"
                    className="hidden"
                    onChange={handleImageChange}
                    accept="image/*"
                  />
                  <img src={gallary} />
                </label>
                <label
                  className={`${
                    openEmoji
                      ? "relative cursor-pointer emojiCommentClass w-10"
                      : "relative cursor-pointer w-10"
                  }`}
                >
                  <img
                    src={emoji}
                    alt="emoji"
                    className={` ${"emojiClassDetail"} "w-8 h-8"`}
                    onClick={() => {
                      openEmoji ? setOpenEmoji(false) : setOpenEmoji(true);
                    }}
                    width={"32px"}
                    height={"32px"}
                  />
                </label>
                <Icon
                  icon="fluent:send-24-filled"
                  color="#707185"
                  width={"28"}
                  height={"28"}
                  cursor="pointer"
                  onClick={sendMessage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={`${openEmoji ? "emojiCommentClass" : ""}`}>
        {openEmoji ? (
          <EmojiPicker
            setCaptionValue={setChatValue}
            captionValue={chatValue}
            setOpenEmoji={setOpenEmoji}
            emojiClass={"manageRespoChat"}
          />
        ) : (
          ""
        )}
      </div>
    </section>
  );
}

export default memo(ChatDetailsModule);
