import React, { useEffect, useState } from "react";
import styles from "./ChatModule.module.css";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { Icon } from "@iconify/react";
import SublyApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";
import { STATUS_CODES } from "../../utils/StatusCode";
import { Toast } from "../../utils/Toster";

function AddMamberModal({
  SetOpenAddMember,
  openAddMember,
  createGroupHandle,
  setMembersList,
  isCreate,
  setIsLoader,
  membersList,
  room_id,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  const [checkHandle, setCheckHandle] = useState([]);
  const [membersData, setMembersData] = useState("");
  const [searchValue, setSearchValue] = useState("");

  // =======clear redux========
  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  // ===Calling APi for get connection list ========
  async function getConnectionsList(search) {
    const requestData = new FormData();
    requestData.append("page_no", 1);
    await SublyApi.connectionList(
      userToken,
      requestData,
      search ? search : ""
    ).then((response) => {
      if (response.status_code === STATUS_CODES.SUCCESS) {
        setMembersData(response.data.connectionList);
        setIsLoader && setIsLoader(false);
      } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: response.message,
        });
        setIsLoader && setIsLoader(false);
      } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        logOutDataClear();
        setIsLoader && setIsLoader(false);
      } else {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        setIsLoader && setIsLoader(false);
      }
    });
  }

  useEffect(() => {
    getConnectionsList();
  }, [openAddMember]);

  // ===========Calling APi for addding more members in the group===========
  async function addMemberHandle() {
    const requestData = new FormData();
    requestData.append("chatRoomId", room_id && room_id);
    requestData.append("memberData", membersList);
    await SublyApi.addGroupMembers(userToken, requestData).then((response) => {
      // console.log("addGroupMembers response", response);
      if (response.status_code === STATUS_CODES.SUCCESS) {
        Toast.fire({
          icon: "success",
          title: response.message,
        });
        SetOpenAddMember(false);
        setMembersData("");
      } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: response.message,
        });
      } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        logOutDataClear();
      } else {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
      }
    });
  }

  return (
    <section>
      <Modal
        show={openAddMember}
        onHide={() => {
          SetOpenAddMember(false);
          setCheckHandle([]);
          setMembersList && setMembersList("");
          setSearchValue("");
        }}
        className="modalCloseBtn"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles.modalTitle}>
            {t("ADD_MEMBERS")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.AddMemberClass}>
          <div className={styles.memberSearch}>
            <div className="flex flex-col mb-6">
              <label className={styles.searchLabelClass}>
                <input
                  type="text"
                  placeholder={t("SEARCH_NAME")}
                  value={searchValue}
                  onChange={(e) => {
                    getConnectionsList(e.target.value);
                    setSearchValue(e.target.value);
                  }}
                />
                <Icon
                  icon="ri:search-2-line"
                  color="#4d4e66"
                  width="26"
                  height="26"
                  cursor={"pointer"}
                />
              </label>
            </div>
            {membersData.length > 0
              ? membersData.map((item, index) => (
                  <div className={`${styles.memberItem}`} key={index}>
                    <div className={styles.profileArea}>
                      <div className={styles.profileImg}>
                        <img src={item.profile_img} />
                      </div>
                      <div className={styles.userInfo}>
                        <h4>{item.fullname}</h4>
                        <p>{item.current_designation}</p>
                      </div>
                    </div>
                    <div className={`${styles.checkBoxClass}`}>
                      <label
                        onChange={(e) => {
                          if (e.target.checked) {
                            const addvalue = [...membersList];
                            addvalue.push(e.target.id);
                            setCheckHandle(addvalue);
                            setMembersList && setMembersList(addvalue);
                          } else {
                            const filterList = checkHandle.filter(
                              (element) => element !== e.target.id
                            );
                            setCheckHandle(filterList);
                            setMembersList && setMembersList(filterList);
                          }
                        }}
                      >
                        <input type="checkbox" id={item.user_id} name="test" />
                      </label>
                    </div>
                  </div>
                ))
              : ""}
            <div className={styles.submitBtn}>
              <button
                className={`bg-mid_background`}
                disabled={
                  membersList && membersList.length > 0
                    ? false
                    : checkHandle && checkHandle.length > 0
                    ? false
                    : true
                }
                onClick={() => {
                  if (isCreate) {
                    createGroupHandle();
                  } else if (room_id) {
                    addMemberHandle();
                  }
                }}
              >
                {t("DONE")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default AddMamberModal;
