import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./HomeModule.module.css";
import SublyApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../utils/StatusCode";
import { handleKeyValues, userMediaClear } from "../../store/Slices/MediaSlice";
import { Toast } from "../../utils/Toster";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import EmojiPicker from "../../commonComponent/EmojiPicker";
import $ from "jquery";
import emoji from "../../Assests/Images/emoji.svg";
import TextareaAutosize from "react-textarea-autosize";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";
import { Icon } from "@iconify/react";

function CommentBox({
  items,
  setIsLoader,
  index,
  commentDetailBox,
  setIsComment,
  isComment,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  const { profileDetail } = useSelector((state) => state.profile);
  const { postMediaList } = useSelector((state) => state.media);

  const [commentValue, setCommentValue] = useState("");
  const [openEmoji, setOpenEmoji] = useState(false);
  const [commentId, setCommentId] = useState("");

  // =======clear redux========
  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  // function for handle comment in post
  async function commentHandle() {
    if (commentValue) {
      setIsLoader(true);
      const requestData = new FormData();
      requestData.append("post_id", items.id);
      requestData.append("comment", commentValue);
      await SublyApi.commentOnPost(userToken, requestData).then(
        async (responsejson) => {
          if (responsejson.status_code === STATUS_CODES.SUCCESS) {
            setCommentValue("");
            setIsComment && setIsComment(!isComment);
            const requestData = new FormData();
            requestData.append("page_no", 1);
            requestData.append("post_id", items.id);
            await SublyApi.fetchCommentList(userToken, requestData).then(
              async (responsejson) => {
                if (responsejson.status_code === STATUS_CODES.SUCCESS) {
                  const MediaObject = [...postMediaList];
                  MediaObject[index] = {
                    ...MediaObject[index],
                    recent_comments: responsejson.data.fetchComments,
                  };
                  MediaObject[index] = {
                    ...MediaObject[index],
                    total_comments: MediaObject[index].total_comments + 1,
                  };
                  dispatch(handleKeyValues({ postMediaList: MediaObject }));
                }
              }
            );
            setIsLoader(false);
          } else if (
            responsejson.data.status_code === STATUS_CODES.BAD_REQUEST
          ) {
            Toast.fire({
              icon: "error",
              title: responsejson.data.message,
            });
            setIsLoader(false);
          } else if (
            responsejson.data.status_code === STATUS_CODES.INVALID_TOKEN
          ) {
            Toast.fire({
              icon: "error",
              title: responsejson.data.message,
            });
            logOutDataClear();
            setIsLoader(false);
          } else if (
            responsejson.status === STATUS_CODES.INTERNAL_SERVER_ERROR
          ) {
            Toast.fire({
              icon: "error",
              title: `${t("SERVER_ERR")}`,
            });
            setIsLoader(false);
          } else {
            Toast.fire({
              icon: "error",
              title: responsejson.data.message,
            });
            setIsLoader(false);
          }
        }
      );
    }
  }

  // ====function to hide box on click outside====
  $(document).mouseup(function (e) {
    if ($(e.target).closest(".emojiCommentClass").length === 0) {
      setOpenEmoji(false);
    }
  });

  return (
    <section
      className={`flex items-center justify-between ${
        commentValue.length > 60
          ? styles.commentBoxAreaLength
          : styles.commentBoxArea
      } ${commentDetailBox}`}
    >
      <div
        className={`${
          commentValue.length > 60
            ? "flex items-start w-full justify-between"
            : "flex items-center w-full justify-between"
        } ${styles.commentUserIcon}`}
      >
        <img src={profileDetail.profile_img} className="mr-3" />
        <TextareaAutosize
          placeholder={t("PLACEHOLDER_TEXT")}
          className="w-full pr-6"
          id={items.id + 1}
          value={commentValue}
          onChange={(e) => {
            setCommentValue(e.target.value);
            // setCommentId(items.id + 1);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              commentHandle();
            }
          }}
        />
      </div>
      <div
        className={`flex items-center commentBtnResponse ${styles.commentBtn}`}
      >
        <label
          className={`${
            openEmoji
              ? "cursor-pointer emojiCommentClass w-10"
              : "cursor-pointer w-10"
          }`}
        >
          <img
            src={emoji}
            alt="emoji"
            className={` ${commentDetailBox && "emojiClassDetail"} "w-8 h-8"`}
            onClick={() => {
              openEmoji ? setOpenEmoji(false) : setOpenEmoji(true);
            }}
          />
          {openEmoji ? (
            <EmojiPicker
              setCaptionValue={setCommentValue}
              captionValue={commentValue}
              setOpenEmoji={setOpenEmoji}
              emojiClass={"manageRespo"}
            />
          ) : (
            ""
          )}
        </label>
        {commentValue ? (
          <Icon
            icon="fluent:send-24-filled"
            color="#707185"
            width={`${commentDetailBox ? "25" : "30"}`}
            height={`${commentDetailBox ? "25" : "30"}`}
            onClick={() => commentHandle()}
            cursor="pointer"
          />
        ) : (
          ""
        )}
      </div>
    </section>
  );
}

export default CommentBox;
