import React, { useState, useEffect } from "react";
import colourImg from "../../Assests/Images/bn-opcity.webp";
import backgroundImg from "../../Assests/Images/mask.webp";
import upperImg from "../../Assests/Images/banner-group-img.webp";
import communityImg from "../../Assests/Images/community.webp";
import socialImg from "../../Assests/Images/social-nw.webp";
import trendingImg from "../../Assests/Images/trending.webp";
import chatImg from "../../Assests/Images/chat-group.webp";
import postImg from "../../Assests/Images/post.webp";
import baseImg from "../../Assests/Images/first-img.webp";
import firstImg from "../../Assests/Images/second-img.webp";
import secondImg from "../../Assests/Images/midSection-lower.webp";
import rightImg from "../../Assests/Images/Block_combind.webp";
import appStoreImg from "../../Assests/Images/app-store2.webp";
import playStroreImg from "../../Assests/Images/play-store2.webp";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./Home.module.css";
import { Card } from "react-bootstrap";
import { BsDot } from "react-icons/bs";
import Footer from "../Footer/Footer";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import { getTokenArr } from "../../firebase";
import $ from "jquery";

const Home = () => {
  const { t } = useTranslation();
  const [getToken, setToken] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  window.addEventListener("resize", function (event) {
    setScreenWidth(window.innerWidth);
  });

  // ====getting firebase token====
  useEffect(() => {
    getTokenArr(setToken);
  }, []);

  localStorage.setItem("linkiii_device_fireToken", getToken && getToken);


  return (
    <>
      <Header />
      <div>
        {/* MId section part-1 */}
        <div className="relative top-20">
          <img src={colourImg} className="w-full h-full" alt="Image 1" />
          <img
            src={backgroundImg}
            className="absolute top-0 left-0 w-full m-0"
            alt="Image 2"
          />
          <section>
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <Container>
                <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 lgg:grid-cols-2 lg:grid-cols1">
                  <div className="col-span-1">
                    <div className="text0">
                      <div className="2xl:pt-52 2xl:pr-14 lgg:pr-14 xl:pr-14 xl:pt-52 lgg:pt-36">
                        <p className="content-center 2xl:leading-15 2xl:pl-20 xl:leading-15 lgg:leading-15 xl:pl-20 text-5xl text-white text-center font-bold md:text-4xl sm:text-3xl">
                          {t("HEADING")}
                        </p>
                      </div>
                      <div>
                        <h3
                          className={`text-4xl text-white text-center md:text-lg sm:text-lg ${styles.subHeading}`}
                        >
                          {t("SUB_HEADING")}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <img
                      className="lg:hidden md:hidden sm:hidden"
                      src={upperImg}
                      alt="banner-Image"
                    />
                  </div>
                </div>
              </Container>
            </div>
          </section>
        </div>

        {/* MId section part-2 */}
        <div className="pt-12 relative">
          {screenWidth > 990 ?
            (<div className={styles.midSection}>
              <div className={styles.cardItems}>
                <div className="flex flex-wrap space-x-6 space-y-5 mr-11">
                  <div></div>
                  <Card className={styles.card}>
                    <Card.Body className="p-6">
                      <div className="flex">
                        <img
                          className={styles.backgoundImg}
                          src={communityImg}
                          alt=""
                        />
                        <Card.Title className={styles.title}>
                          {t("COMMUNITY_TITEL")}
                        </Card.Title>
                      </div>
                      <Card.Text className={styles.cardText}>
                        {t("COMMUNITY_TEXT")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card className={styles.card}>
                    <Card.Body className="p-6">
                      <div className="flex">
                        <img
                          className={styles.backgoundImg}
                          src={socialImg}
                          alt=""
                        />
                        <Card.Title className={styles.title}>
                          {t("SOCIAL_TITEL")}
                        </Card.Title>
                      </div>
                      <Card.Text className={styles.cardText}>
                        {t("SOCIAL_TEXT")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card className={styles.card}>
                    <Card.Body className="p-6">
                      <div className="flex">
                        <img
                          className={styles.backgoundImg}
                          src={trendingImg}
                          alt=""
                        />
                        <Card.Title className={styles.title}>
                          {t("TRENDING_TITEL")}
                        </Card.Title>
                      </div>
                      <Card.Text className={styles.cardText}>
                        {t("TRENDING_TEXT")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card className={styles.card}>
                    <Card.Body className="p-6">
                      <div className="flex">
                        <img
                          className={styles.backgoundImg}
                          src={chatImg}
                          alt=""
                        />
                        <Card.Title className={styles.title}>
                          {t("CHAT_TITEL")}
                        </Card.Title>
                      </div>
                      <Card.Text className={styles.cardText}>
                        {t("CHAT_TEXT")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card className={styles.card}>
                    <Card.Body className="p-6">
                      <div className="flex">
                        <img
                          className={styles.backgoundImg}
                          src={postImg}
                          alt=""
                        />
                        <Card.Title className={styles.title}>
                          {t("POST_TITEL")}
                        </Card.Title>
                      </div>
                      <Card.Text className={styles.cardText}>
                        {t("POST_TEXT")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <div>
                    <h4 className={styles.whatWE}>{t("WHAT_WE_DO")}</h4>
                    <p
                      className={`sm:text-center 2xl:text-4xl xl:text-4xl text-3xl font-bold ${styles.theBest}`}
                    >
                      {t("THE_BEST_PLACE")}
                    </p>
                    <p
                      className={`sm:text-center 2xl:text-4xl xl:text-4xl text-3xl ${styles.linkiSocial}`}
                    >
                      {t("LINKIII_SOCIAL_NETWORK")}
                    </p>
                  </div>
                </div>
              </div>
            </div>)
            :
            (<div className={styles.midSection}>
              <div className={styles.responsiveCard}>
                <div className="flex flex-wrap space-y-5">
                  <div></div>
                  <Card className={styles.card}>
                    <Card.Body className="p-6">
                      <div className="flex">
                        <img
                          className={styles.backgoundImg}
                          src={communityImg}
                          alt=""
                        />
                        <Card.Title className={styles.title}>
                          {t("COMMUNITY_TITEL")}
                        </Card.Title>
                      </div>
                      <Card.Text className={styles.cardText}>
                        {t("COMMUNITY_TEXT")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card className={styles.card}>
                    <Card.Body className="p-6">
                      <div className="flex">
                        <img
                          className={styles.backgoundImg}
                          src={socialImg}
                          alt=""
                        />
                        <Card.Title className={styles.title}>
                          {t("SOCIAL_TITEL")}
                        </Card.Title>
                      </div>
                      <Card.Text className={styles.cardText}>
                        {t("SOCIAL_TEXT")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card className={styles.card}>
                    <Card.Body className="p-6">
                      <div className="flex">
                        <img
                          className={styles.backgoundImg}
                          src={trendingImg}
                          alt=""
                        />
                        <Card.Title className={styles.title}>
                          {t("TRENDING_TITEL")}
                        </Card.Title>
                      </div>
                      <Card.Text className={styles.cardText}>
                        {t("TRENDING_TEXT")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card className={styles.card}>
                    <Card.Body className="p-6">
                      <div className="flex">
                        <img
                          className={styles.backgoundImg}
                          src={chatImg}
                          alt=""
                        />
                        <Card.Title className={styles.title}>
                          {t("CHAT_TITEL")}
                        </Card.Title>
                      </div>
                      <Card.Text className={styles.cardText}>
                        {t("CHAT_TEXT")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card className={styles.card}>
                    <Card.Body className="p-6">
                      <div className="flex">
                        <img
                          className={styles.backgoundImg}
                          src={postImg}
                          alt=""
                        />
                        <Card.Title className={styles.title}>
                          {t("POST_TITEL")}
                        </Card.Title>
                      </div>
                      <Card.Text className={styles.cardText}>
                        {t("POST_TEXT")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <div>
                    <h4 className={styles.whatWE}>{t("WHAT_WE_DO")}</h4>
                    <p
                      className={`2xl:text-4xl xl:text-4xl text-3xl font-bold ${styles.theBest}`}
                    >
                      {t("THE_BEST_PLACE")}
                    </p>
                    <p
                      className={`2xl:text-4xl xl:text-4xl text-3xl ${styles.linkiSocial}`}
                    >
                      {t("LINKIII_SOCIAL_NETWORK")}
                    </p>
                  </div>
                </div>
              </div>
            </div>)}
        </div>

        {/* Mid section part-3 */}
        <div className={styles.midPart}>
          <Container>
            <Row>
              <Col lg={6} md={12} sm={12}>
                <div className={styles.text}>
                  <p
                    className={`no-underline text-xl text-mid_text leading-5 ${styles.whatLinkii}`}
                  >
                    {t("WHAT_LINKIII")}
                  </p>
                  <div>
                    <h2 className={styles.headindText}>{t("WHY_JOIN")}</h2>
                  </div>
                  <div className="mb-4">
                    <p
                      className={`text-mid_text2 leading-8 ${styles.homeMidText}`}
                    >
                      {t("HOME_MID_TEXT1")}
                    </p>
                  </div>
                  <div className="mb-4">
                    <p className="text-mid_text2 leading-8">
                      {t("HOME_MID_TEXT2")}
                    </p>
                  </div>
                  <div className="text-xl mb-4">
                    <div className="flex">
                      <div className={styles.icon}>
                        <BsDot />
                      </div>
                      <div className={styles.list}>
                        <p>{t("SOCIAL_POSTING")}</p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className={styles.icon}>
                        <BsDot />
                      </div>
                      <div className={styles.list}>
                        <p>{t("PROFESSIONAL_JOB")}</p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className={styles.icon}>
                        <BsDot />
                      </div>
                      <div className={styles.list}>
                        <p>{t("PROFESSIONAL_COMMUNITY")}</p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className={styles.icon}>
                        <BsDot />
                      </div>
                      <div className={styles.list}>
                        <p>{t("NETWORK_WITH_FRIEND")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div
                  className={`relative 2xl:ml-12 xl:ml-12 lgg:ml-12 lg:ml-12 md:ml-12 ${styles.images}`}
                >
                  <img
                    className={styles.baseImage}
                    src={baseImg}
                    alt="base-image"
                  />
                  <img
                    className={styles.firstImage}
                    src={firstImg}
                    alt="upper-image"
                  />
                  <img
                    className={styles.secondImage}
                    src={secondImg}
                    alt="lower-image"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Mid section part-4 */}
        <div className="pt-12 bg-mid_background">
          <Container>
            <Row>
              <Col lg={6}>
                <div className="pl-16 pr-7">
                  <p
                    className={`text-white sm:text-2xl md:text-2xl lg:text-3xl lgg:text-3xl text-5xl sm:text-center leading-10 font-bold ${styles.youCan}`}
                  >
                    {t("HOME_MID_HEADING")}
                  </p>
                  <p className="text-mid_text3 font-normal leading-8 font-sans-serif">
                    {t("HOME_MID_TEXT3")}
                  </p>
                  <div className="flex lg:pl-14">
                    <img
                      className={styles.storeIMg}
                      src={appStoreImg}
                      alt="app-store"
                    />
                    <img src={playStroreImg} alt="play-store" />
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <img className="m-auto	" src={rightImg} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Outlet />
      <Footer />
    </>
  );
};

export default Home;
