import React from "react";
import ReactPlayer from "react-player";

function VideoPlayer({ videoUrl }) {
  return (
    <section>
      <ReactPlayer
        url={videoUrl}
        width="100%"
        height="100%"
        playing={false}
        loop={true}
        controls={true}
      />
    </section>
  );
}

export default VideoPlayer;
