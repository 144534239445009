import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
// const REACT_PROXYURL = "https://quiet-retreat-79741.herokuapp.com/";
const REACT_PROXYURL = "";

// for making unique id for every browser
if (!localStorage.getItem("linkiii_device_id")) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem("linkiii_device_id", uint32.toString(32));
}

// for making unique token for every browser
if (!localStorage.getItem("linkiii_device_token")) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem("linkiii_device_token", uint32.toString(32));
}

/** API Class
 * static clase trying together method used to get/send to the API.
 * There shouldn't be any frontend-specific stuff here, and there  shouldn't be any API-aware stuff else hre in the frontend...
 */
class SublyApi {
  //token which interact with the API will be stored here.
  static token;
  //required common header for each api calling.
  static commonHeaders = {
    "api-key": process.env.REACT_APP_API_KEY_PAIR,
    "device-token":
      (localStorage.getItem("linkiii_device_fireToken"))
        ? localStorage.getItem("linkiii_device_fireToken")
        : localStorage.getItem("linkiii_device_token"),
    "device-id": localStorage.getItem("linkiii_device_id"),
    // "device-token": localStorage.getItem("linkiii_device_token"),
    "device-type": "3",
  };
  

  static async request(endpoint, data = {}, method = "get", header) {
    const url = `${REACT_PROXYURL}${BASE_URL}${endpoint}`;
    const headers = { ...SublyApi.commonHeaders, ...header };

    const params = method === "get" ? data : {};
    try {
      const result = await axios({ url, method, data, params, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  /* ------SIGNUP API -----*/

  static async signUp(data) {
    let res = await this.request(`/sign-up`, data, "post");
    return res;
  }

  /* ------VERIFY EMAIL API -----*/

  static async verifyEmail(data) {
    let res = await this.request(`/verify-email`, data, "post");
    return res;
  }

  /* ------LOGIN API -----*/

  static async login(data) {
    let res = await this.request(`/login`, data, "post");
    return res;
  }

  /* ------FORGOT PASSWORD API -----*/

  static async forgotPassword(data) {
    let res = await this.request(`/forgot-password`, data, "post");
    return res;
  }

  /* ------VERIFY FORGOT PASSWORD API -----*/

  static async verifyForgotPassword(data) {
    let res = await this.request(`/verify-forgot-password`, data, "post");
    return res;
  }

  // ------------Fetch Profile data------------

  static async getProfileData(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/fetch-profile-data`, data, "get", header);
    return res;
  }

  // ------------Trending list------------

  static async getTrendingList(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/trending-list`, data, "post", header);
    return res;
  }

  // ------------Post media list------------

  static async getPostMedia(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/post-media-list`, data, "post", header);
    return res;
  }

  // ------------Create post media------------

  static async createMediaPost(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/create-post`, data, "post", header);
    return res;
  }

  // ------------Comment on the post------------

  static async commentOnPost(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/comment-post`, data, "post", header);
    return res;
  }

  // ------------Like post------------

  static async likePost(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/like-post`, data, "post", header);
    return res;
  }

  // ------------Report post------------

  static async reportPost(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/report-post`, data, "post", header);
    return res;
  }

    // ------------Report user------------

    static async reportUser(authToken, data) {
      let header = { "access-token": ` ${authToken}` };
      let res = await this.request(`/report-user`, data, "post", header);
      return res;
    }

  // ------------disllike post------------

  static async dislikePost(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/dislike-post`, data, "post", header);
    return res;
  }

  // ------------repost post------------

  static async repostPost(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/repost-post`, data, "post", header);
    return res;
  }
  /* ----------User about me -----*/

  static async userAboutMe(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/user-about-me`, data, "post", header);
    return res;
  }

  /* ----------User update profile -----*/

  static async userUpdateProfile(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/update-profile`, data, "post", header);
    return res;
  }

  /* ----------search tags -----*/

  static async userSearchTags(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/search-tags`, data, "post", header);
    return res;
  }

  /* ----------edit my tags -----*/

  static async EditMyTags(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/edit-my-tags`, data, "post", header);
    return res;
  }

  /* ----------user tags -----*/

  static async userTags(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/user-tags`, data, "get", header);
    return res;
  }

  /* ----------search skills -----*/

  static async userSearchSkills(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/search-skills`, data, "post", header);
    return res;
  }

  /* ----------edit my skillls -----*/

  static async editMySkills(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/edit-my-skills`, data, "post", header);
    return res;
  }

  /* ----------user skills -----*/

  static async userSkills(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/user-skills`, data, "get", header);
    return res;
  }

  /* ----------upload cover image -----*/

  static async uploadCoverImg(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/upload-cover-image`, data, "post", header);
    return res;
  }

  // ------------Fetch experience data------------

  static async getExperience(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/fetch-experience`, data, "get", header);
    return res;
  }

  // ------------Fetch education data------------

  static async getEducation(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/fetch-education`, data, "get", header);
    return res;
  }

  // ------------Save experience data------------

  static async sevExperience(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/save-experience`, data, "post", header);
    return res;
  }

  // ------------Remove experience data------------

  static async removeExperience(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/remove-experience`, data, "post", header);
    return res;
  }

  // ------------Save education data------------

  static async sevEducation(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/save-education`, data, "post", header);
    return res;
  }

  // ------------Remove education data------------

  static async removeEducation(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/remove-education`, data, "post", header);
    return res;
  }

  /* ----------Count (request,follow) -----*/

  static async fetchCount(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/fetch-counts`, "", "get", header);
    return res;
  }

  /* ----------Request List -----*/

  static async requestList(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/connection-request-list`, "", "get", header);
    return res;
  }

  /* ----------Connection Request action -----*/

  static async requestConnectionAction(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/connection-request-action`,
      data,
      "post",
      header
    );
    return res;
  }

  /* ----------Connection List -----*/

  static async connectionList(authToken, data, searchValue) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/connection-list?search=${searchValue ? searchValue : ""}`,
      data,
      "post",
      header
    );
    return res;
  }

  /* ----------Suggestions List -----*/

  static async suggestionList(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/suggest-user-connection`,
      data,
      "post",
      header
    );
    return res;
  }

  /* ----------Send connection request -----*/

  static async sendRequestList(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/send-connection-request`,
      data,
      "post",
      header
    );
    return res;
  }

  /* --------user profile detail-------*/

  static async userProfileDetail(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/user-profile-detail`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* --------other user profile detail-------*/

  static async otherProfileDetail(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/fetch-other-user-profile`,
      data,
      "post",
      header
    );
    return res;
  }

  // ----------Delete post-----------
  static async deletePost(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/delete-post`, data, "post", header);
    return res;
  }

  // -------------Fetch comment list--------------
  static async fetchCommentList(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/fetch-comment-list`, data, "post", header);
    return res;
  }
  // ----------Disconnect user-----------
  static async disconnectUser(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/disconnect-user`, data, "post", header);
    return res;
  }

  // ----------Unfollow user-----------
  static async unfollowUser(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/unfollow-user`, data, "post", header);
    return res;
  }

  // -----------Post detail------------
  static async FetchPostDetail(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/post-detail`, data, "post", header);
    return res;
  }
  // ----------follow user-----------
  static async followUser(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/follow-user`, data, "post", header);
    return res;
  }

  // ----------follower list of user-----------
  static async followerList(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/follower-list`, data, "post", header);
    return res;
  }

  // ----------save suggestion of user-----------
  static async saveSuggestion(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/save-suggestion`, data, "post", header);
    return res;
  }

  // ----------need help-----------
  static async needHelp(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/need-help`, data, "post", header);
    return res;
  }

  // ----------Change password-----------
  static async changePassword(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/change-password`, data, "post", header);
    return res;
  }

  // ----------Notification status update-----------
  static async notificationStatus(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/update-notification-status`,
      data,
      "post",
      header
    );
    return res;
  }

  // ----------Notification List-----------
  static async notificationList(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/notification-list`,
      undefined,
      "get",
      header
    );
    return res;
  }

  // ----------Notification List read status-----------
  static async readNotification(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/notification-read-status-update`,
      data,
      "post",
      header
    );
    return res;
  }

  // ----------Global Search-----------
  static async globalSeach(authToken, search) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/global-search?search=${search}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  // ----------Create chat group-----------
  static async createGroupChat(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/create-group`, data, "post", header);
    return res;
  }

  // ----------add members in group-----------
  static async addGroupMembers(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/add-group-member`, data, "post", header);
    return res;
  }

  // ----------Social signUp ---------------

  static async socialSignUpHandle(data) {
    let res = await this.request(`/social-signup`, data, "post");
    return res;
  }

  // ----------Social login ---------------

  static async socialLoginHandle(data) {
    let res = await this.request(`/social-login`, data, "post");
    return res;
  }

  // ----------Verify google login ---------------

  static async verifyGoogleLogin(data) {
    let res = await this.request(`/verify-google-login`, data, "post");
    return res;
  }

  // ----------Delete user api---------------

  static async deleteUser(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/delete-user`, undefined, "get", header);
    return res;
  }

  // ----------send media in chat module api---------------
  static async sendMedia(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/upload-media`, data, "post", header);
    return res;
  }

  // ------------Remove group member------------

  static async removeGroupMember(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/remove-group-member`, data, "post", header);
    return res;
  }
}

export default SublyApi;
