import React from "react";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../Assests/Styles/Common.css";
import crossImg from "../../Assests/Images/popupCrossImg.webp";
import { FiSearch } from "react-icons/fi";
import SublyApi from "../../helpers/Api";
import { Toast } from "../../utils/Toster";
import { useDispatch, useSelector } from "react-redux";
import { profileData } from "../../store/Slices/ProfileSlice";
import { RxCross2 } from "react-icons/rx";

const TagModal = ({ userTag, setUserTag }) => {
  const { t } = useTranslation();
  const { profile } = useSelector((state) => state.profile);
  const { userToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [searchList, setSearchList] = useState([]);
  const [tags, setTags] = useState("");

  // === function for handle search ===
  const searchHandler = async (event) => {
    setTags(event.target.value);
    let requestData = new FormData();
    requestData.append("search", event.target.value);
    if (event.target.value === "") {
      setSearchList([]);
    }
    await SublyApi.userSearchTags(userToken, requestData).then(
      (responsejson) => {
        if (responsejson.status_code === 200) {
          dispatch(profileData(userToken));
          setSearchList(responsejson.data);
        } else {
        }
      }
    );
  };

  // === function for edit tags ===
  const editTags = async (id, action_type) => {
    let requestData = new FormData();
    requestData.append("tag_id", id);
    requestData.append("action_type", action_type);
    await SublyApi.EditMyTags(userToken, requestData).then((responsejson) => {
      if (responsejson.status_code === 200) {
        dispatch(profileData(userToken));
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.data.message,
        });
      }
    });
  };

  const remove = (index) => {
    const list = [...searchList];
    list.splice(index, 1);
    setSearchList(list);
  };

  const searchItems =
    searchList &&
    searchList.map((searchList, index) => (
      <p
        onClick={() => {
          editTags(searchList.id, 1);
          remove(index);
        }}
        className="searchItems"
      >
        #{searchList.tag_name}
      </p>
    ));

  return (
    <>
      <div>
        <Modal
          show={userTag}
          onHide={() => setUserTag(false)}
          backdrop="static"
        >
          <Modal.Header className="crossImg">
            <Modal.Title className="modalTitle">
              {t("MY_TAG_MODAL")}
            </Modal.Title>
            <img
              className="cursor-pointer"
              src={crossImg}
              alt="cross-Image"
              onClick={() => {
                setUserTag(false);
                setSearchList([]);
                setTags("");
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <div className="mt-8">
              <Form>
                <Form.Group className="mb-3">
                  <Form.Control
                    name="tagNam"
                    type="text"
                    className="h-12 relative"
                    placeholder={t("SEARCH_TAG")}
                    onChange={(e) => {
                      searchHandler(e);
                    }}
                  />
                  <div className="skillSearch">
                    <FiSearch />
                  </div>
                </Form.Group>
              </Form>
              {searchItems != "" ? (
                <div className="serchList">{searchItems}</div>
              ) : (
                tags && <div className="notFound">Your search not found</div>
              )}

              {!searchList?.length && !tags ? (
                <div>
                  {profile &&
                    profile.tags &&
                    profile.tags?.map((item, index) => (
                      <div className="addTags">
                        <p>{item.tag_name}</p>
                        <RxCross2
                          className="removeTagIcon"
                          onClick={() => editTags(item.tag_id, 2)}
                        />
                      </div>
                    ))}
                </div>
              ) : null}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export default TagModal;
