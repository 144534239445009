import React from "react";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../Assests/Styles/Common.css";
import crossImg from "../../Assests/Images/popupCrossImg.webp";
import { FiSearch } from "react-icons/fi";
import SublyApi from "../../helpers/Api";
import { Toast } from "../../utils/Toster";
import { useDispatch, useSelector } from "react-redux";
import { profileData } from "../../store/Slices/ProfileSlice";
import { RxCross2 } from "react-icons/rx";

const SkillModal = ({ userSkill, setUserSkill }) => {
  const { t } = useTranslation();
  const { profile } = useSelector((state) => state.profile);
  const { userToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [searchSkillsList, setSearchSkillsList] = useState([]);
  const [skills, setSkills] = useState("");

  // ===function for manage search skills ===
  const searchSkills = async (event) => {
    setSkills(event.target.value);
    let requestData = new FormData();
    requestData.append("search", event.target.value);
    if (event.target.value === "") {
      setSearchSkillsList([]);
    }
    await SublyApi.userSearchSkills(userToken, requestData).then(
      (responsejson) => {
        if (responsejson.status_code === 200) {
          dispatch(profileData(userToken));
          setSearchSkillsList(responsejson.data);
        }
        if ((responsejson.data = [])) {
        } else {
        }
      }
    );
  };

  // === function for manage edit skills ===
  const editSkills = async (id, action_type) => {
    let requestData = new FormData();
    requestData.append("skill_id", id);
    requestData.append("action_type", action_type);
    await SublyApi.editMySkills(userToken, requestData).then((responsejson) => {
      if (responsejson.status_code === 200) {
        dispatch(profileData(userToken));
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.data.message,
        });
      }
    });
  };

  const remove = (index) => {
    const list = [...searchSkillsList];
    list.splice(index, 1);
    setSearchSkillsList(list);
  };

  const searchSkillsItems =
    searchSkillsList &&
    searchSkillsList.map((searchSkillsList, index) => (
      <p
        onClick={() => {
          editSkills(searchSkillsList.id, 1);
          remove(index);
        }}
        className="searchItems"
      >
        {searchSkillsList.skill_name}
      </p>
    ));

  return (
    <>
      <div>
        <Modal
          show={userSkill}
          onHide={() => setUserSkill(false)}
          backdrop="static"
        >
          <Modal.Header className="crossImg">
            <Modal.Title className="modalTitle">{t("SKILLS")}</Modal.Title>
            <img
              className="cursor-pointer"
              src={crossImg}
              alt="cross-Image"
              onClick={() => {
                setUserSkill(false);
                setSearchSkillsList([]);
                // setSkillsOptionList([]);
                setSkills("");
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <div className="mt-8">
              <Form>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    className="h-12 relative"
                    placeholder={t("SEARCH_SKILLS")}
                    onChange={(e) => {
                      searchSkills(e);
                    }}
                  />
                  <div className="skillSearch">
                    <FiSearch />
                  </div>
                </Form.Group>
              </Form>
              {searchSkillsItems != "" ? (
                <div className="serchList">{searchSkillsItems}</div>
              ) : (
                skills && <div className="notFound">Your search not found</div>
              )}
              {!searchSkillsList?.length && !skills ? (
                <div>
                  {profile &&
                    profile.skills &&
                    profile.skills?.map((item, index) => (
                      <div className="addTags">
                        <p>{item.skill_name}</p>
                        <RxCross2
                          className="removeTagIcon"
                          onClick={() => editSkills(item.skill_id, 2)}
                        />
                      </div>
                    ))}
                </div>
              ) : null}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export default SkillModal;
