import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import editImg2 from "../../../Assests/Images/editGrayImg.webp";
import styles from "./Education.module.css";
import schoolImg from "../../../Assests/Images/school-img.webp";
import EducationModal from "../EducationModal";
import { useSelector } from "react-redux";
import moment from "moment";

const Education = () => {
  const { t } = useTranslation();
  const [userEducation, setUserEducation] = useState(false);
  const { profile } = useSelector((state) => state.profile);
  const userEducationData = profile.education ? profile.education : [];

  return (
    <>
      <Container>
        <div className={`bg-bg_colour ${styles.educationMain}`}>
          <div className={`flex ${styles.education}`}>
            <p
              className={`text-solid_black font-semibold ${styles.educationHeading}`}
            >
              {t("EDUCATION")}
            </p>
            <img
              src={editImg2}
              alt="edit-image"
              className="w-5	h-5 cursor-pointer"
              onClick={() => setUserEducation(true)}
            />
          </div>

          <div className="mt-3">
            {userEducationData && userEducationData.length > 0 ? (
              userEducationData.map((item, index) => (
                <>
                  <div className="flex mt-6" key={index}>
                    <div className={styles.schoolBG}>
                      <img src={schoolImg} className={styles.schoolImage} />
                    </div>
                    <div className="ml-6">
                      <p
                        className={`font-semibold text-solid_black md:text-sm sm:text-sm ${styles.universityName}`}
                      >
                        {item.academy}
                      </p>
                      <p className="text-base	font-normal	text-my_color2 md:text-sm sm:text-sm">
                        {item.speciality}
                      </p>
                      <p className="text-sm	font-normal	text-my_color2">
                        {item.start_date && item.end_date ? (
                          <span>
                            {moment(item.start_date).format("YYYY")}
                            {" - "}
                            {moment(item.end_date).format("YYYY")}
                          </span>
                        ) : (
                          <span>
                            {moment(item.start_date).format("YYYY")}
                            {" - Current"}
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                  <hr className={styles.separationLine} />
                </>
              ))
            ) : (
              <p>{t("NOT_AVAILABLE")}</p>
            )}
          </div>
        </div>
      </Container>
      <EducationModal
        userEducation={userEducation}
        setUserEducation={setUserEducation}
      />
    </>
  );
};

export default Education;
