import React, { useState, memo } from "react";
import ChatList from "./ChatList";
import { Col, Container, Row } from "react-bootstrap";
import TopHeader from "../UserHeaders/TopHeader";
import BottomHeader from "../UserHeaders/BottomHeader";
import styles from "./ChatModule.module.css";
import { useTranslation } from "react-i18next";
import TrendingSection from "../HomeModule/TrendingSection";
import Footer from "../Footer/Footer";
import chatBackground from "../../Assests/Images/Chat_bg.png";
import CreateGroup from "./CreateGroup";
import ChatDetailsModule from "./ChatDetailsModule";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

function ChatDetail() {
  const { t } = useTranslation();
  const [openGroup, setOpenGroup] = useState(false);
  const [chatState, setChatState] = useState({});

  const navigate = useNavigate();

  return (
    <section>
      <div className={`${styles.myNetworkWrapper} background_color`}>
        <TopHeader />
        <div
          className={`${styles.backgImg} bg-cover w-full bg-center relative`}
          style={{
            backgroundImage: `url(${chatBackground})`,
          }}
        >
          <div className={styles.btmHeader}>
            <BottomHeader />
          </div>
          <p className={styles.myNetworkHeading}>{t("CHAT")}</p>
        </div>
        <div className="my-8 mx-0">
          <Container>
            <Row>
              <Col lg={3} md={12} sm={12} className="mb-4">
                <div className="mt">
                  <ChatList
                    isDetail={1}
                    setChatState={setChatState}
                    chatState={chatState}
                  />
                </div>
              </Col>
              <Col lg={6} md={12} sm={12} className="mb-4">
                <div className={styles.showResponsive}>
                  <div className={styles.responsiveChat}>
                    <div
                      className={`${styles.followBackBtn} 
               flex items-center cursor-pointer`}
                      onClick={() => {
                        navigate("/chat");
                      }}
                    >
                      <BiArrowBack />
                      <span className="text-lg text-center text-black font-medium">
                        {t("BACK")}
                      </span>
                    </div>

                    <div className={styles.createFlexGroup}>
                      <CreateGroup
                        // openGroup={openGroup}
                        setOpenGroup={setOpenGroup}
                        // setChatState={setChatState}
                      />
                    </div>
                  </div>
                </div>
                <ChatDetailsModule
                  setChatState={setChatState}
                  chatState={chatState}
                />
              </Col>
              <Col lg={3} md={12} sm={12} className="mb-4">
                <div className="mb-4">
                  <div className={styles.hideInResponsive}>
                    <CreateGroup
                      openGroup={openGroup}
                      setOpenGroup={setOpenGroup}
                      setChatState={setChatState}
                    />
                  </div>
                </div>
                <div className={styles.hideInResponsive}>
                  <TrendingSection isSetting={"chat"} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </section>
  );
}

export default memo(ChatDetail);
