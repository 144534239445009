export const TRANSLATIONS_EN = {
  // -----------------------------HEADER TEXT-----------------------------------------------------

  HOME: "Home",
  SERVICES: "Services",
  ABOUT_US: "About Us",
  CONTACT_US: "Contact Us",

  //-----------------------------HOME PAGE TEXT-------------------------------------------------

  HEADING: "YOUR SOLUTIONS FOR COMMUNITY!",
  SUB_HEADING: "A trusted connecting partner",
  COMMUNITY_TITEL: "Professional Community",
  COMMUNITY_TEXT:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum dummy text.",
  SOCIAL_TITEL: "Social Network",
  SOCIAL_TEXT:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum dummy text.",
  TRENDING_TITEL: "Trending",
  TRENDING_TEXT:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum dummy text.",
  CHAT_TITEL: "Chat & Group",
  CHAT_TEXT:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum dummy text.",
  POST_TITEL: "Post",
  POST_TEXT:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum dummy text.",
  WHAT_WE_DO: "What We Do",
  THE_BEST_PLACE: "The best place for you",
  LINKIII_SOCIAL_NETWORK: "Linkeiii Social Network",
  WHAT_LINKIII: "Whats Linkeiii",
  WHY_JOIN: "Why Join to Linkeiii Social Network?",
  HOME_MID_TEXT1:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown   printer took a galley of type and scrambled it to make a type specimen book.",
  HOME_MID_TEXT2:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.",
  SOCIAL_POSTING: "Social Posting",
  PROFESSIONAL_JOB: "Professional Job",
  PROFESSIONAL_COMMUNITY: "Professional Community",
  NETWORK_WITH_FRIEND: "Network with all Friend",
  HOME_MID_HEADING: "You Can Easily Find This App...!",
  HOME_MID_TEXT3:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",

  //----------------------------FOOTER TEXT---------------------------------------------------

  FOOTER_TEXT:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure, deserunt.",
  NUMBER: "+91-1234567890",
  NAVIGATION: "Navigation",
  JOIN_US: "Join Us",
  DISCOVER: "Discover",
  COMPANIES: "Companies",
  SOCIAL_CONNECT: "Social Connect",
  HIRING: "Hiring",
  COMPANIES_JOB: "Companies Job",
  REQUIREMENTS: "Requirements",

  //-----------------------------BUTTON TEXT-------------------------------------------------

  SIGN_UP: "Sign Up",
  SIGN_IN: "Sign In",
  SIGN_IN_SIGN_UP: "Sign In / Sign Up",

  //----------------------------SIGN UP PAGE TEXT------------------------------------------

  BACK: "Back",
  CREAT_NEW_ACCOUNT: "Create new account",
  ENTER_INFORMATION: "Please enter required information.",
  FULL_NAME: "Full name",
  EMAIL: "Email",
  DATE_OF_BIRTH: "Date of Birth",
  PASSWORD: "Password",
  CONFIRM_PASSWORD: "Confirm Password",
  AGREE_WITH: "I agree with",
  TERMS_CONDITIONS: "Terms & Conditions",
  OR: "Or",
  GOOGLE: "Google",
  ALREADY_LINKEIII_MEMBER: "Already have Linkeiii member?",
  OOPS: "Oops!",
  PAGE_404: "404 page not found",
  SORRY_PAGE_NOT_EXIST: "Sorry, the page you are looking for does not exist.",
  NAME_REQUIRED: "Please enter your full name.",
  NAME_TO_LONG: "Please enter your full name, with a maximum of 50 characters.",
  NAME_TO_SHORT: "Please enter your full name, with a minimum of 2 characters.",
  EMAIL_REQUIRED: "Please enter your email address.",
  EMAIL_INVALID: "Please enter a valid email address.",
  DATE_OF_BIRTH_LABEL: "Date of birth",
  DATE_OF_BIRTH_REQUIRED: "Please enter your date of birth.",
  DATE_FORMAT: "DD/MM/YYYY",
  PASSWORD_REQUIRED: "Please enter password.",
  PASSWORD_MUST_CONTAIN:
    "Please enter password must contain one uppercase, lowercase, number, and a special character.",
  PASSWORD_MIN_CHARACTER:
    "Please enter password, with a minimum of 8 characters.",
  CONFIRM_PASSWORD_REQUIRED: "Please enter confirm Password.",
  CONFIRM_PASSWORD_NOT_MATCH: "Password does not match the confirm password.",
  SERVER_ERR: "Internal server error",
  PASS_MAXLENGTH: "Please enter password, with a maximum of 15 characters.",
  SIGN_IN_TEXT: "  Sign In Now",
  SOMETHING_WENT_WRONG: "Something went wrong",
  SIMILAR_PASSWORD:
    "Please enter your new password is similar to current password.",

  //----------------------------LOGIN PAGE TEXT------------------------------------------

  LOGIN: "Login",
  ALREADY_A_MEMBER: "If you are already a member, easily Log In",
  FORGOT_PASSWORD: "Forgot password?",
  NEW_TO_LINKIII: "New to Linkeiii?",
  JOIN_NOW: "Join now",

  //----------------------------FORGOT PASSWORD PAGE TEXT------------------------------------------

  FORGOT_SUB_HEADING:
    "Don't worry! It happens. please enter the address associated with your account.",
  BACK_TO_SIGNIN: "Back to Sign in",
  SUBMIT: "Submit",

  //----------------------------VERIFY FORGOT PASSWORD PAGE TEXT------------------------------------------

  VERIFY_FORGOT_PASSWORD: "Verify forgot password",
  ENTER_VERIFICATION_CODE: "Verification code",
  ENTER_NEW_PASSWORD: "New password",
  ENTER_CONFIRM_PASSWORD: "Confirm password",
  VERIFICATION_CODE_REQUIRED: "Please enter verification code.",

  //----------------------------EMAIL VERIFY PAGE TEXT------------------------------------------

  EMAIL_VERIFICATION: "Confirm Your Email Address",
  EMAIL_CONFIRMATION_MESSAGE:
    "A confirmation email has been sent to your mail id. Click on the confirmation link in the email to activate your account.",
  BACK_TO_BUTTON: "Back to login",

  //----------------------------ABOUT US PAGE TEXT------------------------------------------

  TALENT_PROJECT: "TALENT & PROJECT",
  COLLABORATION_NETWORKING:
    "Collaboration Networking Platform Lorem Ipsum is simply dummy ",
  ABOUT_PARAGRAPH_TEXT:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum.",
  LIST_ITEM_ONE:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  LIST_ITEM_TWO:
    "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  LIST_ITEM_THREE:
    "It has survived not only five centuries, but also the leap into electronic typesetting.",
  LIST_ITEM_FOUR:
    "remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing.",
  LIST_ITEM_FIVE:
    "Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",

  //----------------------------EMAIL VERIFY PAGE TEXT------------------------------------------

  CHAT: "Chat",
  MY_NETWORK: "My Network",

  //----------------------------USER PROFILE PAGE TEXT------------------------------------------

  USER_DESIGINATION: "UI/UX Designer - Mindiii Systems PVT.LTD",
  USER_DOB: "DOB : ",
  MORE_INFO_BTN: "More Info",
  ABOUT_ME: "About me",
  EDUCATION: "Education",
  MY_TAG: "My #tag",
  MY_TAG_MODAL: "My #Tag",
  MANAGE_TAG: "manage #Tag",
  TAG_1: "#UI/UX Designer ",
  TAG_2: "#uiuxdesign ",
  TAG_3: "#mindiiisystemspvtltd ",
  TAG_4: "#Massachusetts_Institute_of_Technology. ",
  TAG_5: "#Science_and_Technology ",
  TAG_6: "#indore_job_ui/ux ",
  MY_CONNECTION: "My Connections",
  MY_FOLLOWERS: "Followers",
  EXPERIENCE: "Experience",
  SKILLS: "Skills",
  WEB_DESIGN: "Web Designer",
  REACT_JS: "React Js",
  JAVASCRIPT: "JavaScript",
  HTML: "HTML",
  CSS: "CSS",
  JAVA: "Java",
  PHONE_REQUIRED: "Phone is required *",

  // ---------- user home module and there sub module ------------

  SEE_MORE: "See More",
  TRENDING: "Trending",
  VIEW_PROFILE: "View Profile",
  FOLLOWERS: "Followers",
  CONNECTION: "Connections",
  POST_BTN: "Post",
  COMMENTS: "Comments",
  COMMENT: "Comment",
  REPOST: "Repost",
  SEND: "Send",
  PLACEHOLDER_TEXT: "Write your message here",
  CHAT_PLACEHOLDER_TEXT: "Your Message",
  WHATS_YOUR_MIND: "Whats on your mind...",
  CREATE_POST: "Create Post",
  SHARE: "Share",
  REPORT_POST: "Report post",
  REPORT_USER: "Report user",
  COPY_LINK: "Copy link",
  REPORT: "Report",
  SEE_LESS: "See Less",
  LOADMORE_COMMENTS: "Load more comments...",
  MORE_INFO: "More info...",

  //----------------------------USER PROFILE INFO MODAL PAGE TEXT------------------------------------------

  EDIT_PROFILE: "Edit Profile",
  DESIGNATION: "Designation",
  COMPANY: "Company",
  COMPANY_ADDRESS: "Address",
  CONTACT_NUMBER: "Contact number",
  SAVE: "Save",
  DESIGNATION_REQUIRED: "Designation is required*",
  DESIGNATION_TO_LONG: "Designation is long*",
  DESIGNATION_TO_SHORT: "Designation is short*",
  COMPANY_REQUIRED: "Company is required*",
  COMPANY_TO_LONG: "Company is long*",
  COMPANY_TO_SHORT: "Company is short*",
  ADDRESS_REQUIRED: "Please enter your address.",
  ADDRESS_TO_LONG: "Please enter address, with a maximum of 200 characters.",
  ADDRESS_TO_SHORT: "Please enter address, with a minimum of 2 characters.",
  CONTACT_NUMBER_REQUIRED: "Contact number is required*",

  //----------------------------USER ABOUT ME MODAL PAGE TEXT------------------------------------------

  ABOUT_ME2: "About Me",
  ABOUT: "About",
  ABOUT_REQUIRED: "Please enter about field.",
  ABOUT_TO_LONG: "About me may have maximum 1000 characters.",

  //----------------------------USER EXPERIENCE MODAL PAGE TEXT------------------------------------------

  START_DATE: "Start Date",
  END_DATE: "End Date",
  TOTAL_YEARS: "yr",
  TOTAL_MONTHS: "m'th",

  //----------------------------USER EDUCATION MODAL PAGE TEXT------------------------------------------

  ACADEMY: "Academy",
  ACADEMY_REQUIRED: "Academy is required*",
  ACADEMY_TO_SHORT: "Academy is to short*",
  SPECIALITY: "Speciality",
  SPECIALITY_REQUIRED: "Speciality is required*",
  START_DATE_REQUIRED: "Start date is required*",
  END_DATE_REQUIRED: "End date is required*",

  //----------------------------USER SKILLS MODAL PAGE TEXT------------------------------------------

  SKILLS_REQUIRED: "Skills is required*",
  SKILLS_TO_LONG: "Skills is to long*",
  SKILLS_TO_SHORT: "Skills is to short*",
  SEARCH_SKILLS: "Search Skills",

  //----------------------------USER TAG MODAL PAGE TEXT------------------------------------------

  SEARCH_TAG: "Search # tag",
  TAG_REQUIRED: "Tag is required*",
  TAG_TO_LONG: "Tag is to long*",
  TAG_TO_SHORT: "Tag is to short*",
  DELETE_POST: "Delete post",
  LOGOUT: "Logout",

  // ------------------Social Share------------------
  SHARE_POST: "Share post",
  FACEBOOK: "Facebook",
  TWITTER: "Twitter",
  LINKEDIN: "Linkedin",
  WHATSAPP: "Whatsapp",

  //------------- My Network----------------
  My_Network: "My Network",
  Requests: "Requests",
  Connections: "Connections",
  Suggestions: "Suggestions",
  No_Data: "No Data Found",
  No_Request: "No Request Found",
  No_Connection: "No Connection Found",
  No_Suggestions: "No Suggestions Found",
  NO_FOLLOWER: "No followers found",
  Connect: "Connect",
  Pending: "Pending",
  Accept: "Accept",
  Decline: "Decline",
  SETTING: "Settings",
  UNFOLLOW: "Unfollow",
  CURRENT: "Current",
  DISCONNECT: "Disconnect",
  MESSAGE: "Message",
  FOLLOW: "Follow",
  FOLLOWING: "Following",
  DISCONNECT: "Disconnect",
  NOT_AVAILABLE: "NA",

  // ---------------------Cropper-----------------------
  IMANGE_CROP: "Image Cropper",
  CROP: "Crop",
  CLOSE: "Close",
  VIDEO_SIZE_ERROR: "Post video size should be less than 50MB individual",
  TMG_UPLOAD_ERROR: "Only 5 files are allowed to upload.",
  DETAILS: "Details",
  LIKE: "Like",
  CPOIED: "Copied",

  // ------------------Settings--------------------
  NOTIFICATION: "Notification",
  CHANGE_PASSWORD: "Change Password",
  NEED_HELP: "Need Help",
  SUGGESTION: "Suggestion",
  TITLE: "Title",
  DESCRIPTION: "Description",
  CREATE_TITLE: "Create title",
  TYPE_HERE: "Type here",
  CURRENT_PASSWORD: "Current password",
  NEW_PASSWORD: "New password",
  CONFIRM_PASSWORD: "Confirm password",
  DELETE_ACCOUNT: "Delete account",
  WANT_TO_DELETE: "Do you want to delete your account.",
  DELETE: "Delete",
  CANCEL: "Cancel",

  // ===============Chat=============
  CHAT_LIST: "Chat List",
  JUST_NOW: "Just now",
  CREATE_GROUP: "Create a Group",
  GROUP_CREATE: "Create Group",
  NEXT: "Next",
  GROUP_TITLE: "Group title",
  CREATE_TITLE: "Create title",
  ARTIFICIAL_INT: "Artificial intelligence",
  GROUP_MEMBER: "member in this Group",
  GROUP_PAGE_DETAIL: "Group Details Page",
  MEMBERS: "Members",
  ADD_MEMBERS: "Add members",
  SEARCH_NAME: "Search name",
  SEARCH: "Search",
  CLEAR_CHAT: "Clear Chat",
  CLEAR_CHAT_HISTORY: " Clear history",
  REMOVE_MEMBER: "Remove",
  NO_CHAT_AVAILABLE_TEXT: ` Once you're connected with users , you'll be able to chat with
  friends`,
  NO_SEARCH_AVAILABLE_TEXT: `No data available for the current search.`,
};
