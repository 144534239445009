import { useEffect, useState } from "react";
import styles from "../MyNetwork.module.css";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { Toast } from "../../../utils/Toster";
import { userLogoutClear } from "../../../store/Slices/UserSlice";
import {
  suggestionList,
  handleSuggestions,
} from "../../../store/Slices/NetworkSlice";
import defaultImg from "../../../Assests/Images/default_user.png";
import SublyApi from "../../../helpers/Api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { FaCheck, FaTimes } from "react-icons/fa";
import {
  REQUEST_RECEIVE_STATUS,
  REQUEST_SENT_STATUS,
  REQUEST_ACTION_TYPE,
} from "../../../utils/Constants";

function MySuggestions() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.user);
  const suggestions = useSelector((state) => state.network.suggestions);
  const suggestionData = useSelector((state) => state.network.suggestionData);
  const [pageValue, setPageValue] = useState(1);
  const [listUpdate, setListUpdate] = useState(false);

  //==== function for fetch suggetionList====
  async function fetchSuggestionList(page, loadValue) {
    const requestData = new FormData();
    requestData.append("page_no", page ? page : pageValue);
    dispatch(
      suggestionList({
        userToken: userToken,
        requestData: requestData,
        loadValue: loadValue,
      })
    ).then((responsejson) => {
      const response = responsejson.payload.response;
      if (response.status_code === STATUS_CODES.SUCCESS) {
      } else if (response.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: response.message,
        });
      } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        dispatch(userLogoutClear());
      } else if (response.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
      } else {
        Toast.fire({
          icon: "error",
          title: response.message,
        });
      }
    });
  }

  useEffect(() => {
    fetchSuggestionList(1, false);
    setPageValue(1);
  }, [listUpdate]);

  // ====function for page increament====
  function pageHandle() {
    fetchSuggestionList(pageValue + 1, true);
    setPageValue(pageValue + 1);
  }

  //====== function to send connection request ======
  async function connectHandler(userData, index) {
    const requestData = new FormData();
    requestData.append("receiver_id", userData.id);
    await SublyApi.sendRequestList(userToken, requestData).then((response) => {
      const responsejson = response.data;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        const list = [...suggestions];
        list[index] = {
          ...list[index],
          is_request_sent: REQUEST_SENT_STATUS.REQUEST_SENT,
          request_status: REQUEST_ACTION_TYPE.REQUEST_PENDING,
        };
        dispatch(handleSuggestions({ suggestions: list }));
      } else if (responsejson.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      } else if (responsejson.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
        dispatch(userLogoutClear());
      } else if (responsejson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        Toast.fire({
          icon: "error",
          title: `${t("SERVER_ERR")}`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.message,
        });
      }
    });
  }

  //====== function to Accept/Decline request ======
  const RequestHandler = async (userData, isAccepted, index) => {
    const requestData = new FormData();
    requestData.append("request_action", isAccepted);
    requestData.append("request_by", userData.request_by);
    requestData.append("request_id", userData.request_id);
    await SublyApi.requestConnectionAction(userToken, requestData).then(
      (responsjson) => {
        if (responsjson.status_code === STATUS_CODES.SUCCESS) {
          const list = [...suggestions];
          if (isAccepted == REQUEST_ACTION_TYPE.REQUEST_DECLINE) {
            list[index] = {
              ...list[index],
              request_status: REQUEST_ACTION_TYPE.REQUEST_DECLINE,
            };
          } else {
            list.splice(index, 1);
          }
          dispatch(handleSuggestions({ suggestions: list }));
        } else if (responsjson.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: responsjson.message,
          });
        } else if (
          responsjson.data.status_code === STATUS_CODES.INVALID_TOKEN
        ) {
          Toast.fire({
            icon: "error",
            title: responsjson.data.message,
          });
          dispatch(userLogoutClear());
        } else if (responsjson.status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
          Toast.fire({
            icon: "error",
            title: `${t("SERVER_ERR")}`,
          });
        } else {
          Toast.fire({
            icon: "error",
            title: responsjson.message,
          });
        }
      }
    );
  };

  return (
    <>
      <div className={`${styles.mySuggestionsData} mb-6  bg-bg_colour`}>
        <h3 className="text-left font-semibold mb-3.5 tracking-norma">
          {t("Suggestions")}
        </h3>
        <Row>
          {suggestions.length > 0 ? (
            suggestions.map((data, index) => (
              <Col lg={12} sm={12} key={index}>
                <div className={`${styles.suggestionsList}`}>
                  <div
                    onClick={() =>
                      navigate(`/my-network/profile-detail/${data.id}`)
                    }
                    className={`${styles.userInfo} cursor-pointer`}
                  >
                    <span className={styles.requestImg}>
                      <img src={defaultImg} alt={data.fullname} />
                    </span>
                    <div className={styles.requestDetail}>
                      <p>{data.fullname}</p>
                      <span>{data.current_designation}</span>
                    </div>
                  </div>
                  <div className={`${styles.connectBtn}`}>
                    {data.is_request_sent ==
                    REQUEST_SENT_STATUS.REQUEST_NOT_SENT ? (
                      data.is_request_received ==
                      REQUEST_RECEIVE_STATUS.NOT_RECEIVED ? (
                        <button
                          onClick={() => {
                            connectHandler(data, index);
                          }}
                        >
                          {t("Connect")}
                        </button>
                      ) : data.request_status ==
                        REQUEST_ACTION_TYPE.REQUEST_PENDING ? (
                        <div className="flex">
                          <button
                            onClick={() => {
                              RequestHandler(
                                data,
                                REQUEST_ACTION_TYPE.REQUEST_ACCEPT,
                                index
                              );
                            }}
                            className={styles.acceptBtn}
                          >
                            <FaCheck />
                          </button>
                          <button
                            onClick={() => {
                              RequestHandler(
                                data,
                                REQUEST_ACTION_TYPE.REQUEST_DECLINE,
                                index
                              );
                            }}
                            className={styles.declineBtn}
                          >
                            <FaTimes />
                          </button>
                        </div>
                      ) : data.request_status ==
                        REQUEST_ACTION_TYPE.REQUEST_DECLINE ? (
                        <button
                          onClick={() => {
                            connectHandler(data, index);
                          }}
                        >
                          {t("Connect")}
                        </button>
                      ) : (
                        ""
                      )
                    ) : data.request_status ==
                      REQUEST_ACTION_TYPE.REQUEST_PENDING ? (
                      <button className={styles.pendingBtn}>
                        {t("Pending")}
                      </button>
                    ) : data.request_status ==
                      REQUEST_ACTION_TYPE.REQUEST_DECLINE ? (
                      <button
                        onClick={() => {
                          connectHandler(data, index);
                        }}
                      >
                        {t("Connect")}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <span className={`${styles.noData} text-center mt-2`}>
              {t("No_Suggestions")}
            </span>
          )}
        </Row>
        {suggestions.length > 0 && suggestionData.loadMore && (
          <div className={`${styles.seeMoreBtn} text-center mb-1`}>
            <span onClick={pageHandle} className="cursor-pointer">
              {t("SEE_MORE")}
            </span>
          </div>
        )}
      </div>
    </>
  );
}

export default MySuggestions;
