import React from "react";
import loader from "../../Assests/Images/pageloading.gif";
import styles from "./ChatLoader.module.css";

function ChatLoader() {
  return (
    <div className={styles.ChatLoader}>
      <img src={loader} alt="loading" />
    </div>
  );
}

export default ChatLoader;
