import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./HomeModule.module.css";
import { useDispatch, useSelector } from "react-redux";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";
import { handleKeyValues } from "../../store/Slices/MediaSlice";
import { Toast } from "../../utils/Toster";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import Loader from "../../utils/Loader/Loader";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";
import { useNavigate } from "react-router-dom";

function ReportComment({
  postIdValue,
  setPostIdValue,
  setOpenBox,
  openReport,
  setOpenReport,
  isDetail,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  const [reportValue, setReportValue] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const { postMediaList } = useSelector((state) => state.media);
  const navigate = useNavigate();

  // =======clear redux========
  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  // ----------Calling API for report post------------
  async function reportPostHandle() {
    setIsLoader(true);
    const requestData = new FormData();
    requestData.append("post_id", postIdValue);
    requestData.append("comment", reportValue);
    await SublyApi.reportPost(userToken, requestData).then(async (response) => {
      if (response.status_code === STATUS_CODES.SUCCESS) {
        setPostIdValue("");
        setReportValue("");
        setOpenReport(false);
        setOpenBox(false);
        if (isDetail) {
          navigate("/");
        }
        const MediaObject = postMediaList.filter(
          (element, ind) => element.id !== postIdValue
        );
        dispatch(handleKeyValues({ postMediaList: MediaObject }));
        Toast.fire({
          icon: "success",
          title: response.message,
        });
        setIsLoader(false);
      } else if (response.data.status_code === STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        setIsLoader(false);
      } else if (response.data.status_code === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        logOutDataClear();
        setIsLoader(false);
      } else {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
        setIsLoader(false);
      }
    });
  }

  return (
    <section>
      {isLoader ? <Loader /> : ""}
      <Modal
        show={openReport}
        onHide={() => {
          setOpenReport(false);
          setReportValue("");
          setPostIdValue("");
        }}
        className="modalCloseBtn"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles.modalTitle}>
            {t("REPORT_POST")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.textAreaClass}>
          <div>
            <textarea
              placeholder={t("PLACEHOLDER_TEXT")}
              className="py-2 w-full"
              value={reportValue}
              onChange={(e) => {
                setReportValue(e.target.value);
              }}
            />
            <div className="flex justify-end">
              <button
                className={`mt-0 bg-mid_background text-white font-medium w-32 h-12 text-lg ${styles.PostBtn}`}
                onClick={() => reportPostHandle()}
                disabled={reportValue ? false : true}
              >
                {t("REPORT")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default ReportComment;
