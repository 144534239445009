import React, { useEffect } from "react";
import { useCallback, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import userProfileImg from "../../Assests/Images/user-profile.webp";
import UserProfileEditImg from "../../Assests/Images/editProfileIMg.webp";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { blue } from "@mui/material/colors";
import styles from "./UserInfoModal.module.css";
import crossImg from "../../Assests/Images/popupCrossImg.webp";
import { TiLocation } from "react-icons/ti";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../Assests/Styles/Common.css";
import SublyApi from "../../helpers/Api";
import { Toast } from "../../utils/Toster";
import { useDispatch, useSelector } from "react-redux";
import {
  profileData,
  profileDataDetail,
} from "../../store/Slices/ProfileSlice";
import moment from "moment";
import dayjs from "dayjs";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";
import { STATUS_CODES } from "../../utils/StatusCode";
import { getCroppedImg } from "../Cropper/Cropper";
import Cropper from "react-easy-crop";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";

const UserInfoModal = ({ userInfo, setUserInfo }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm();
  const { profileDetail } = useSelector((state) => state.profile);
  const { userToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState("");
  const [phone, setPhone] = useState();
  const [dialCode, setDialCode] = useState(
    profileDetail.dialCode != null ? profileDetail.dialCode : "91"
  );
  const [phoneError, setPhoneError] = useState("");
  const [profileImage, setProfileImage] = useState();
  const [profilePreview, setProfilePreview] = useState(userProfileImg);
  const [isDisabled, setIsDisabled] = useState(true);
  // const [isLoader, setIsLoader] = useState(false);
  const [profileImageView, setProfileImageView] = useState("");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [showHead, setShowHead] = useState(false);
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    setProfileImage(profileDetail.profile_img);
    setProfilePreview(profileDetail.profile_img);
    setValue("name", profileDetail.fullname);
    let dateOfBirths = moment(profileDetail.dob).format("YYYY/MM/DD");
    setSelectedDate(dateOfBirths);
    setValue("address", profileDetail.location);
    setValue("email", profileDetail.email);
    profileDetail.phone_number &&
      setPhone(profileDetail.phone_number.toString());
    profileDetail.phone_dial_code &&
      setDialCode(profileDetail.phone_dial_code.toString());
  }, [profileDetail, userInfo]);

  //---------function for clear data on logout---------
  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        profileImageView,
        croppedAreaPixels
      );
      imgCropper();

      var blockCrop = croppedImage.split(";");
      // Get the content type of the image
      var cropContentTypes = blockCrop[0].split(":")[1]; // In this case "image/png"
      // get the real base64 content of the file
      var blockRealData = blockCrop[1].split(",")[1];

      var cropBlobImg = await b64toBlob(blockRealData, cropContentTypes);

      setProfileImage(cropBlobImg);
      setProfilePreview(croppedImage);
      setProfileImageView("");
      imgCropper();
    } catch (e) {
      console.error(e);
    }
  }, [profileImageView, croppedAreaPixels]);

  function b64toBlob(cropData, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;
    var byteCharacters = window.atob(cropData); //decode string
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const imgCropper = (e) => {
    if (e && e.target.files && e.target.files.length == 0) {
      return false;
    } else {
      showHead ? setShowHead(false) : setShowHead(true);
    }
  };

  //---------function for date picker css--------------
  const theme = createTheme({
    palette: {
      primary: {
        main: blue[500],
        light: blue[500],
      },
    },
  });

  //---------function for set profile image------------------
  const onFileChange = (e) => {
    if (e.target.files.length !== 0) {
      setProfileImageView(URL.createObjectURL(e.target.files[0]));
    }
  };

  // ---format date javascript-----
  function dateFormater(date, separator) {
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return year + separator + month + separator + day;
  }

  const onSubmit = async (formdata) => {
    if (!phone) {
      setPhoneError("Please enter phone number.");
      return;
    }
    let requestData = new FormData();
    let newDate = formdata.dateOfBirth;
    if (!newDate) {
      newDate = selectedDate;
    }
    requestData.append("profile_image", profileImage);
    requestData.append("fullname", formdata.name.trim());
    requestData.append("dob", dateFormater(new Date(newDate), "-"));
    requestData.append("phone_dial_code", dialCode);
    requestData.append("phone_number", phone);
    requestData.append("location", formdata.address.trim());

    await SublyApi.userUpdateProfile(userToken, requestData).then(
      (responsejson) => {
        if (responsejson.status_code === 200) {
          Toast.fire({
            icon: "success",
            title: responsejson.message,
          });
          dispatch(profileData(userToken));
          dispatch(profileDataDetail(userToken));
          setUserInfo(false);
        } else if (
          responsejson.data.status_code === STATUS_CODES.INVALID_TOKEN
        ) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          logOutDataClear();
          // setIsLoader(false);
        } else {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
        }
      }
    );
  };

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  const onInputChange = (e) => {
    // setError("");
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  return (
    <>
      <Modal
        show={showHead}
        onHide={() => {
          imgCropper();
          setCrop({ x: 0, y: 0 });
          setZoom(1);
          setCroppedAreaPixels(null);
          setProfileImageView("");
        }}
        backdrop="static"
        className="modalCloseBtn"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("IMANGE_CROP")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="manageCropper">
          <Cropper
            image={profileImageView}
            crop={crop}
            zoom={zoom}
            aspect={1 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className={`bg-mid_background text-white font-medium text-lg ${styles.CropBtn}`}
            onClick={showCroppedImage}
          >
            {t("CROP")}
          </Button>
          <Button
            variant="secondary"
            className={`text-white font-medium text-lg ${styles.closeBtn}`}
            onClick={imgCropper}
          >
            {t("CLOSE")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={userInfo}
        onHide={() => setUserInfo(false)}
        backdrop="static"
      >
        <Modal.Header className="crossImg">
          <Modal.Title className="modalTitle">{t("EDIT_PROFILE")}</Modal.Title>
          <img
            className="cursor-pointer"
            src={crossImg}
            alt="cross-Image"
            onClick={() => {
              setUserInfo(false);
              setPhoneError("");
              clearErrors("name");
              clearErrors("dateOfBirth");
              clearErrors("address");
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="flex justify-center rounded">
            <div className="relative rounded">
              <img
                className={`rounded h-40 w-40 object-cover ${styles.profileImg}`}
                src={profilePreview}
                alt="user-profile"
              />
              <label htmlFor="uploadImage" className="">
                <img
                  className={`absolute bg-came_bg cursor-pointer ${styles.cameraImg}`}
                  src={UserProfileEditImg}
                  alt="edit-Image"
                />
                <input
                  id="uploadImage"
                  type="file"
                  accept="image/*"
                  style={{
                    display: "none",
                  }}
                  onChange={(e) => {
                    onFileChange(e);
                    imgCropper(e);
                  }}
                  onClick={(e) => {
                    e.target.value = "";
                  }}
                />
              </label>
            </div>
          </div>

          <div className="mt-8">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3">
                <Form.Label className="labelName">{t("FULL_NAME")}</Form.Label>
                <Form.Control
                  type="text"
                  className="h-12"
                  {...register("name", {
                    onChange: (e) => {
                      handleKeyPress(e);
                    },
                    required: {
                      value: true,
                      message: `${t("NAME_REQUIRED")}`,
                    },
                    maxLength: {
                      value: 50,
                      message: `${t("NAME_TO_LONG")}`,
                    },
                    minLength: {
                      value: 2,
                      message: `${t("NAME_TO_SHORT")}`,
                    },
                    ...sanitizeHtmlTags(),
                  })}
                />
                <div className="text-error_message">
                  {errors.name ? <span>{errors.name.message}</span> : ""}
                </div>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="labelName mb-0">
                  {t("DATE_OF_BIRTH")}
                </Form.Label>
                <div className="dateOfBirth">
                  <Controller
                    control={control}
                    name="dateOfBirth"
                    render={({ field: { onChange, value } }) => (
                      <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              openTo="year"
                              views={["year", "month", "day"]}
                              disableFuture
                              className="custom-datepicker"
                              format={t("DATE_FORMAT")}
                              onChange={onChange}
                              defaultValue={dayjs(selectedDate)}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </ThemeProvider>
                    )}
                  />
                  <div className="text-error_message">
                    {errors.dateOfBirth && <p>{t("DATE_OF_BIRTH_REQUIRED")}</p>}
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="labelName mb-0">
                  {t("COMPANY_ADDRESS")}
                  <Form.Control
                    type="text"
                    className={`h-12 mt-2.5`}
                    {...register("address", {
                      onChange: (e) => {
                        handleKeyPress(e);
                      },
                      required: {
                        value: true,
                        message: `${t("ADDRESS_REQUIRED")}`,
                      },
                      maxLength: {
                        value: 200,
                        message: `${t("ADDRESS_TO_LONG")}`,
                      },
                      minLength: {
                        value: 2,
                        message: `${t("ADDRESS_TO_SHORT")}`,
                      },
                      ...sanitizeHtmlTags(),
                    })}
                  />
                  <div className="text-error_message">
                    {errors.address ? (
                      <span>{errors.address.message}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <TiLocation className={styles.location} />
                </Form.Label>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="emaillabelName">{t("EMAIL")}</Form.Label>
                <Form.Control
                  className="h-12 text-my_color2"
                  disabled={isDisabled}
                  {...register("email", {
                    onChange: (e) => onInputChange(e),
                    required: {
                      value: true,
                      message: `${t("EMAIL_REQUIRED")}`,
                    },
                    pattern: {
                      value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                      message: `${t("EMAIL_INVALID")}`,
                    },
                  })}
                />
                <div className="text-error_message">
                  {errors.email ? <span>{errors.email.message}</span> : ""}
                </div>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="labelName">
                  {t("CONTACT_NUMBER")}
                </Form.Label>
                <div className={`phone ${styles.PhoneInputMain}`}>
                  <PhoneInput
                    value={dialCode + " " + phone}
                    className="h-12"
                    onChange={(value, country) => {
                      setPhoneError("");
                      let dialCode = country.dialCode;
                      let phone = value.slice(dialCode.length, value.length);
                      setDialCode(dialCode);
                      setPhone(phone);
                    }}
                  />
                  <div className="text-error_message">
                    {phoneError ? <span>{phoneError}</span> : ""}
                  </div>
                </div>
              </Form.Group>

              <button className="saveBtn">{t("SAVE")}</button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserInfoModal;
