import React from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

function EmojiPicker({ setCaptionValue, captionValue, leftSide, emojiClass }) {
  return (
    <section
      className={`${
        leftSide ? `${emojiClass} absolute z-10 left-0` : `${emojiClass} absolute z-10 right-0`
      }`}
    >
      <Picker
        data={data}
        onEmojiSelect={(e) => setCaptionValue(captionValue + e.native)}
        perLine={7}
      />
    </section>
  );
}

export default EmojiPicker;
