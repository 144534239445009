import React, { useEffect, useState, useRef } from "react";
import styles from "./HomeModule.module.css";
import TrendingOption from "./TrendingOptions";
import { useTranslation } from "react-i18next";
import SublyApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../utils/StatusCode";
import { Toast } from "../../utils/Toster";
import { userLogoutClear } from "../../store/Slices/UserSlice";
import Loader from "../../utils/Loader/Loader";
import { userProfileClear } from "../../store/Slices/ProfileSlice";
import { userMediaClear } from "../../store/Slices/MediaSlice";
import { userNetworkClear } from "../../store/Slices/NetworkSlice";

function TrendingSection({ isSetting }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  const [trending, setTrending] = useState(null);
  const [loadMore, setLoadMore] = useState();
  const [pageValue, setPageValue] = useState(1);
  const [load, setLoad] = useState(false);
  const chatListRef = useRef(null);

  // =======clear redux========
  function logOutDataClear() {
    dispatch(userLogoutClear());
    dispatch(userProfileClear());
    dispatch(userMediaClear());
    dispatch(userNetworkClear());
  }

  // =======Calling APi for trending list=======
  async function trendingList(page, loadValue) {
    // setLoad(true);
    const requestData = new FormData();
    requestData.append("page_no", page ? page : pageValue);
    await SublyApi.getTrendingList(userToken, requestData).then(
      (responsejson) => {
        if (responsejson.status_code === STATUS_CODES.SUCCESS) {
          setLoadMore(responsejson.data.loadMore);
          if (loadValue) {
            setLoad(true);
            setTrending(trending.concat(responsejson.data.trendingPostList));
          } else {
            setTrending(responsejson?.data.trendingPostList);
          }
          setLoad(false);
        } else if (responsejson?.status_code === STATUS_CODES.BAD_REQUEST) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          setLoad(false);
        } else if (
          responsejson?.data.status_code === STATUS_CODES.INVALID_TOKEN
        ) {
          Toast.fire({
            icon: "error",
            title: responsejson.data.message,
          });
          logOutDataClear();
          setLoad(false);
        } else if (
          responsejson?.status === STATUS_CODES.INTERNAL_SERVER_ERROR
        ) {
          Toast.fire({
            icon: "error",
            title: `${t("SERVER_ERR")}`,
          });
          setLoad(false);
        } else {
          Toast.fire({
            icon: "error",
            title: responsejson
              ? responsejson.data.message
              : `${t("SOMETHING_WENT_WRONG")}`,
          });
          setLoad(true);
        }
      }
    );
  }

  useEffect(() => {
    trendingList(1);
    setPageValue(1);
  }, []);

  // =====function for load more functionality=====
  function loadMoreHandle() {
    trendingList(pageValue + 1, true);
    setPageValue(pageValue + 1);
  }

  const scrollToBottom = () => {
    if (chatListRef.current) {
      // Set a timeout to scroll to the bottom after a short delay
      setTimeout(() => {
        chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
      });
    }
  };

  useEffect(() => {
    if (chatListRef.current) {
      // Scroll to bottom after the component updates
      scrollToBottom();
    }
  }, [trending]);

  return (
    <>
      {load ? <Loader /> : ""}
      <section
        className={
          isSetting == "chat"
            ? styles.chatTrendArea
            : isSetting && isSetting
              ? styles.settingTrendModule
              : styles.trendingBody
        }
      >
        <div>
          <h1 className={styles.trendingTxt}>{t("TRENDING")}</h1>
          {trending && trending.length > 0 ? (
            <div
              className={`overflow-y-auto ${styles.trendOption}`}
              ref={chatListRef}
            >
              {trending
                ? trending.map((item, index) => (

                  <TrendingOption item={item} key={index} />
                ))
                : ""}
            </div>
          ) : (
            <p className={styles.no_data}>{t("No_Data")}</p>
          )}
        </div>
        {loadMore && loadMore == true ? (
          <div className={`${styles.seeMore} text-center pt-1.5`}>
            <span
              className="cursor-pointer"
              onClick={() => {
                loadMoreHandle();
              }}
            >
              {t("SEE_MORE")}
            </span>
          </div>
        ) : (
          ""
        )}
      </section>
    </>
  );
}

export default TrendingSection;
