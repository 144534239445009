import React, { memo } from "react";
import ChatList from "./ChatList";
import { Col, Container, Row } from "react-bootstrap";
import TopHeader from "../UserHeaders/TopHeader";
import styles from "./ChatModule.module.css";
import { useTranslation } from "react-i18next";
import TrendingSection from "../HomeModule/TrendingSection";
import Footer from "../Footer/Footer";
import ChatGroupDetailModule from "./ChatGroupDetailModule";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { IS_PRIVATE_CHAT } from "../../utils/Constants";

function ChatGroupDetail({ isDetail }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const allGroupState = location.state;

  // console.log("allGroupState", allGroupState);

  return (
    <section>
      <div
        className={`${styles.myNetworkWrapper} background_color profilDetailWrapper pb-6`}
      >
        <TopHeader />
        <div className="mt-10 mb-10 mx-0">
          <Container>
            <Row>
              <Col lg={3} md={12} sm={12}>
                <div
                  className={`${styles.followBackBtn} flex items-center cursor-pointer`}
                  onClick={() => {
                    navigate("/chat/chat-details", {
                      state: {
                        room_id:
                          allGroupState &&
                          allGroupState.chat_detail.chat_room_id,
                        room_type:
                          allGroupState && allGroupState.chat_detail.room_type,
                        refrence_id:
                          allGroupState &&
                          allGroupState.chat_detail.refrence_id,
                        chatDetails: allGroupState && allGroupState.chat_detail,
                        is_private_chat:
                          IS_PRIVATE_CHAT.CHAT_LIST_PRIVATE_CONNECTION,
                      },
                    });
                  }}
                >
                  <BiArrowBack />
                  <span className="text-lg text-center text-black font-medium">
                    {t("BACK")}
                  </span>
                </div>
              </Col>
              <Col lg={6} md={12} sm={12}>
                <h3 className={styles.detailHeadText}>
                  {t("GROUP_PAGE_DETAIL")}
                </h3>
              </Col>
              <Col lg={3} md={12} sm={12}></Col>
            </Row>
          </Container>
        </div>
        <div className="my-8 mx-0">
          <Container>
            <Row>
              <Col lg={3} md={12} sm={12} className="mb-4">
                <div className={`"mt" ${styles.hideInResponsive}`}>
                  <ChatList
                    onClick={() => {
                      !isDetail && navigate("/chat/chat-details");
                    }}
                  />
                </div>
              </Col>
              <Col lg={6} md={12} sm={12} className="mb-4">
                <ChatGroupDetailModule />
              </Col>
              <Col lg={3} md={12} sm={12} className="mb-4">
                <div className={styles.hideInResponsive}>
                  <TrendingSection isSetting={"chat"} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </section>
  );
}

export default memo(ChatGroupDetail);
