import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./Slices/UserSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import profileSlice from "./Slices/ProfileSlice";
import postMediaSlice from "./Slices/MediaSlice";
import NetworkSlice from "./Slices/NetworkSlice";
import SearchSlice from "./Slices/SearchSlice";

//redux method which combines reducer
const rootReducer = combineReducers({
  user: userSlice,
  profile: profileSlice,
  media: postMediaSlice,
  network: NetworkSlice,
  search: SearchSlice,
});

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer); //wrap your app's root reducers and pass it to the persistStore.

//return an object that holds the complete state of your app
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store); //persist the store to save the data locally
